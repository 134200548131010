const data_svgs = [
    {
        'id': 1,
        'svg': `<svg width="0" height="0" class="hidden">
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z1">
            <title>Z1</title>
            <path fill="#d7e8cd" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z3">
            <title>Z3</title>
            <path fill="#8de0b8" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z2">
            <title>Z2</title>
            <path fill="#92c193" d="M.07-.21h44v44h-44z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="selection-color">
            <path fill="#665c54" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="icon">
            <title>icon</title>
            <path fill="#d7e8cd" d="M0 0h44v44H0z"></path>
            <path d="M12 0h20v12h-5.86L22 7.86 17.86 12H12zm0 32v-5.86L7.86 22 12 17.86V12H0v20zm20 0v-5.86L36.14 22 32 17.86V12h12v20zm0 0h-5.86L22 36.14 17.86 32H12v12h20z" fill="#5bb56f"></path>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#195f28"></path>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#ef2e7f" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="font-color">
            <path fill="#665c54" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C12">
            <title>C12</title>
            <path d="M18.46 18.46A7.5 7.5 0 0 0 10 10a7.5 7.5 0 0 0 8.48 8.48zM34 34a7.5 7.5 0 0 0-8.48-8.48A7.5 7.5 0 0 0 34 34zm-15.53-8.46A7.5 7.5 0 0 0 10 34a7.51 7.51 0 0 0 8.49-8.48zM34 10a7.5 7.5 0 0 0-8.48 8.48A7.5 7.5 0 0 0 34 10z" fill="#ef2e7f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C11">
            <title>C11</title>
            <path d="M26.75 24h.75a8.08 8.08 0 0 0 5.5-2 8.08 8.08 0 0 0-5.5-2h-.75c.19-.16.38-.33.56-.51a8.07 8.07 0 0 0 2.47-5.3 8.07 8.07 0 0 0-5.3 2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0 2.47 5.3 8.07 8.07 0 0 0 5.3 2.47 8.07 8.07 0 0 0-2.47-5.3c-.17-.17-.36-.34-.55-.47zm-7.23-7.31a8.07 8.07 0 0 0-5.3-2.47 8.07 8.07 0 0 0 2.47 5.3c.18.18.37.35.56.51h-.75A8.08 8.08 0 0 0 11 22a8.08 8.08 0 0 0 5.5 2h.75c-.19.16-.38.33-.56.51a8.07 8.07 0 0 0-2.47 5.3 8.07 8.07 0 0 0 5.3-2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0-2.48-5.31z" fill="#ef2e7f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C10">
            <title>C10</title>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#ef2e7f" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C9">
            <title>C9</title>
            <path d="M18.46 18.46A7.5 7.5 0 0 0 10 10a7.5 7.5 0 0 0 8.48 8.48zM34 34a7.5 7.5 0 0 0-8.48-8.48A7.5 7.5 0 0 0 34 34zm-15.53-8.46A7.5 7.5 0 0 0 10 34a7.51 7.51 0 0 0 8.49-8.48zM34 10a7.5 7.5 0 0 0-8.48 8.48A7.5 7.5 0 0 0 34 10z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C8">
            <title>C8</title>
            <path d="M26.75 24h.75a8.08 8.08 0 0 0 5.5-2 8.08 8.08 0 0 0-5.5-2h-.75c.19-.16.38-.33.56-.51a8.07 8.07 0 0 0 2.47-5.3 8.07 8.07 0 0 0-5.3 2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0 2.47 5.3 8.07 8.07 0 0 0 5.3 2.47 8.07 8.07 0 0 0-2.47-5.3c-.17-.17-.36-.34-.55-.47zm-7.23-7.31a8.07 8.07 0 0 0-5.3-2.47 8.07 8.07 0 0 0 2.47 5.3c.18.18.37.35.56.51h-.75A8.08 8.08 0 0 0 11 22a8.08 8.08 0 0 0 5.5 2h.75c-.19.16-.38.33-.56.51a8.07 8.07 0 0 0-2.47 5.3 8.07 8.07 0 0 0 5.3-2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0-2.48-5.31z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C7">
            <title>C7</title>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#5bb56f" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C6">
            <title>C6</title>
            <path d="M18.46 18.46A7.5 7.5 0 0 0 10 10a7.5 7.5 0 0 0 8.48 8.48zM34 34a7.5 7.5 0 0 0-8.48-8.48A7.5 7.5 0 0 0 34 34zm-15.53-8.46A7.5 7.5 0 0 0 10 34a7.51 7.51 0 0 0 8.49-8.48zM34 10a7.5 7.5 0 0 0-8.48 8.48A7.5 7.5 0 0 0 34 10z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C5">
            <title>C5</title>
            <path d="M26.75 24h.75a8.08 8.08 0 0 0 5.5-2 8.08 8.08 0 0 0-5.5-2h-.75c.19-.16.38-.33.56-.51a8.07 8.07 0 0 0 2.47-5.3 8.07 8.07 0 0 0-5.3 2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0 2.47 5.3 8.07 8.07 0 0 0 5.3 2.47 8.07 8.07 0 0 0-2.47-5.3c-.17-.17-.36-.34-.55-.47zm-7.23-7.31a8.07 8.07 0 0 0-5.3-2.47 8.07 8.07 0 0 0 2.47 5.3c.18.18.37.35.56.51h-.75A8.08 8.08 0 0 0 11 22a8.08 8.08 0 0 0 5.5 2h.75c-.19.16-.38.33-.56.51a8.07 8.07 0 0 0-2.47 5.3 8.07 8.07 0 0 0 5.3-2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0-2.48-5.31z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C4">
            <title>C4</title>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#f3fff4" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C3">
            <title>C3</title>
            <path d="M18.46 18.46A7.5 7.5 0 0 0 10 10a7.5 7.5 0 0 0 8.48 8.48zM34 34a7.5 7.5 0 0 0-8.48-8.48A7.5 7.5 0 0 0 34 34zm-15.53-8.46A7.5 7.5 0 0 0 10 34a7.51 7.51 0 0 0 8.49-8.48zM34 10a7.5 7.5 0 0 0-8.48 8.48A7.5 7.5 0 0 0 34 10z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C2">
            <title>C2</title>
            <path d="M26.75 24h.75a8.08 8.08 0 0 0 5.5-2 8.08 8.08 0 0 0-5.5-2h-.75c.19-.16.38-.33.56-.51a8.07 8.07 0 0 0 2.47-5.3 8.07 8.07 0 0 0-5.3 2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0 2.47 5.3 8.07 8.07 0 0 0 5.3 2.47 8.07 8.07 0 0 0-2.47-5.3c-.17-.17-.36-.34-.55-.47zm-7.23-7.31a8.07 8.07 0 0 0-5.3-2.47 8.07 8.07 0 0 0 2.47 5.3c.18.18.37.35.56.51h-.75A8.08 8.08 0 0 0 11 22a8.08 8.08 0 0 0 5.5 2h.75c-.19.16-.38.33-.56.51a8.07 8.07 0 0 0-2.47 5.3 8.07 8.07 0 0 0 5.3-2.47A8.07 8.07 0 0 0 22 22a8.07 8.07 0 0 0-2.48-5.31z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C1">
            <title>C1</title>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#195f28" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="bg-color">
            <path fill="#f3b3b3" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B12">
            <title>B12</title>
            <path d="M15 7l7-7 7 7-7 7zm0 30l7-7 7 7-7 7zm-8-8l-7-7 7-7 7 7zm30 0l-7-7 7-7 7 7z" fill="#ef2e7f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B11">
            <title>B11</title>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#ef2e7f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B10">
            <title>B10</title>
            <path d="M18.63 6H6v12.63zM38 18.63V6H25.37zM18.63 38H6V25.37zM38 38V25.37L25.37 38z" fill="#ef2e7f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B9">
            <title>B9</title>
            <path d="M15 7l7-7 7 7-7 7zm0 30l7-7 7 7-7 7zm-8-8l-7-7 7-7 7 7zm30 0l-7-7 7-7 7 7z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B8">
            <title>B8</title>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B7">
            <title>B7</title>
            <path d="M18.63 6H6v12.63zM38 18.63V6H25.37zM18.63 38H6V25.37zM38 38V25.37L25.37 38z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B6">
            <title>B6</title>
            <path d="M15 7l7-7 7 7-7 7zm0 30l7-7 7 7-7 7zm-8-8l-7-7 7-7 7 7zm30 0l-7-7 7-7 7 7z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B5">
            <title>B4</title>
            <path d="M18.63 6H6v12.63zM38 18.63V6H25.37zM18.63 38H6V25.37zM38 38V25.37L25.37 38z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B4">
            <title>B5</title>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B3">
            <title>B3</title>
            <path d="M15 7l7-7 7 7-7 7zm0 30l7-7 7 7-7 7zm-8-8l-7-7 7-7 7 7zm30 0l-7-7 7-7 7 7z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B2">
            <title>B2</title>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B1">
            <title>B1</title>
            <path d="M18.63 6H6v12.63zM38 18.63V6H25.37zM18.63 38H6V25.37zM38 38V25.37L25.37 38z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A9">
            <title>A9</title>
            <path d="M11.39 17.61L7 22l4.39 4.39v6.22h6.22L22 37l4.39-4.39h6.22v-6.22L37 22l-4.39-4.39v-6.22h-6.22L22 7l-4.39 4.39h-6.22z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A8">
            <title>A8</title>
            <path d="M37.56 6.44L22 0 6.44 6.44 0 22l6.44 15.56L22 44l15.56-6.44L44 22zm-6.37 6.37L22 9l-9.19 3.81L9 22l3.81 9.19L22 35l9.19-3.81L35 22z" fill="#5bb56f" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A7">
            <title>A7</title>
            <path d="M12 0h20v12h-5.86L22 7.86 17.86 12H12zm0 32v-5.86L7.86 22 12 17.86V12H0v20zm20 0v-5.86L36.14 22 32 17.86V12h12v20zm0 0h-5.86L22 36.14 17.86 32H12v12h20z" fill="#5bb56f"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A6">
            <title>A6</title>
            <path d="M11.39 17.61L7 22l4.39 4.39v6.22h6.22L22 37l4.39-4.39h6.22v-6.22L37 22l-4.39-4.39v-6.22h-6.22L22 7l-4.39 4.39h-6.22z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A5">
            <title>A5</title>
            <path d="M37.56 6.44L22 0 6.44 6.44 0 22l6.44 15.56L22 44l15.56-6.44L44 22zm-6.37 6.37L22 9l-9.19 3.81L9 22l3.81 9.19L22 35l9.19-3.81L35 22z" fill="#f3fff4" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A4">
            <title>A4</title>
            <path d="M12 0h20v12h-5.86L22 7.86 17.86 12H12zm0 32v-5.86L7.86 22 12 17.86V12H0v20zm20 0v-5.86L36.14 22 32 17.86V12h12v20zm0 0h-5.86L22 36.14 17.86 32H12v12h20z" fill="#f3fff4"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A3">
            <title>A3</title>
            <path d="M11.39 17.61L7 22l4.39 4.39v6.22h6.22L22 37l4.39-4.39h6.22v-6.22L37 22l-4.39-4.39v-6.22h-6.22L22 7l-4.39 4.39h-6.22z" fill="#195f28"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A2">
            <title>A2</title>
            <path d="M37.56 6.44L22 0 6.44 6.44 0 22l6.44 15.56L22 44l15.56-6.44L44 22zm-6.37 6.37L22 9l-9.19 3.81L9 22l3.81 9.19L22 35l9.19-3.81L35 22z" fill="#195f28" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A1">
            <title>A1</title>
            <path d="M12 0h20v12h-5.86L22 7.86 17.86 12H12zm0 32v-5.86L7.86 22 12 17.86V12H0v20zm20 0v-5.86L36.14 22 32 17.86V12h12v20zm0 0h-5.86L22 36.14 17.86 32H12v12h20z" fill="#195f28"></path>
        </symbol>
    </svg>`,

    'symbols': {
        'Z': 3,
        'A': 9,
        'B': 12,
        'C': 12
    },

    },
    {
        'id': 2,
        'svg': `<svg width="0" height="0" class="hidden">
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z1">
        <title>Z1</title>
        <rect y="0" style="fill:#3fb3b1;" width="44" height="44"/>
      </symbol>

      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z2">
      <title>Z2</title>
      <rect y="0" style="fill:#ffcd85;" width="44" height="44"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z3">
    <title>Z3</title>
    <rect y="0" style="fill:#f26969;" width="44" height="44"/>
  </symbol>



      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A1">
      <title>A1</title>
      <polygon  style="fill:#FFFFFF;" points="30.1,2.6 14.3,2.6 2.3,14.6 2.3,30 14.6,41.5 30.3,41.5 41.6,29.9 41.6,15 "/>
      <path style="fill:#F7941C;" d="M6.8,28.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0.4,0.4,1.1-0.1,1-0.6C7.6,27.7,6.8,27.8,6.8,28.5z"
      />
  <path style="fill:#F7941C;" d="M7.9,16.3c0.1-0.5-0.6-1-1-0.6c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1C6.8,16.6,7.6,16.7,7.9,16.3z"
      />
  <path style="fill:#F7941C;" d="M16.8,35.1c-2.4-0.2-4.7-0.2-7.1-0.1c1.7,1.6,3.7,3.5,5.5,4.9c2.6,0.1,4.4,0,6.7,0.1
      C20.3,38.3,18.6,36.7,16.8,35.1z M16.1,38.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.5-0.6-0.5-1.4,0.1-2c0.4-0.4,1-0.5,1.5-0.4c0.5,0.3,0.8,0.8,0.8,1.3
      C17.4,37.8,16.8,38.4,16.1,38.5z"/>
  <path style="fill:#F7941C;" d="M16.3,36.5c-0.5-0.1-1,0.5-0.6,1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
      C16.6,37.6,16.7,36.8,16.3,36.5z"/>
  <path style="fill:#F7941C;" d="M3.8,22.6c0.1,2.3-0.1,4.4,0,6.7c1.5,1.8,4,3.6,5.6,5.3c0.1-2.4,0.1-4.7-0.1-7.1
      C7.7,25.8,5.5,24.2,3.8,22.6z M8.3,29.3c-0.5,0.5-1.4,0.5-2,0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1C6.1,29.1,6,29,6,28.9
      c0-0.1-0.1-0.2-0.1-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1C6,27.6,6.6,27,7.3,27c0.5,0,1.1,0.3,1.3,0.8C8.8,28.3,8.7,28.9,8.3,29.3z
      "/>
  <path style="fill:#F7941C;" d="M28.2,36.5c-0.4,0.3-0.3,1.1,0.3,1.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
      C29.1,37.1,28.6,36.4,28.2,36.5z"/>
  <path style="fill:#F7941C;" d="M27.6,9.3c2.4,0.2,4.7,0.2,7.1,0.1c-1.7-1.6-3.5-3.8-5.3-5.3c-3.3,0-4.2,0-6.9,0
      C24,5.9,25.8,7.8,27.6,9.3z M28.4,6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
      c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.5,0.6,0.5,1.4-0.1,2c-0.4,0.4-1,0.5-1.5,0.4C27.4,8.5,27,7.9,27,7.4
      C27,6.6,27.6,6,28.4,6z"/>
  <path style="fill:#F7941C;" d="M16.3,7.9c0.4-0.3,0.3-1.1-0.3-1.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1C15.3,7.4,15.8,8,16.3,7.9z"
      />
  <path style="fill:#F7941C;" d="M28.2,7.9c0.5,0.1,1-0.5,0.6-1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0C27.9,6.8,27.8,7.6,28.2,7.9z"
      />
  <path style="fill:#F7941C;" d="M3.8,22.1c1.8-1.6,3.9-3.4,5.5-5.2c0.2-2.4,0.2-4.7,0.1-7.1c-2,2-3.6,3.4-5.6,5.4
      C3.5,17.5,3.9,19.8,3.8,22.1z M5.9,15.9c0-0.1,0-0.2,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
      c0.6-0.5,1.4-0.5,2,0.1c0.4,0.4,0.5,1,0.4,1.5c-0.3,0.5-0.8,0.8-1.3,0.8c-0.8,0-1.4-0.6-1.4-1.3c0,0,0-0.1,0-0.1
      C5.9,15.9,5.9,15.9,5.9,15.9z"/>
  <path style="fill:#F7941C;" d="M16.8,9.3c1.8-1.6,3.5-3.4,5.1-5.2c-2.5,0-4.1-0.1-7,0c-1.8,1.5-3.4,3.7-5.1,5.3
      C12.1,9.5,14.5,9.5,16.8,9.3z M15,6.4C15,6.4,15.1,6.3,15,6.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2
      C15.6,6,15.7,6,15.8,6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.7,0.1,1.3,0.7,1.3,1.4c0,0.5-0.3,1.1-0.8,1.3c-0.5,0.1-1.1,0-1.5-0.4
      C14.5,7.8,14.6,7,15,6.4z"/>
  <path style="fill:#F7941C;" d="M40.1,21.9c0.1-3.6,0.1-3.6,0-6.3c-1.5-1.8-3.5-4.1-5-5.8c-0.1,2.4-0.1,4.7,0.1,7.1
      C36.7,18.7,38.4,20.4,40.1,21.9z M36.1,15.1c0.5-0.5,1.4-0.5,2-0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1
      c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.7-0.7,1.3-1.4,1.3
      c-0.5,0-1.1-0.3-1.3-0.8C35.6,16.1,35.8,15.5,36.1,15.1z"/>
  <path style="fill:#F7941C;" d="M40.1,22.5c-1.8,1.6-3.4,3.3-5,5.1c-0.2,2.4-0.2,4.7-0.1,7.1c1.6-1.7,3.6-3.6,5-5.4
      C40.4,27,40,24.8,40.1,22.5z M38.5,28.6c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
      c-0.6,0.5-1.4,0.5-2-0.1c-0.4-0.4-0.5-1-0.4-1.5c0.3-0.5,0.8-0.8,1.3-0.8c0.8,0,1.4,0.6,1.4,1.3c0,0,0,0.1,0,0.1
      C38.5,28.5,38.5,28.5,38.5,28.6z"/>
  <path style="fill:#F7941C;" d="M36.6,28.1c-0.1,0.5,0.6,1,1,0.6c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1
      C37.6,27.8,36.8,27.7,36.6,28.1z"/>
  <path style="fill:#F7941C;" d="M27.6,35.1c-1.8,1.6-3.5,3.2-5.1,4.9c3,0,4.4,0,7.2-0.1c1.8-1.5,2.8-2.8,5-5
      C32.3,34.9,29.9,35,27.6,35.1z M29.4,38C29.4,38.1,29.4,38.1,29.4,38c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
      c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C27.6,38.4,27,37.8,27,37c0-0.5,0.3-1.1,0.8-1.3c0.5-0.1,1.1,0,1.5,0.4
      C29.9,36.6,29.9,37.5,29.4,38z"/>
  <path style="fill:#F7941C;" d="M37.7,16c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c-0.4-0.4-1.1,0.1-1,0.6C36.8,16.7,37.6,16.6,37.7,16z"
      />
  </symbol>

  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A2">
      <title>A2</title>
      <path style="fill:#00366D;" d="M39,15c-1.6-1.1-3.2-2.2-4.8-3.3c-0.4-0.2-0.7-0.5-1.1-0.7c-0.2-0.4-0.5-0.7-0.7-1.1
      c-1.1-1.6-2.3-3.2-3.3-4.8c-1.9,0.4-3.8,0.7-5.8,1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.4-0.1-0.9-0.2-1.3-0.3c-1.9-0.4-3.8-0.7-5.8-1
      c-1.1,1.6-2.2,3.2-3.3,4.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.2-0.7,0.5-1.1,0.7C8.2,12.7,6.6,13.9,5,15c0.4,1.9,0.7,3.8,1,5.7
      c0.1,0.4,0.2,0.8,0.3,1.3c-0.1,0.4-0.2,0.8-0.3,1.3c-0.4,1.9-0.7,3.8-1,5.7c1.6,1.1,3.2,2.2,4.8,3.3c0.4,0.2,0.7,0.5,1.1,0.7
      c0.2,0.4,0.5,0.7,0.7,1.1c1.1,1.6,2.3,3.2,3.3,4.8c1.9-0.4,3.8-0.7,5.8-1c0.4-0.1,0.8-0.2,1.3-0.3c0.4,0.1,0.9,0.2,1.3,0.3
      c1.9,0.4,3.8,0.7,5.8,1c1.1-1.6,2.2-3.2,3.3-4.8c0.2-0.4,0.5-0.7,0.7-1.1c0.4-0.2,0.7-0.5,1.1-0.7c1.6-1.1,3.2-2.2,4.8-3.3
      c-0.4-1.9-0.7-3.8-1-5.7c-0.1-0.4-0.2-0.8-0.3-1.3c0.1-0.4,0.2-0.8,0.3-1.3C38.4,18.8,38.7,16.9,39,15z M37.1,28.2
      c-1.7,1.2-3.4,2.4-5.2,3.6c-1.2,1.8-2.4,3.4-3.6,5.2c-2.1-0.4-4.2-0.7-6.2-1.1c-2.1,0.4-4.2,0.7-6.2,1.1
      c-1.2-1.7-2.5-3.4-3.6-5.2c-1.8-1.1-3.5-2.4-5.2-3.6C7.3,26.1,7.6,24,8.1,22c-0.4-2.1-0.8-4.1-1.1-6.2c1.7-1.2,3.4-2.4,5.2-3.6
      c1.2-1.8,2.4-3.4,3.6-5.2C17.9,7.4,20,7.7,22,8.1c2.1-0.4,4.2-0.7,6.2-1.1c1.2,1.7,2.5,3.4,3.6,5.2c1.8,1.1,3.5,2.4,5.2,3.6
      c-0.4,2.1-0.7,4.1-1.1,6.2C36.4,24,36.7,26.1,37.1,28.2z"/>

    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A3">
    <title>A3</title>
    <path  style="fill:#D03F44;" d="M-0.1-0.2v44.2h44.2V-0.2H-0.1z M1.4,3.6c0.4,0.2,1,0.3,1.5,0.1C2.6,4.3,2.5,5,2.8,5.6
    c0.5,1.2,2.1,1.7,3.2,1c0.1,0,0.2-0.1,0.3-0.2C6,6.8,6,7.2,6.1,7.6C6.4,8.1,7,8.4,7.6,8.2c0.5,2.4-0.2,4.9-1.3,7.1
    c-0.8,2.1-1.8,4-3.4,5.4c-0.1-0.2-0.3-0.3-0.5-0.4c-0.3-0.2-0.7-0.2-1-0.2V3.6z M42.6,20.3c-0.7-0.2-1.4-0.1-1.9,0.4
    c-1.6-1.4-2.6-3.3-3.3-5.3c-1.1-2.4-1.8-4.9-1.2-7.4c0.1,0.1,0.3,0.2,0.5,0.2c0.8,0.3,1.7-0.1,2.1-0.9c0.1-0.4,0.1-0.8-0.1-1.2
    c0.1,0.1,0.3,0.1,0.4,0.2c1.1,0.3,2.3-0.2,2.8-1.2c0.2-0.8,0.1-1.6-0.4-2.3c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.6,0.1,0.8,0V20.3z
     M40.3,21.8c-2,1.4-3.2,3.6-4.1,5.9c-1,2.2-1.7,4.7-1.2,7.1c0,0,0,0,0,0c0,0,0,0,0,0c-2.4-0.4-4.8,0.3-7.1,1.3
    c-2.3,0.9-4.5,2.1-5.9,4.1c0,0,0,0,0,0c0,0,0,0,0,0c-1.4-2-3.6-3.3-5.9-4.1c-2.2-1-4.7-1.7-7.1-1.3c0,0,0,0,0,0c0,0,0,0,0,0
    c0.4-2.4-0.3-4.9-1.2-7.1c-0.9-2.3-2.1-4.5-4.1-5.9c0,0,0,0,0,0c0,0,0,0,0,0c2-1.4,3.2-3.6,4.1-5.9c1-2.2,1.7-4.7,1.2-7.1
    c0,0,0,0,0,0c0,0,0,0,0,0c2.4,0.4,4.8-0.3,7.1-1.3c2.3-0.9,4.5-2.1,5.9-4.1c0,0,0,0,0,0c0,0,0,0,0,0c1.4,2,3.6,3.3,5.9,4.1
    c2.2,1,4.7,1.7,7.1,1.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,2.4,0.3,4.9,1.2,7.1C37,18.2,38.2,20.4,40.3,21.8
    C40.2,21.8,40.2,21.8,40.3,21.8C40.2,21.8,40.2,21.8,40.3,21.8z M41.1,2.4c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.6-2.7-0.2-3.2,1
    c-0.3,0.6-0.2,1.3,0.1,1.9c-0.8-0.3-1.9,0-2.2,0.9c-0.2,0.5-0.1,1.1,0.2,1.5c-2.5,0.6-5-0.1-7.4-1.2c-2.5-0.9-4.8-2.3-6.1-4.5
    c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.2c-1.3,2.2-3.6,3.6-6.1,4.5c-2.2,1-4.7,1.7-7,1.3
    c0.1-0.3,0.2-0.6,0.1-1C8.2,6,7.6,5.8,7,5.9C6.9,6,6.8,6,6.7,6c0.5-0.6,0.7-1.5,0.4-2.3c-0.6-1-1.7-1.5-2.8-1.2
    C4.2,2.6,4,2.7,3.9,2.8C4.1,2.4,4.1,1.9,4,1.5c0-0.1-0.1-0.1-0.1-0.2h37.1C40.8,1.7,40.9,2.1,41.1,2.4z M1.4,23.4
    C2,23.5,2.6,23.3,3,22.9c1.6,1.4,2.6,3.3,3.4,5.3c1.1,2.4,1.8,5,1.2,7.5c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.6-0.3-1.5-0.1-1.7,0.6
    c-0.2,0.4-0.1,0.8,0.1,1.1c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.6-2.7-0.2-3.2,1c-0.3,0.6-0.2,1.3,0.1,1.9c-0.3-0.1-0.7-0.2-1.1-0.1
    V23.4z M3.5,41.2c0.1,0.1,0.3,0.1,0.4,0.2c1.1,0.3,2.3-0.2,2.8-1.2c0.2-0.8,0.1-1.6-0.4-2.3c0.1,0,0.2,0.1,0.2,0.1
    c0.6,0.2,1.2-0.1,1.5-0.6c0.1-0.4,0-0.9-0.2-1.2c0,0,0,0,0.1,0c2.5-0.6,5.1,0.1,7.5,1.2c2.5,0.9,4.8,2.3,6.1,4.5
    c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.2c1.3-2.2,3.6-3.6,6.1-4.5c2.4-1.1,4.9-1.8,7.4-1.2
    c-0.3,0.5-0.4,1-0.2,1.6c0.3,0.9,1.4,1.2,2.3,0.9c-0.3,0.6-0.4,1.3-0.1,1.9c0.5,1.3,2.1,1.7,3.3,1.1c0.1,0,0.2-0.1,0.3-0.2
    c-0.2,0.3-0.3,0.7-0.1,1.1c0,0,0,0,0,0H3.4c0-0.1,0.1-0.1,0.1-0.2C3.7,42,3.7,41.5,3.5,41.2z M42,40.8c-0.1,0-0.2,0.1-0.3,0.1
    c0.5-0.6,0.7-1.5,0.5-2.3c-0.6-1.1-1.8-1.6-2.9-1.2c-0.2,0-0.3,0.1-0.4,0.2c0.2-0.4,0.2-0.8,0.1-1.3c-0.4-0.8-1.3-1.2-2.2-0.9
    c-0.2,0.1-0.4,0.1-0.5,0.2c-0.6-2.5,0.1-5,1.2-7.4c0.8-2.1,1.8-4,3.4-5.4c0.2,0.2,0.5,0.4,0.8,0.5c0.4,0.1,0.7,0.1,1,0v17.5
    C42.4,40.8,42.2,40.8,42,40.8z"/>
  </symbol>

  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A4">
  <title>A4</title>
  <path style="fill:#15366C;" d="M5.2,38.4c0,0.3,0,0.7-0.1,1c0.3,0,0.7-0.1,1-0.1c0.2,0.3,0.3,0.6,0.4,0.9C6.8,40,7,39.8,7.3,39.6
  c0.3,0.2,0.6,0.4,0.8,0.6c0.1-0.3,0.3-0.6,0.4-0.9c0.3,0,0.7,0,1,0.1c0-0.3-0.1-0.7-0.1-1c0.3-0.2,0.6-0.3,0.9-0.4
  c-0.3-0.3-0.5-0.5-0.5-0.8c0.2-0.3,0.4-0.6,0.6-0.8c-0.3-0.1-0.6-0.3-0.9-0.4c0-0.3,0-0.7,0.1-1c-0.3,0-0.7,0.1-1,0.1
  c-0.2-0.3-0.3-0.6-0.4-0.9c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0-0.7,0-1-0.1
  c0,0.3,0.1,0.7,0.1,1c-0.3,0.2-0.6,0.3-0.9,0.4c0.2,0.3,0.4,0.5,0.6,0.8c-0.2,0.3-0.4,0.6-0.6,0.8C4.7,38.1,5,38.2,5.2,38.4z"/>
<path style="fill:#15366C;" d="M5.4,8.2c0,0.3,0,0.7-0.1,1c0.3,0,0.7-0.1,1-0.1C6.5,9.4,6.6,9.7,6.7,10C7,9.8,7.2,9.6,7.5,9.4
  C7.8,9.6,8,9.8,8.3,10c0.1-0.3,0.2-0.6,0.4-0.9c0.3,0,0.6,0.1,0.9,0.1c0-0.3-0.1-0.7-0.1-1c0.3-0.1,0.6-0.2,0.9-0.3
  C10.2,7.6,10,7.4,9.8,7.1c0.2-0.3,0.4-0.6,0.7-0.8C10.2,6.2,9.9,6,9.6,5.9c0-0.3,0-0.7,0.1-1C9.3,5,9,5,8.7,5
  C8.5,4.7,8.4,4.4,8.3,4.1C8,4.4,7.8,4.6,7.5,4.7C7.2,4.6,7,4.4,6.7,4.2C6.6,4.5,6.4,4.8,6.3,5.1C6,5,5.7,5,5.4,4.9
  c0,0.3,0.1,0.7,0.1,1C5.2,6.1,4.9,6.2,4.6,6.3C4.8,6.5,5,6.8,5.2,7.1C5,7.3,4.8,7.6,4.6,7.9C4.9,8,5.2,8.1,5.4,8.2z"/>
<path style="fill:#15366C;" d="M38.5,36c0-0.3,0-0.7,0.1-1c-0.3,0-0.7,0.1-1,0.1c-0.2-0.3-0.3-0.6-0.4-0.9
  c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0-0.7,0-1-0.1c0,0.3,0.1,0.7,0.1,1
  c-0.3,0.2-0.6,0.3-0.9,0.4c0.2,0.3,0.4,0.5,0.6,0.8c-0.2,0.3-0.4,0.6-0.6,0.8c0.3,0.1,0.6,0.3,0.9,0.4c0,0.3,0,0.7-0.1,1
  c0.3,0,0.7-0.1,1-0.1c0.2,0.3,0.3,0.6,0.4,0.9c0.3-0.2,0.5-0.4,0.8-0.6c0.3,0.2,0.6,0.4,0.8,0.6c0.1-0.3,0.3-0.6,0.4-0.9
  c0.3,0,0.7,0,1,0.1c0-0.3-0.1-0.7-0.1-1c0.3-0.2,0.6-0.3,0.9-0.4c-0.2-0.3-0.5-0.5-0.6-0.8c0.2-0.3,0.4-0.6,0.6-0.8
  C39.1,36.3,38.8,36.1,38.5,36z"/>
<path style="fill:#15366C;" d="M34.3,8.3c0,0.3,0,0.7-0.1,1c0.3,0,0.7-0.1,1-0.1c0.2,0.3,0.3,0.6,0.4,0.9c0.3-0.2,0.5-0.4,0.8-0.6
  c0.3,0.2,0.6,0.4,0.8,0.6c0.1-0.3,0.3-0.6,0.4-0.9c0.3,0,0.7,0,1,0.1c0-0.3-0.1-0.7-0.1-1c0.3-0.2,0.6-0.3,0.9-0.4
  c-0.2-0.3-0.5-0.6-0.6-0.8c0.2-0.3,0.4-0.6,0.6-0.8c-0.3-0.1-0.6-0.3-0.9-0.4c0-0.3,0-0.7,0.1-1c-0.3,0-0.7,0.1-1,0.1
  c-0.2-0.3-0.3-0.6-0.4-0.9c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0-0.7,0-1-0.1
  c0,0.3,0.1,0.7,0.1,1c-0.3,0.2-0.6,0.3-0.9,0.4c0.2,0.3,0.4,0.5,0.6,0.8c-0.2,0.3-0.4,0.6-0.6,0.8C33.7,8,34,8.2,34.3,8.3z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A5">
<title>A5</title>
<path style="fill:#FFFFFF;" d="M5.3,31.6c0.7,1.6,1.4,3.3,2.1,4.9c1.6,0.7,3.3,1.4,4.9,2.1c1.4,1.1,2.8,2.2,4.3,3.2
c1.8-0.2,3.5-0.5,5.3-0.7c1.8,0.2,3.6,0.5,5.3,0.7c1.4-1.1,2.8-2.2,4.3-3.2c1.6-0.7,3.3-1.4,4.9-2.1c0.7-1.6,1.4-3.3,2.1-4.9
c1.1-1.4,2.2-2.8,3.2-4.3c-0.2-1.8-0.5-3.5-0.7-5.3c0.2-1.8,0.5-3.6,0.7-5.3c-1.1-1.4-2.2-2.8-3.2-4.3c-0.7-1.6-1.4-3.3-2.1-4.9
c-1.6-0.7-3.3-1.4-4.9-2.1c-1.4-1.1-2.8-2.2-4.3-3.2c-1.8,0.2-3.5,0.5-5.3,0.7c-1.8-0.2-3.6-0.5-5.3-0.7c-1.4,1.1-2.8,2.2-4.3,3.2
C10.7,6.1,9,6.8,7.4,7.5C6.7,9.1,6,10.8,5.3,12.4c-1.1,1.4-2.2,2.8-3.2,4.3c0.2,1.8,0.5,3.5,0.7,5.3c-0.2,1.8-0.5,3.6-0.7,5.3
C3.2,28.7,4.3,30.2,5.3,31.6z"/>
</symbol>



<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A6">
<title>A6</title>
<path style="fill:#FFFFFF;" d="M10.3,33.8c1.9,1.9,4.3,2.9,6.8,3.5
l4.8,4.8l4.8-4.8l0.1,0c2.5-0.6,4.9-1.6,6.8-3.5c1.9-1.9,2.9-4.2,3.5-6.8l0-0.1l4.8-4.8l-4.8-4.8l0-0.1c-0.6-2.5-1.6-4.9-3.5-6.8
c-1.9-1.9-4.2-2.9-6.8-3.5l-0.1,0L22,2.1L17.1,7l-0.1,0c-2.5,0.6-4.9,1.6-6.8,3.5c-1.9,1.9-2.9,4.2-3.5,6.8l0,0.1l-3.3,3.3L2,22.1
L6.8,27l0,0.1C7.4,29.6,8.4,31.9,10.3,33.8z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A7">
<title>A7</title>
<path  style="fill:#FFFFFF;" d="M6.1,28.2c0.3,0.3,0.3,0.4,0,0.8c-2,2.4-2.7,5.1-1.9,8.2c0.3,1.2,1,2.1,2.1,2.5
		c3.2,1.2,6.2,0.5,8.8-1.7c0.3-0.2,0.4-0.3,0.8,0c3.5,3,8.9,3,12.4,0c0.3-0.2,0.4-0.3,0.6,0c1.7,1.6,3.8,2.2,6.4,2.3
		c0.3,0,0.9-0.1,1.4-0.2c1.9-0.4,3-1.5,3.3-3.3c0.5-2.9-0.1-5.5-2-7.7c-0.3-0.3-0.3-0.5,0-0.8c3-3.3,3-8.9,0-12.3
		c-0.3-0.3-0.3-0.5,0-0.8c2-2.4,2.6-5.1,1.9-8.1c-0.3-1.5-1.3-2.4-2.8-2.8c-3-0.7-5.7-0.1-8.1,1.9c-0.3,0.3-0.4,0.4-0.8,0
		c-3.4-3-8.9-3-12.3,0c-0.3,0.2-0.4,0.3-0.7,0c-2-1.8-4.4-2.4-7-2.2c-2.6,0.2-4,1.6-4.2,4.2c-0.3,2.6,0.4,5,2.2,7
		c0.3,0.3,0.3,0.4,0,0.8C3.1,19.3,3.1,24.8,6.1,28.2z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A8">
<title>A8</title>
<path  style="fill:#FFFFFF;" d="M10.4,33.5c1.9,1.9,4.2,2.9,6.8,3.5
l4.8,4.8l4.8-4.8l0.1,0c2.5-0.6,4.9-1.6,6.7-3.5c1.9-1.9,2.9-4.2,3.5-6.7l0-0.1l4.8-4.8l-4.8-4.8l0-0.1c-0.6-2.5-1.6-4.9-3.5-6.7
c-1.9-1.9-4.2-2.9-6.7-3.5l-0.1,0L22,2l-4.8,4.8l-0.1,0c-2.5,0.6-4.9,1.6-6.7,3.5c-1.9,1.9-2.9,4.2-3.5,6.7l0,0.1l-3.3,3.3
l-1.5,1.5l4.8,4.8l0,0.1C7.5,29.3,8.6,31.6,10.4,33.5z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A9">
<title>A9</title>
<path style="fill:#14366B;" d="M3.9,5.6C3.7,5.9,3.7,6.1,3.7,6.4c0,0.2,0.1,0.3,0.3,0.4C4.3,7,4.5,7.1,4.7,7.1
c0.2,0.1,0.5,0.1,0.8,0.2C5.8,7.4,6,7.5,6.2,7.6c-0.4,0-0.8,0-1.2,0C4.7,7.7,4.4,7.8,4.3,7.9C4.1,8,4,8.1,4,8.3
c0,0.2,0.1,0.4,0.4,0.5C4.2,9,4.1,9.2,4,9.4c0,0.2,0,0.5,0,0.7c0.1,0.4,0.3,0.7,0.7,0.9c0.4,0.2,0.8,0.2,1.1,0.1
c0.2,0,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.2,0-0.4-0.1-0.6-0.2C5.1,10.1,5,10,5,9.7C5,9.4,5.2,9.2,5.4,9
c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.4C8.8,9.5,9,9.6,9.2,9.7c0.2,0.1,0.3,0,0.4-0.1
c0.1-0.2,0.2-0.3,0.1-0.5C9.6,9,9.5,8.8,9.4,8.5C9.3,8.3,9.1,8,9,7.7C8.8,7.4,8.8,7,8.7,6.5C8.7,6,8.8,5.7,9,5.4
C9.2,5.1,9.5,5,9.8,5c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
c0.1-0.4,0.1-0.8-0.1-1.2c-0.2-0.4-0.5-0.6-0.9-0.7c-0.6-0.1-1,0-1.3,0.3C8.7,4,8.5,3.9,8.3,3.9C8.2,3.9,8,4,7.9,4.2
C7.8,4.4,7.7,4.7,7.6,5c-0.1,0.3-0.1,0.7,0,1.1C7.5,5.9,7.4,5.7,7.4,5.4C7.3,5.1,7.2,4.9,7.1,4.7C7.1,4.4,7,4.2,6.9,4
C6.8,3.9,6.7,3.7,6.5,3.7c-0.3-0.1-0.6,0-0.9,0.1C5.4,4,5.1,4.2,5,4.4C4.8,4.3,4.6,4.1,4.4,4C4.3,3.9,4.2,3.9,4.1,4
C4,4.2,4,4.3,4.1,4.4C4.2,4.6,4.4,4.8,4.5,5C4.3,5.1,4,5.3,3.9,5.6z"/>
<path style="fill:#14366B;" d="M4.7,36.9c-0.2,0.1-0.5,0.2-0.6,0.3c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,0.3,0,0.6,0.2,0.9
C4,38.7,4.3,38.8,4.5,39c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0c0.2-0.1,0.3-0.3,0.5-0.4
c0.2,0.3,0.4,0.5,0.6,0.6c0.3,0.1,0.5,0.2,0.9,0.1c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.5,0.2-0.8
c0.1-0.3,0.1-0.5,0.2-0.7c0,0.4,0,0.8,0.1,1.1c0.1,0.3,0.2,0.6,0.3,0.8C8.1,39.9,8.2,40,8.4,40c0.2,0,0.4-0.1,0.5-0.4
c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5,0,0.8,0c0.4-0.1,0.7-0.3,0.9-0.7c0.2-0.4,0.3-0.8,0.2-1.1c-0.1-0.2-0.2-0.2-0.4-0.2
c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.3-0.5,0.3c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.3-0.3-0.7-0.3-1.1
c0-0.5,0.1-0.8,0.3-1.2c0.2-0.3,0.3-0.6,0.4-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.1
c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.8,0.4c-0.3,0.2-0.7,0.2-1.2,0.3c-0.5,0-0.9-0.1-1.2-0.3C5.2,34.8,5,34.5,5,34.2
c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.2-0.2,0.2-0.4c0-0.2-0.1-0.4-0.2-0.4c-0.4-0.1-0.8-0.1-1.1,0.2
c-0.4,0.2-0.6,0.5-0.7,0.9C4,34.1,4,34.3,4,34.5c0,0.2,0.1,0.4,0.3,0.6C4.1,35.3,4,35.5,4,35.7c0,0.2,0.1,0.3,0.3,0.4
c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.7,0.1,1.2,0c-0.2,0.1-0.4,0.2-0.7,0.3C5.2,36.7,4.9,36.8,4.7,36.9z"/>
<path style="fill:#14366B;" d="M36.4,5c-0.1-0.3-0.1-0.6-0.3-0.8C36,4,35.8,3.9,35.7,3.9c-0.2,0-0.3,0.1-0.5,0.4
C35,4.1,34.8,4,34.6,4c-0.2,0-0.5,0-0.8,0c-0.4,0.1-0.7,0.3-0.9,0.7c-0.2,0.4-0.2,0.8-0.1,1.1c0,0.2,0.1,0.2,0.4,0.2
c0.2,0,0.3-0.1,0.4-0.2c0-0.2,0.1-0.4,0.2-0.6C33.8,5.1,34,5,34.2,5c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.7,0.3,1.1
c0,0.5-0.1,0.8-0.3,1.2c-0.2,0.3-0.3,0.6-0.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2,0,0.3,0.1,0.5c0.2,0.1,0.3,0.2,0.5,0.1
c0.2-0.1,0.3-0.2,0.6-0.3c0.2-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.7-0.2,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3C38.9,9.1,39,9.4,39,9.7
c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.4,0.1,0.8,0.1,1.1-0.2
c0.4-0.2,0.6-0.5,0.7-0.9c0-0.3,0.1-0.5,0-0.7c0-0.2-0.1-0.4-0.3-0.6c0.3-0.2,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.3-0.4
c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4,0-0.7-0.1-1.1,0c0.2-0.1,0.4-0.2,0.7-0.3c0.3-0.1,0.5-0.2,0.8-0.2C39.6,7,39.8,6.9,40,6.8
c0.2-0.1,0.3-0.2,0.3-0.4c0.1-0.3,0-0.6-0.2-0.9C40,5.3,39.8,5,39.5,4.9c0.2-0.2,0.3-0.4,0.4-0.5c0.1-0.2,0.1-0.3,0-0.4
c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2-0.3-0.4-0.5-0.6-0.6c-0.3-0.1-0.5-0.2-0.9-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.2,0.7C36.4,5.7,36.4,5.3,36.4,5z"/>
<path style="fill:#14366B;" d="M34.3,38.9c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.4-0.2
c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.4-0.1,0.8,0.1,1.1c0.2,0.4,0.5,0.6,0.9,0.7c0.3,0.1,0.5,0.1,0.8,0c0.2,0,0.4-0.1,0.6-0.3
c0.2,0.3,0.4,0.4,0.5,0.4c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3,0.1-0.7,0-1.1c0.1,0.2,0.2,0.4,0.2,0.7
c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.5,0.3,0.6c0.1,0.2,0.2,0.3,0.4,0.3c0.3,0.1,0.6,0,0.9-0.1c0.3-0.1,0.5-0.3,0.6-0.6
c0.2,0.2,0.4,0.3,0.5,0.4c0.2,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.3-0.3-0.4-0.5c0.3-0.2,0.5-0.4,0.6-0.6
c0.2-0.3,0.2-0.5,0.2-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.1-0.7-0.2
c0.4,0.1,0.8,0,1.1,0c0.4-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2-0.1-0.4-0.4-0.6c0.2-0.2,0.3-0.3,0.3-0.6
c0-0.2,0-0.5,0-0.7c-0.1-0.4-0.3-0.7-0.7-0.9c-0.4-0.2-0.8-0.2-1.1-0.1c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.3,0.5c0,0.3-0.1,0.6-0.4,0.8c-0.3,0.2-0.7,0.3-1.2,0.2c-0.5,0-0.8-0.1-1.2-0.2
c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3,0-0.4,0.1c-0.1,0.2-0.2,0.3-0.1,0.5c0.1,0.2,0.2,0.4,0.3,0.6
c0.1,0.2,0.3,0.5,0.4,0.8c0.1,0.3,0.2,0.7,0.2,1.2c0.1,0.5,0,0.9-0.2,1.1C34.9,38.8,34.6,38.9,34.3,38.9z"/>
	
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A10">
<title>A10</title>
<path style="fill:#14366B;" d="M13.2,4.8c-0.4-0.1-0.8-0.1-1-0.1c-0.9,0.1-1.6,0.8-2.1,1.7C9.8,6.2,9.6,5.9,9.4,5.7
C7.7,3.8,5.1,4,4.4,4C4.3,4.7,3.9,7.3,5.6,9.2C5.8,9.4,6,9.6,6.3,9.8c-0.9,0.5-1.6,1.2-1.8,2c-0.1,0.3-0.1,0.6,0,1
c0.8-0.3,2.2-0.6,3.9-0.5c1.3,0.1,2.4,0.3,3.2,0.6c0.3,0.2,0.7,0.1,1-0.1c0.3-0.2,0.4-0.6,0.2-1c-0.2-0.8-0.4-1.9-0.3-3.2
C12.4,6.9,12.8,5.6,13.2,4.8z"/>
<path style="fill:#14366B;" d="M30.9,11.5c-0.2,0.3-0.1,0.7,0.1,1c0.2,0.3,0.6,0.4,1,0.2c0.8-0.2,1.9-0.4,3.2-0.3
c1.7,0.1,3,0.6,3.8,0.9c0.1-0.4,0.1-0.8,0.1-1c-0.1-0.9-0.7-1.6-1.6-2.2c0.2-0.2,0.5-0.3,0.7-0.5c1.9-1.7,1.8-4.3,1.7-5
c-0.7-0.1-3.3-0.5-5.2,1.1c-0.2,0.2-0.4,0.4-0.6,0.7c-0.5-1-1.1-1.6-2-1.9c-0.3-0.1-0.6-0.1-1-0.1c0.3,0.8,0.6,2.2,0.5,3.9
C31.5,9.6,31.2,10.7,30.9,11.5z"/>
<path style="fill:#14366B;" d="M30.6,39.4c0.4,0.1,0.8,0.1,1,0.1c0.9-0.1,1.6-0.7,2.2-1.5c0.1,0.3,0.3,0.5,0.5,0.8
c1.6,1.9,4.2,2,4.9,1.9c0.2-0.7,0.7-3.3-0.9-5.2c-0.2-0.2-0.4-0.5-0.6-0.6c1-0.4,1.7-1.1,2-1.9c0.1-0.3,0.1-0.6,0.1-1
c-0.8,0.2-2.2,0.5-3.9,0.3c-1.3-0.1-2.4-0.5-3.1-0.8c-0.3-0.2-0.7-0.1-1,0.1c-0.3,0.2-0.4,0.6-0.3,0.9c0.2,0.8,0.3,1.9,0.2,3.2
C31.5,37.4,31,38.6,30.6,39.4z"/>
<path style="fill:#14366B;" d="M13.1,31.9c0.2-0.3,0.1-0.7-0.1-1c-0.2-0.3-0.6-0.3-1-0.2c-0.8,0.2-1.9,0.4-3.2,0.4
c-1.7-0.1-3-0.5-3.8-0.8c-0.1,0.4-0.1,0.8,0,1c0.1,0.9,0.8,1.6,1.7,2.1c-0.2,0.2-0.5,0.3-0.7,0.5c-1.8,1.7-1.7,4.3-1.6,5
c0.7,0.1,3.3,0.5,5.1-1.2c0.2-0.2,0.4-0.4,0.6-0.7c0.5,0.9,1.2,1.6,2,1.8c0.3,0.1,0.6,0.1,1,0c-0.3-0.8-0.6-2.2-0.6-3.9
C12.5,33.8,12.8,32.7,13.1,31.9z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A11">
<title>A11</title>
<path style="fill:#FFFFFF;" d="M3.6,20.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0.1c0.2,0.4,0.5,0.8,0.8,1c0.1,0.1,0.3,0.2,0.5,0.3
			c0.1,0,0.1,0.1,0.2,0.1C5.3,22,5.4,22,5.5,22.1c0,0-0.1,0-0.1,0.1c-0.6,0.3-1.1,0.7-1.5,1.3c-0.3,0.6-0.5,1.2-0.4,1.9
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.1,0.1,0.1
			c0.4,0.3,0.9,0.5,1.5,0.4c0.6-0.1,1.1-0.3,1.4-0.8c0.5-0.7,0.4-1.3,0.1-2C7,24,6.9,23.8,6.8,23.7c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.5-0.1-1.1,0.3-1.2,0.7c0,0.2-0.1,0.3,0,0.5c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0,0,0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.3-0.6
			c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0,0.1-0.1,0.2-0.1
			c0.2-0.1,0.4-0.1,0.6-0.1c0,0,0,0,0,0c0.6,0.1,1,0.4,1.3,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0.3,0,0.6-0.1,0.9c0,0,0,0,0.1,0
			c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.5-0.1,0.7C8.1,27.8,8,28,8,28.1
			c0,0-0.1,0.1-0.1,0.2c-0.2,0.3-0.3,0.6-0.5,1c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2,0.3-0.4,0.6-0.6,0.9
			c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0
			c-0.7,1.5-0.7,2.9,0.1,4.3c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1,0.1,0.1,0.1,0.2c0.4,0.4,0.8,0.8,1.2,1.2c0.1,0,0.2,0.1,0.2,0.2
			c0.7,0.5,1.4,0.8,2.2,0.9c0.3,0,0.7,0,1,0c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.2c0.3-0.1,0.5-0.3,0.8-0.5c0.1-0.1,0.2-0.2,0.4-0.3
			c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6c0.4-0.3,0.8-0.5,1.3-0.7c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0
			c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0.6-0.2,1.1-0.2,1.6,0.1c0.1,0,0.1,0,0.2,0.1h0c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2
			c0.2,0.2,0.3,0.4,0.4,0.7c0,0.1,0.1,0.3,0.1,0.4c0,0.7-0.6,1.4-1.4,1.2c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.2-0.4-0.3c0,0,0-0.1,0-0.1
			c0,0,0,0,0.1,0c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.5-0.1c0.4-0.2,0.6-0.7,0.5-1.1c0-0.2-0.1-0.4-0.2-0.6c0,0,0,0,0-0.1
			c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.1-0.6-0.2-0.9-0.2c-0.2,0-0.4,0-0.5,0.1
			c-0.2,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.3,0,0.6,0.2,0.9
			c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.4,0.4,0.7,0.5c0.4,0.1,0.8,0.2,1.3,0.1c0.3,0,0.7-0.1,1-0.3c0.1,0,0.1,0,0.2-0.1
			c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0.1,0c0.2-0.2,0.4-0.4,0.6-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0.1-0.1c0,0,0,0,0,0
			c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0,0.1c0.2,0.4,0.4,0.7,0.7,0.9c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.2,0.7,0.3,1.1,0.3
			c0.3,0,0.5,0.1,0.8,0c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2
			c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.3-0.8,0.2-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.3-0.6-0.8-0.9-1.4-1c-0.5-0.1-1,0-1.4,0.3
			c-0.3,0.2-0.5,0.5-0.6,0.8c0,0,0,0,0,0.1c-0.1,0.5,0,0.9,0.4,1.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.4-0.2c0,0,0,0,0.1,0
			c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4-0.1
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.2-0.1-0.4-0.1-0.6c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0
			c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.3-0.4,0.4-0.5c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2
			c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0.1c0,0,0.1,0,0.1,0c-0.1-0.2,0-0.3,0.1-0.3
			c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
			c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0.2,0.1,0.5,0.3,0.7,0.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0.1,0.3,0.2,0.4,0.3
			c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.8,0.6,1.8,0.8,2.8,0.8c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0,0
			c0.2,0,0.4-0.1,0.6-0.2c0,0,0,0,0,0h0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.4-0.3,0.6-0.4l0,0l0,0
			c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.6,0.3-0.9c0-0.1,0-0.3,0-0.4
			c0-0.3,0-0.6,0-0.8c0,0,0,0,0-0.1c-0.1-0.5-0.2-0.9-0.4-1.3c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
			c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.2-0.3-0.3-0.4-0.5c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.5-0.7-0.7-1.1
			c-0.2-0.3-0.3-0.5-0.4-0.8c0-0.1-0.1-0.2-0.1-0.4c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.3,0.1-0.4c0,0,0-0.1,0-0.1
			c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1c-0.2-0.6-0.2-1.2,0-1.7c0,0,0-0.1,0.1-0.1
			c0.1-0.2,0.3-0.5,0.5-0.6c0.4-0.3,1.1-0.6,1.8-0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.6
			c0,0-0.1,0.1-0.1,0c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.3-0.1-0.6-0.4-0.8c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.2-0.1-0.5-0.1-0.7,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0.5-0.4,1.1-0.2,1.7
			c0.2,0.5,0.5,0.8,0.9,1c0.3,0.2,0.7,0.3,1.1,0.2c0.1,0,0.2,0,0.3,0c0.4-0.1,0.7-0.2,0.9-0.5c0.1-0.1,0.1-0.1,0.2-0.2
			c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c-0.1-0.8-0.4-1.5-1.1-2
			c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c0,0-0.1,0-0.1-0.1c0,0,0,0,0.1,0c0.3-0.1,0.6-0.3,0.9-0.5
			c0.4-0.4,0.7-0.8,0.9-1.3c0.1-0.3,0.2-0.6,0.2-1c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1-0.1-0.1-0.1c-0.9-0.5-2.1-0.1-2.6,0.7c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.3,0,0.5,0.1,0.8
			c0-0.1,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.1,0.2,0.2,0.3,0.2
			c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.1,0.9-0.2,1.1-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.3,0.6-0.6,0.8
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.3-0.5-0.4c0-0.1-0.1-0.1-0.1-0.2
			c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.5-0.2-1,0-1.5c0,0,0,0,0-0.1c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.4,0-0.8,0.2-1.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3
			c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0-0.1c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1
			c0,0,0,0,0-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.3,0.4-0.5
			c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.2-0.4,0.3-0.9,0.3-1.3c0-0.3,0-0.6,0-0.9
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.1-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.4-0.6c0,0,0,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
			c-0.3-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.4-0.1-0.7-0.2-1.1-0.2
			c0,0-0.1,0-0.1,0c-0.9,0-1.7,0.2-2.4,0.6c-0.2,0.1-0.5,0.3-0.7,0.5c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0.1-0.4,0.3-0.6,0.4c-0.3,0.2-0.6,0.4-0.9,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.2,0.1-0.4,0.1
			c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.6-0.2C25.9,8,25.9,8,25.8,7.9c0-0.1-0.1-0.1,0-0.2c0,0,0,0,0-0.1
			c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.1-0.1-0.2-0.1
			c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1
			c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
			c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.2,0.3-0.3,0.5-0.4c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
			c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.2-0.3-0.2-0.6-0.2
			c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.2,0.1,0.4,0.2,0.6c0,0,0,0.1,0.1,0.1
			c0.1,0.2,0.3,0.4,0.5,0.5c0,0,0.1,0,0.1,0.1c0.4,0.2,0.8,0.3,1.2,0.2c0.6-0.1,1-0.4,1.3-0.9c0.1-0.2,0.2-0.5,0.2-0.7c0,0,0,0,0,0
			c0.1-0.3,0-0.6-0.1-0.9c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.2-0.3C26.3,4.2,26.2,4,26,3.9c-0.5-0.3-1-0.4-1.6-0.4
			c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.1-0.9,0.3C23.1,4,23,4.1,22.8,4.2c0,0,0,0-0.1,0c-0.4,0.3-0.6,0.7-0.8,1.2c0,0,0,0.1-0.1,0.1
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c-0.2-0.3-0.4-0.5-0.6-0.7
			c-0.2-0.1-0.3-0.3-0.5-0.3c-0.3-0.2-0.6-0.3-0.9-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0
			c-0.3,0-0.6,0.1-0.9,0.2C17.4,4,17.1,4.4,17,4.9c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
			c0,0.1,0.1,0.3,0.2,0.4c0.3,0.5,0.6,0.8,1.2,1c0.4,0.1,0.7,0.1,1.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1
			c0.1,0,0.2-0.1,0.2-0.2c0.3-0.3,0.5-0.6,0.5-1c0-0.3-0.1-0.6-0.3-0.9C20,5,19.7,4.9,19.4,4.9c-0.1,0-0.2,0.1-0.3,0.1
			c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0C18.9,5.1,19,5,19,5c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0
			c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.1,0.4
			c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.2,0.2
			c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.1-0.9,0.1-1.4,0c0,0,0,0,0,0
			c0,0.2,0,0.2-0.1,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c-0.4,0-0.7,0-1.1-0.1
			c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0
			c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1-0.1-0.3-0.1-0.4-0.3
			c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.4-0.3-0.6-0.3c0,0,0,0,0,0c-0.3-0.1-0.7-0.3-1.1-0.3c-0.5-0.1-0.9-0.1-1.4-0.1
			c-0.2,0-0.4,0.1-0.6,0.1c0,0,0,0,0,0C8.4,5.7,7.9,6,7.4,6.4c-0.1,0-0.1,0.1-0.2,0.1C7.1,6.6,7,6.8,6.9,6.9C6.8,7,6.7,7,6.6,7.1
			C6.6,7.2,6.5,7.3,6.4,7.4C6.4,7.4,6.3,7.5,6.3,7.5c0,0,0,0,0,0.1C6.2,7.7,6.1,7.7,6,7.8C5.9,8,5.8,8.1,5.7,8.3
			C5.7,8.4,5.6,8.5,5.6,8.6C5.5,8.7,5.4,8.9,5.4,9.1c-0.1,0.4-0.2,0.8-0.2,1.2c0,0,0,0.1,0,0.1c0,0.7,0.1,1.3,0.4,2
			c0.1,0.3,0.3,0.5,0.5,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.3,0.3,0.4,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2
			c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.5,0.4,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1C8,16.1,8,16.3,8.1,16.5c0.1,0.4,0.1,0.7,0,1.1C8.1,17.7,8,17.9,7.9,18
			c-0.1,0.1-0.2,0.2-0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.1,0.7c0,0,0,0.1-0.1,0.1
			c-0.2,0.5-0.4,0.8-0.9,1.1C6.4,20.9,6.2,21,5.9,21c-0.3,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.8-0.1-1.1
			c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.2,0,0.4,0.1,0.6c0,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0
			c0.4-0.1,0.6-0.3,0.8-0.6c0.2-0.3,0.3-0.6,0.3-1c0-0.2,0-0.5-0.1-0.7c0-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2C5.6,17,4.8,17,4.1,17.6c-0.3,0.2-0.4,0.4-0.5,0.8c-0.1,0.4-0.2,0.8-0.1,1.2
			C3.5,19.7,3.6,20,3.6,20.2z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A12">
<title>A12</title>
<path style="fill:#5692B0;" d="M42.5,21.8c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1-0.1c-0.4-0.2-0.7-0.4-1-0.7c-1-0.9-1.7-2-2.2-3.2
c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1-0.1-1.5
c0-0.4,0-0.8,0.1-1.3c0-0.2,0-0.3,0.1-0.5c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.2,0-0.3,0.1-0.5c0-0.1,0-0.1,0-0.2
c0.1-0.4,0.1-0.9,0.2-1.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0.1-0.4c0-0.2,0-0.3,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.3,0.1-0.7,0.2-1
c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.5c0.1-0.7,0-1.4-0.1-2.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3
c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.3-0.1-0.7-0.1-1.1-0.2c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
c-0.2,0-0.4,0-0.6,0.1c-0.7,0.1-1.5,0.2-2.2,0.4c-0.1,0-0.2,0-0.2,0c0,0,0,0,0-0.1c0,0,0,0,0,0.1c-0.2,0-0.3,0.1-0.5,0.1
c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.1,0-0.2,0.1c-0.5,0.1-1,0.2-1.6,0.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.4,0.1-0.8,0.1-1.2,0.1
c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.5,0-0.9,0-1.3-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.5-0.1-1-0.3-1.4-0.5c-0.5-0.2-1-0.5-1.4-0.9
c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.1-0.2
c-0.2-0.3-0.4-0.5-0.5-0.8C22.1,1.7,22,1.6,22,1.5c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0.1c-0.1,0.4-0.3,0.8-0.6,1.1
c0,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0.1C20.3,3.6,20,3.8,19.8,4
c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.7,0.3-1.1,0.4
c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.3,0-0.4,0
c-0.2,0-0.4,0-0.6,0c-0.8,0-1.6-0.1-2.4-0.3c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5-0.1c-0.4-0.1-0.7-0.1-1.1-0.2
c-0.3-0.1-0.6-0.1-1-0.2c-0.2,0-0.4-0.1-0.6-0.1C8,4.4,7.4,4.3,6.9,4.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0
c-0.5,0-0.9,0.1-1.4,0.2c0,0-0.1,0-0.1,0c-0.2,0-0.2,0.1-0.3,0.3C4.2,5.4,4.1,6,4.2,6.6c0,0.2,0,0.4,0,0.6l0,0
c0,0.4,0.1,0.8,0.1,1.2c0,0,0,0,0,0.1c0,0.3,0.1,0.6,0.1,0.9c0,0,0,0,0,0c0.2,1.1,0.5,2.1,0.6,3.2c0,0.1,0,0.1,0,0.2
c0,0.2,0.1,0.3,0.1,0.5l0,0c0.1,0.3,0.1,0.7,0.1,1c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0,0.9c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.6
c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.1,0.6-0.2,0.9c0,0.1-0.1,0.2-0.1,0.4c-0.2,0.7-0.6,1.4-1.1,2c-0.1,0.2-0.3,0.4-0.5,0.5
c0,0.1-0.1,0.1-0.2,0.2C3,21,3,21.1,2.9,21.2c-0.3,0.2-0.6,0.5-0.9,0.6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.2
c0,0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.1,0.7,1.5,1.2c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.3,0.4,0.5
c0.3,0.5,0.6,1,0.7,1.6c0.1,0.2,0.1,0.3,0.1,0.5l0,0c0,0.1,0,0.2,0,0.2c0.1,0.4,0.2,0.9,0.2,1.3c0,0.3,0,0.6,0,0.9
c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0.1,0.1,0,0.1,0,0.2c0,0.9-0.1,1.8-0.3,2.7c-0.1,0.7-0.2,1.3-0.4,2c0,0.2-0.1,0.5-0.1,0.7
c-0.1,0.7-0.2,1.4-0.3,2.2c0,0.7,0,1.4,0.2,2c0,0,0,0,0,0c0,0.2,0.2,0.3,0.4,0.4c0.7,0.1,1.3,0.2,2,0.1c0.1,0,0.2,0,0.3,0
c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4,0c0.4-0.1,0.9-0.1,1.3-0.2c0.9-0.2,1.8-0.4,2.8-0.6
c0.8-0.1,1.5-0.2,2.3-0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0.5,0,0.9,0,1.4,0c1,0.1,1.9,0.3,2.9,0.7c0.8,0.3,1.5,0.8,2.1,1.4
c0.4,0.4,0.7,0.8,1,1.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.4,0.4-0.7,0.7-1
c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.8-0.5,1.2-0.6
c1.2-0.5,2.5-0.6,3.8-0.6c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
c0.7,0.1,1.5,0.2,2.2,0.3c0.1,0,0.3,0,0.4,0.1c0.4,0.1,0.8,0.2,1.2,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
c0.7,0.1,1.5,0.3,2.2,0.3c0.3,0,0.7,0,1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
c0-0.1,0.1-0.2,0.1-0.3c0-0.3,0.1-0.7,0.1-1c0-0.3,0-0.6,0-0.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
c0-0.3-0.1-0.6-0.1-1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c-0.1-0.4-0.2-0.7-0.2-1.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.2-1-0.4-2-0.5-3c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.1-0.6-0.1-1c0-0.6,0-1.3,0.1-1.9
c0-0.1,0-0.1,0-0.2c0.1-0.5,0.2-1,0.3-1.4c0.3-0.9,0.8-1.7,1.4-2.4c0.6-0.7,1.3-1.2,2.1-1.5c0,0,0.1,0,0.1-0.1
C42.6,21.8,42.5,21.8,42.5,21.8z M39.4,22.1c-0.8,0.8-1.5,1.5-2.3,2.3c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.4,0.4-0.6,0.6
c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0,1.7,0,3.4,0,5.1c0,0.4,0,0.8,0,1.2c0,0.3,0,0.7,0,1c0,0.2,0,0.5,0,0.7
c0,0.6,0,1.1,0,1.7c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.6,0-1.2,0-1.7,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0
c-0.5,0-1.1,0-1.6,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-1.2,0-2.5,0-3.7,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-1.2,1.2-2.3,2.3-3.5,3.5c-0.1,0.1-0.1,0.1-0.3,0
c-1.2-1.2-2.4-2.3-3.5-3.5c-0.1-0.1-0.2-0.1-0.3-0.1c-1.7,0-3.5,0-5.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-1.4,0-2.7,0-4.1,0
c-0.2,0-0.2,0-0.2-0.2c0-1.2,0-2.5,0-3.7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-1.3,0-2.7,0-4c0-0.1,0-0.1,0-0.2
c0-0.4,0-0.8,0-1.3c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.4-0.4-0.7-0.7c-0.3-0.3-0.7-0.7-1-1c0,0-0.1-0.1-0.1-0.1
c-0.6-0.6-1.1-1.1-1.7-1.7c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1,0-0.2c0.5-0.5,1-1,1.4-1.5c0,0,0.1-0.1,0.1-0.1
c0.5-0.5,0.9-0.9,1.4-1.4c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0-0.5,0-1.1,0-1.6
c0-1.8,0-3.6,0-5.4c0-0.2,0-0.3,0-0.5c0,0,0-0.1,0-0.1c0-0.7,0-1.4,0-2.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0
c1.3,0,2.5,0,3.7,0c0,0,0.1,0,0.1,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c1.5,0,3,0,4.5,0c0.1,0,0.2,0,0.3-0.1
C18.8,7.6,19.4,7,20,6.5c0,0,0.1-0.1,0.1-0.1c0.4-0.4,0.7-0.8,1.1-1.1c0.2-0.2,0.4-0.4,0.6-0.7c0,0,0.1-0.1,0.1,0
c0,0,0.1,0.1,0.1,0.1c1.2,1.2,2.3,2.3,3.5,3.5c0.1,0.1,0.2,0.1,0.3,0.1c0.9,0,1.7,0,2.6,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0
c1.6,0,3.1,0,4.7,0c0,0,0.1,0,0.1,0c0.5,0,1,0,1.4,0c0.1,0,0.3-0.1,0.3,0c0.1,0.1,0,0.2,0,0.3c0,1.8,0,3.6,0,5.4
c0,0.2,0,0.5,0,0.7c0,0,0,0.1,0,0.1c0,0.9,0,1.8,0,2.7c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0.1,0.3
c1.2,1.2,2.3,2.3,3.5,3.5C39.6,21.9,39.6,21.9,39.4,22.1z"/>
</symbol>





  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B1">
  <title>B1</title>
  <path  style="fill:#3F94B3;" d="M36.6,22c-1.4-1.4-2.8-2.8-4.1-4.3c-0.2-2-0.3-3.9-0.2-5.9c-2,0-4,0-5.9-0.2
c-1.5-1.3-3-2.6-4.3-4c-1.4,1.4-2.8,2.8-4.4,4c-2,0.2-4,0.3-5.9,0.2c0,2,0,3.9-0.2,5.9c-1.3,1.5-2.6,3-4.1,4.3
c1.4,1.4,2.8,2.8,4.1,4.3c0.2,2,0.3,3.9,0.2,5.9c2,0,4,0,5.9,0.2c1.5,1.3,3,2.6,4.4,4c1.4-1.4,2.8-2.8,4.3-4c2-0.2,4-0.3,5.9-0.2
c0-2,0-3.9,0.2-5.9C33.8,24.8,35.1,23.4,36.6,22z M30.8,25.6c-0.2,1.6-0.2,3.3-0.2,4.9c-1.6,0-3.3,0.1-4.9,0.2
c-1.3,1-2.5,2.1-3.6,3.3c-1.2-1.2-2.4-2.2-3.6-3.3c-1.6-0.1-3.3-0.2-4.9-0.2c0-1.6-0.1-3.3-0.2-4.9c-1.1-1.3-2.1-2.5-3.3-3.6
c1.2-1.1,2.3-2.4,3.3-3.6c0.2-1.6,0.2-3.3,0.2-4.9c1.6,0,3.3-0.1,4.9-0.2c1.3-1,2.5-2.1,3.6-3.3c1.2,1.2,2.4,2.2,3.6,3.3
c1.6,0.1,3.3,0.2,4.9,0.2c0,1.6,0.1,3.3,0.2,4.9c1.1,1.3,2.1,2.5,3.3,3.6C32.9,23.1,31.8,24.4,30.8,25.6z"/>
</symbol>


    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B2">
    <title>B2</title>
    <path style="fill:#FA9F2C;" d="M22.6,21.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2-0.1-0.4-0.1
    c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0.1,0.2,0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.2
    c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2,0.1,0.4,0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.2
    c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.2,0.1-0.4C22.8,21.6,22.7,21.5,22.6,21.4z"/>
<path style="fill:#FA9F2C;" d="M36.2,16.2c-1.2-0.8-2.3-1.6-3.5-2.4c-0.5-0.3-1-0.7-1.5-1c-0.3-0.5-0.7-1-1-1.5
    c-0.8-1.1-1.6-2.3-2.4-3.4c-1.4,0.2-2.8,0.5-4.2,0.7C23.2,8.7,22.6,8.8,22,9c-0.6-0.1-1.2-0.2-1.7-0.4c-1.4-0.3-2.8-0.5-4.2-0.7
    c-0.8,1.1-1.6,2.3-2.4,3.4c-0.3,0.5-0.7,1-1,1.5c-0.5,0.3-1,0.6-1.5,1c-1.2,0.8-2.3,1.6-3.5,2.4c0.2,1.4,0.5,2.7,0.7,4.1
    c0.1,0.6,0.2,1.1,0.4,1.7c-0.1,0.6-0.2,1.2-0.4,1.7c-0.3,1.4-0.5,2.7-0.7,4.1c1.2,0.8,2.3,1.6,3.5,2.4c0.5,0.3,1,0.7,1.5,1
    c0.3,0.5,0.7,1,1,1.5c0.8,1.1,1.6,2.3,2.4,3.4c1.4-0.2,2.8-0.5,4.2-0.7c0.6-0.1,1.2-0.2,1.7-0.4c0.6,0.1,1.2,0.2,1.7,0.4
    c1.4,0.3,2.8,0.5,4.2,0.7c0.8-1.1,1.6-2.3,2.4-3.4c0.3-0.5,0.7-1,1-1.5c0.5-0.3,1-0.6,1.5-1c1.2-0.8,2.3-1.6,3.5-2.4
    c-0.2-1.4-0.5-2.7-0.7-4.1c-0.1-0.6-0.2-1.1-0.4-1.7c0.1-0.6,0.2-1.2,0.4-1.7C35.8,18.9,36,17.5,36.2,16.2z M27.4,8.9
    C27.4,8.9,27.4,8.9,27.4,8.9c0.1,0,0.1,0,0.1,0c1,1.4-0.4,2.7-1.6,3.6C25.8,11.1,25.7,9.2,27.4,8.9z M16.5,9
    C16.5,8.9,16.5,8.9,16.5,9c0.1-0.1,0.1-0.1,0.1,0c1.7,0.3,1.6,2.2,1.5,3.6C16.9,11.7,15.5,10.4,16.5,9z M8.8,16.6
    C8.8,16.6,8.8,16.6,8.8,16.6c0-0.1,0-0.1,0-0.1c1.4-1,2.7,0.4,3.6,1.6C11,18.3,9.1,18.3,8.8,16.6z M8.9,27.5
    C8.8,27.4,8.8,27.4,8.9,27.5c-0.1-0.1-0.1-0.1,0-0.1c0.3-1.7,2.2-1.6,3.7-1.4C11.6,27.1,10.3,28.5,8.9,27.5z M16.6,35.1
    C16.6,35.1,16.6,35.1,16.6,35.1c-0.1,0-0.1,0-0.1,0c-1-1.4,0.4-2.7,1.6-3.6C18.2,32.9,18.3,34.8,16.6,35.1z M27.5,35
    C27.5,35.1,27.5,35.1,27.5,35c-0.1,0.1-0.1,0.1-0.1,0c-1.7-0.3-1.6-2.2-1.5-3.6C27.1,32.3,28.5,33.6,27.5,35z M31.7,30
    c-0.5,0.3-0.9,0.3-1.4,0.2c0.1,0.4,0.1,0.9-0.2,1.4c-1,1.5-3,0.4-4.2-0.4c-0.3,1.4-0.9,3.6-2.7,3.2c-0.6-0.1-0.9-0.4-1.1-0.8
    c-0.2,0.4-0.6,0.7-1.1,0.8c-1.8,0.4-2.4-1.8-2.7-3.2c-1.2,0.8-3.2,1.9-4.2,0.4c-0.3-0.5-0.4-0.9-0.2-1.4c-0.4,0.1-0.9,0.1-1.4-0.2
    c-1.5-1-0.4-3,0.4-4.2c-1.4-0.3-3.6-0.9-3.2-2.7c0.1-0.6,0.4-0.9,0.8-1.1c-0.4-0.2-0.7-0.6-0.8-1.1c-0.4-1.8,1.8-2.4,3.2-2.7
    C11.9,17,10.8,15,12.3,14c0.5-0.3,0.9-0.3,1.4-0.2c-0.1-0.4-0.1-0.9,0.2-1.4c1-1.5,3-0.4,4.2,0.4c0.3-1.4,0.9-3.6,2.7-3.2
    c0.6,0.1,0.9,0.4,1.1,0.8c0.2-0.4,0.6-0.7,1.1-0.8c1.8-0.4,2.4,1.8,2.7,3.2c1.2-0.8,3.2-1.9,4.2-0.4c0.3,0.5,0.4,0.9,0.2,1.4
    c0.4-0.1,0.9-0.1,1.4,0.2c1.5,1,0.4,3-0.4,4.2c1.4,0.3,3.6,0.9,3.2,2.7c-0.1,0.6-0.4,0.9-0.8,1.1c0.4,0.2,0.7,0.6,0.8,1.1
    c0.4,1.8-1.8,2.4-3.2,2.7C32.1,27,33.2,29,31.7,30z M35.2,27.4C35.2,27.4,35.2,27.4,35.2,27.4c0,0.1,0,0.1,0,0.1
    c-1.4,1-2.7-0.4-3.6-1.6C33,25.7,34.9,25.7,35.2,27.4z M35.2,16.6c-0.3,1.7-2.2,1.6-3.7,1.4c0.9-1.1,2.2-2.5,3.6-1.6
    C35.2,16.6,35.2,16.6,35.2,16.6C35.2,16.6,35.2,16.6,35.2,16.6z"/>
<path style="fill:#FA9F2C;" d="M30.4,25.4c-0.2-1.1-0.4-2.3-0.7-3.4c0.2-1.1,0.5-2.3,0.7-3.4c-1-0.7-1.9-1.3-2.9-2
    c-0.6-1-1.3-1.9-2-2.9c-1.2,0.2-2.3,0.4-3.5,0.7c-1.1-0.2-2.3-0.5-3.5-0.7c-0.7,1-1.3,1.9-2,2.9c-1,0.6-2,1.3-2.9,2
    c0.2,1.1,0.4,2.3,0.7,3.4c-0.2,1.1-0.5,2.3-0.7,3.4c1,0.7,1.9,1.3,2.9,2c0.6,1,1.3,1.9,2,2.9c1.2-0.2,2.3-0.4,3.5-0.7
    c1.1,0.2,2.3,0.5,3.5,0.7c0.7-1,1.3-1.9,2-2.9C28.4,26.8,29.4,26.1,30.4,25.4z M25.3,27.8c-0.2,0.3-0.3,0.5-0.5,0.7
    c-0.3-0.1-0.6-0.1-0.9-0.2c-0.6-0.1-1.2-0.2-1.9-0.4c-0.6,0.1-1.2,0.3-1.9,0.4c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2-0.2-0.3-0.5-0.5-0.7
    c-0.4-0.5-0.7-1-1-1.6c-0.5-0.3-1.1-0.7-1.6-1c-0.3-0.2-0.5-0.3-0.8-0.5c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.6,0.2-1.2,0.4-1.8
    c-0.1-0.6-0.3-1.2-0.4-1.8c-0.1-0.3-0.1-0.6-0.2-0.9c0.3-0.2,0.5-0.3,0.8-0.5c0.5-0.4,1.1-0.7,1.6-1c0.3-0.5,0.7-1,1-1.6
    c0.2-0.3,0.3-0.5,0.5-0.7c0.3,0.1,0.6,0.1,0.9,0.2c0.6,0.1,1.2,0.2,1.9,0.4c0.6-0.1,1.2-0.3,1.9-0.4c0.3-0.1,0.6-0.1,0.9-0.2
    c0.2,0.2,0.3,0.5,0.5,0.7c0.4,0.5,0.7,1,1,1.6c0.5,0.3,1.1,0.7,1.6,1c0.3,0.2,0.5,0.3,0.8,0.5c-0.1,0.3-0.1,0.6-0.2,0.9
    c-0.1,0.6-0.2,1.2-0.4,1.8c0.1,0.6,0.3,1.2,0.4,1.8c0.1,0.3,0.1,0.6,0.2,0.9c-0.3,0.2-0.5,0.3-0.8,0.5c-0.5,0.4-1.1,0.7-1.6,1
    C26,26.8,25.6,27.3,25.3,27.8z"/>

    <path style="fill:#D03D41;" d="M28.5,13.4c0.1,0.1,0.1,0.1,0.1,0.2c0.2,0.6-0.9,1-1.4,1.2c0.2,0.3,0.4,0.6,0.6,0.9
    c0.9-0.6,2.2-1.6,1.4-2.6c-0.7-1-2.1-0.2-3,0.4c0.2,0.3,0.4,0.6,0.6,0.9C27.2,13.8,28,12.9,28.5,13.4z"/>
<path style="fill:#D03D41;" d="M16.3,15.6c0.2-0.3,0.4-0.6,0.6-0.9c-0.5-0.2-1.6-0.6-1.4-1.2c0-0.1,0-0.1,0.1-0.2
    c0.5-0.5,1.3,0.4,1.7,0.8c0.2-0.3,0.4-0.6,0.6-0.9c-0.9-0.6-2.2-1.4-3-0.4C14.1,14,15.4,15,16.3,15.6z"/>
<path style="fill:#D03D41;" d="M14.1,17.3c-0.4-0.4-1.3-1.2-0.8-1.7c0.1-0.1,0.1-0.1,0.2-0.1c0.6-0.2,1,0.9,1.2,1.4
    c0.3-0.2,0.6-0.4,0.9-0.6c-0.6-0.9-1.6-2.2-2.6-1.4c-1,0.7-0.2,2.1,0.4,2.9C13.5,17.6,13.8,17.5,14.1,17.3z"/>
<path style="fill:#D03D41;" d="M13.4,21.5c-0.1-0.3-0.1-0.7-0.2-1c-0.5,0.2-1.5,0.7-1.8,0.2c0,0-0.1-0.1-0.1-0.2
    c0-0.7,1.2-0.6,1.8-0.6c-0.1-0.3-0.1-0.7-0.2-1c-1,0.2-2.6,0.6-2.4,1.8C10.7,21.9,12.3,21.7,13.4,21.5z"/>
<path style="fill:#D03D41;" d="M20.4,30.8c0.2,0.5,0.7,1.5,0.2,1.8c0,0-0.1,0.1-0.2,0.1c-0.7,0-0.6-1.2-0.6-1.8
    c-0.3,0.1-0.7,0.1-1,0.2c0.2,1,0.6,2.6,1.8,2.3c1.3-0.2,1-1.8,0.8-2.8C21.1,30.6,20.8,30.7,20.4,30.8z"/>
<path style="fill:#D03D41;" d="M15.5,27.7c-0.3-0.2-0.6-0.4-0.9-0.6c-0.2,0.5-0.6,1.6-1.2,1.4c-0.1,0-0.1,0-0.2-0.1
    c-0.5-0.5,0.4-1.3,0.8-1.7c-0.3-0.2-0.6-0.4-0.9-0.6c-0.6,0.9-1.4,2.2-0.4,2.9C13.9,29.8,14.9,28.5,15.5,27.7z"/>
<path style="fill:#D03D41;" d="M15.5,30.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.6,0.9-1,1.4-1.2c-0.2-0.3-0.4-0.6-0.6-0.9
    C15.4,29,14.1,30,14.8,31c0.7,1,2.1,0.2,3-0.4c-0.2-0.3-0.4-0.6-0.6-0.9C16.8,30.2,16,31.1,15.5,30.6z"/>
<path style="fill:#D03D41;" d="M13,24.2c-0.6,0-1.8,0.1-1.8-0.6c0-0.1,0-0.2,0.1-0.2c0.3-0.5,1.4,0,1.8,0.2c0.1-0.3,0.1-0.7,0.2-1
    c-1-0.2-2.6-0.4-2.9,0.8c-0.2,1.2,1.3,1.6,2.4,1.8C12.9,24.9,13,24.5,13,24.2z"/>
<path style="fill:#D03D41;" d="M30.6,22.5c0.1,0.3,0.1,0.7,0.2,1c0.5-0.2,1.5-0.7,1.8-0.2c0,0,0.1,0.1,0.1,0.2
    c0,0.7-1.2,0.6-1.8,0.6c0.1,0.3,0.1,0.7,0.2,1c1-0.2,2.6-0.6,2.4-1.8C33.3,22.1,31.7,22.3,30.6,22.5z"/>
<path style="fill:#D03D41;" d="M20.4,11.3c0.1,0,0.2,0,0.2,0.1c0.5,0.3,0,1.3-0.2,1.8c0.3,0.1,0.7,0.1,1,0.2c0.2-1,0.4-2.6-0.8-2.8
    c-1.2-0.2-1.6,1.3-1.8,2.3c0.3,0.1,0.7,0.1,1,0.2C19.8,12.5,19.7,11.4,20.4,11.3z"/>
<path style="fill:#D03D41;" d="M23.6,13.2c-0.2-0.5-0.7-1.5-0.2-1.8c0,0,0.1-0.1,0.2-0.1c0.7,0,0.6,1.2,0.6,1.8
    c0.3-0.1,0.7-0.1,1-0.2c-0.2-1-0.6-2.6-1.8-2.3c-1.3,0.2-1,1.8-0.8,2.8C22.9,13.4,23.2,13.3,23.6,13.2z"/>
<path style="fill:#D03D41;" d="M31,19.8c0.6,0,1.8-0.1,1.8,0.6c0,0.1,0,0.2-0.1,0.2c-0.3,0.5-1.4,0-1.8-0.2c-0.1,0.3-0.1,0.7-0.2,1
    c1,0.2,2.6,0.4,2.9-0.8c0.2-1.2-1.3-1.6-2.4-1.8C31.1,19.1,31,19.5,31,19.8z"/>
<path style="fill:#D03D41;" d="M28.5,16.3c0.3,0.2,0.6,0.4,0.9,0.6c0.2-0.5,0.6-1.6,1.2-1.4c0.1,0,0.1,0,0.2,0.1
    c0.5,0.5-0.4,1.3-0.8,1.7c0.3,0.2,0.6,0.4,0.9,0.6c0.6-0.9,1.4-2.2,0.4-2.9C30.1,14.2,29.1,15.5,28.5,16.3z"/>
<path style="fill:#D03D41;" d="M23.6,32.7c-0.1,0-0.2,0-0.2-0.1c-0.5-0.3,0-1.3,0.2-1.8c-0.3-0.1-0.7-0.1-1-0.2
    c-0.2,1-0.4,2.6,0.8,2.8c1.2,0.2,1.6-1.3,1.8-2.3c-0.3-0.1-0.7-0.1-1-0.2C24.2,31.5,24.3,32.6,23.6,32.7z"/>
<path style="fill:#D03D41;" d="M27.7,28.4c-0.2,0.3-0.4,0.6-0.6,0.9c0.5,0.2,1.6,0.6,1.4,1.2c0,0.1,0,0.1-0.1,0.2
    c-0.5,0.5-1.3-0.4-1.7-0.8c-0.2,0.3-0.4,0.6-0.6,0.9c0.9,0.6,2.2,1.4,3,0.4C29.9,30,28.6,29,27.7,28.4z"/>
<path style="fill:#D03D41;" d="M29.9,26.7c0.4,0.4,1.3,1.2,0.8,1.7c-0.1,0.1-0.1,0.1-0.2,0.1c-0.6,0.2-1-0.9-1.2-1.4
    c-0.3,0.2-0.6,0.4-0.9,0.6c0.6,0.9,1.6,2.2,2.6,1.4c1-0.7,0.2-2.1-0.4-2.9C30.5,26.4,30.2,26.5,29.9,26.7z"/>

  </symbol>

  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B3">
  <title>B3</title>
  <path  style="fill:#00376F;" d="M38.5,21.8C38.5,21.8,38.5,21.8,38.5,21.8c0-0.1,0-0.2-0.2-0.3c-1.8-1.1-3.1-2.9-3.9-4.9
  c-0.8-2-1.2-4.2-0.7-6.3c0-0.1,0-0.3-0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1C31.2,10.4,29,10,27,9.2
  c-2-0.9-3.8-2.1-4.9-3.9C22,5.1,21.9,5,21.8,5c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.2c-1.1,1.8-2.9,3.1-4.9,3.9
  c-2,0.8-4.2,1.2-6.3,0.7c-0.1,0-0.3,0-0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0.5,2.1,0.1,4.2-0.7,6.3
  c-0.9,2-2.1,3.8-3.9,4.9C5.1,21.5,5,21.7,5,21.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2,0.2,0.3C7,23.2,8.3,25,9.1,27
  c0.8,2,1.2,4.2,0.7,6.3c0,0.1,0,0.3,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.1c2.1-0.5,4.2-0.1,6.3,0.7
  c2,0.9,3.8,2.1,4.9,3.9c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.2c1.1-1.8,2.9-3.1,4.9-3.9
  c2-0.8,4.2-1.2,6.3-0.7c0.1,0,0.3,0,0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.3c-0.5-2.1-0.1-4.2,0.7-6.3
  c0.9-2,2.1-3.8,3.9-4.9C38.4,22,38.5,21.9,38.5,21.8C38.5,21.8,38.5,21.8,38.5,21.8z M29.5,11.6c0.3-0.5,0.7-0.6,1.2-0.6
  c0,0,0,0,0,0c0.6,0,1.2,0,1.8-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.1,0.6-0.1,1.2-0.1,1.8c0,0,0,0,0,0
  c0,0.5-0.2,1-0.6,1.2c-0.6,0.4-1.5,0.1-2-0.4c0,0,0,0,0,0c0,0,0,0,0,0C29.4,13.1,29.2,12.2,29.5,11.6z M20.5,7.8
  C20.5,7.8,20.5,7.8,20.5,7.8c0.4-0.5,0.9-0.9,1.2-1.4c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
  c0.3,0.5,0.8,0.9,1.2,1.3c0,0,0,0,0,0c0.3,0.4,0.6,0.8,0.4,1.3c-0.2,0.7-1,1.1-1.7,1.1c0,0,0,0,0,0c0,0,0,0,0,0
  c-0.7,0-1.5-0.4-1.7-1.1C19.9,8.6,20.2,8.2,20.5,7.8z M10.9,10.9C10.9,10.9,10.9,10.9,10.9,10.9c0.7,0.1,1.3,0.1,1.9,0.1
  c0,0,0,0,0,0c0.5,0,1,0.2,1.2,0.6c0.4,0.6,0.1,1.5-0.4,2c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.5-1.3,0.8-2,0.4c-0.5-0.3-0.6-0.7-0.6-1.2
  c0,0,0,0,0,0C10.9,12.1,11,11.5,10.9,10.9C10.9,10.9,10.8,10.9,10.9,10.9C10.8,10.9,10.8,10.9,10.9,10.9
  C10.9,10.9,10.9,10.9,10.9,10.9z M7.7,23c-0.5-0.4-0.9-0.8-1.3-1.2c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
  c0.5-0.3,0.9-0.8,1.3-1.2c0,0,0,0,0,0c0.4-0.3,0.8-0.6,1.3-0.4c0.7,0.2,1.1,1,1.1,1.7c0,0,0,0,0,0c0,0,0,0,0,0
  c0,0.7-0.4,1.5-1.1,1.7C8.6,23.6,8.2,23.4,7.7,23C7.8,23,7.7,23,7.7,23z M14,32c-0.3,0.5-0.7,0.6-1.2,0.6c0,0,0,0,0,0
  c-0.6,0-1.2,0-1.8,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0.1-0.6,0.1-1.2,0.1-1.8c0,0,0,0,0,0
  c0-0.5,0.2-1,0.6-1.2c0.6-0.4,1.5-0.1,2,0.4c0,0,0,0,0,0c0,0,0,0,0,0C14.1,30.5,14.4,31.3,14,32z M23,35.8C23,35.8,23,35.8,23,35.8
  c-0.4,0.5-0.9,0.9-1.2,1.4c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.3-0.5-0.8-0.9-1.2-1.3c0,0,0,0,0,0
  c-0.3-0.4-0.6-0.8-0.4-1.3c0.2-0.7,1-1.1,1.7-1.1c0,0,0,0,0,0c0,0,0,0,0,0c0.7,0,1.5,0.4,1.7,1.1C23.6,35,23.3,35.4,23,35.8z
   M32.7,32.7C32.6,32.7,32.6,32.7,32.7,32.7c-0.7-0.1-1.3-0.1-1.9-0.1c0,0,0,0,0,0c-0.5,0-1-0.2-1.2-0.6c-0.4-0.6-0.1-1.5,0.4-2
  c0,0,0,0,0,0c0,0,0,0,0,0c0.5-0.5,1.3-0.8,2-0.4c0.5,0.3,0.6,0.7,0.6,1.2c0,0,0,0,0,0C32.6,31.4,32.6,32,32.7,32.7
  C32.7,32.7,32.7,32.7,32.7,32.7C32.7,32.7,32.7,32.7,32.7,32.7C32.7,32.7,32.7,32.7,32.7,32.7z M33.6,26.7
  c-0.3,0.8-0.6,1.7-0.8,2.6c0,0-0.1-0.1-0.1-0.1c-0.9-1-2.5-0.7-3.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,0.9-1.2,2.5-0.2,3.4
  c0,0,0.1,0.1,0.1,0.1c-0.9,0.2-1.7,0.4-2.6,0.8c-0.8,0.4-1.6,0.8-2.4,1.3c0-0.1,0-0.1,0-0.2c0.1-1.4-1.3-2.3-2.5-2.3c0,0,0,0,0,0
  c0,0,0,0,0,0c-1.2,0-2.6,0.9-2.5,2.3c0,0.1,0,0.1,0,0.2c-0.7-0.5-1.5-0.9-2.4-1.3c-0.8-0.3-1.7-0.6-2.6-0.8c0,0,0.1-0.1,0.1-0.1
  c1-0.9,0.7-2.5-0.2-3.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.9-0.9-2.5-1.2-3.4-0.2c0,0-0.1,0.1-0.1,0.1c-0.2-0.9-0.4-1.7-0.8-2.6
  c-0.4-0.8-0.8-1.6-1.3-2.4c0.1,0,0.1,0,0.2,0c1.4,0.1,2.3-1.3,2.3-2.6c0,0,0,0,0,0c0,0,0,0,0,0c0-1.2-0.9-2.6-2.3-2.6
  c-0.1,0-0.1,0-0.2,0c0.5-0.7,0.9-1.5,1.3-2.4c0.3-0.8,0.6-1.7,0.8-2.6c0,0,0.1,0.1,0.1,0.1c0.9,1,2.5,0.7,3.4-0.2c0,0,0,0,0,0
  c0,0,0,0,0,0c0.9-0.9,1.2-2.5,0.2-3.4c0,0-0.1-0.1-0.1-0.1c0.9-0.2,1.7-0.4,2.6-0.8c0.8-0.4,1.6-0.8,2.4-1.3c0,0.1,0,0.1,0,0.2
  c-0.1,1.4,1.3,2.3,2.5,2.3c0,0,0,0,0,0c0,0,0,0,0,0c1.2,0,2.6-0.9,2.5-2.3c0-0.1,0-0.1,0-0.2c0.7,0.5,1.5,0.9,2.4,1.3
  c0.8,0.3,1.7,0.6,2.6,0.8c0,0-0.1,0.1-0.1,0.1c-1,0.9-0.7,2.5,0.2,3.4c0,0,0,0,0,0c0,0,0,0,0,0c0.9,0.9,2.5,1.2,3.4,0.2
  c0,0,0.1-0.1,0.1-0.1c0.2,0.9,0.4,1.7,0.8,2.6c0.4,0.8,0.8,1.6,1.3,2.4c-0.1,0-0.1,0-0.2,0c-1.4-0.1-2.3,1.3-2.3,2.6c0,0,0,0,0,0
  c0,0,0,0,0,0c0,1.2,0.9,2.6,2.3,2.6c0.1,0,0.1,0,0.2,0C34.4,25.1,34,25.9,33.6,26.7z M37.2,21.8C37.2,21.8,37.2,21.8,37.2,21.8
  c-0.6,0.4-1,0.8-1.4,1.2c0,0,0,0,0,0c-0.4,0.3-0.8,0.6-1.3,0.4c-0.7-0.2-1.1-1-1.1-1.7c0,0,0,0,0,0c0,0,0,0,0,0
  c0-0.7,0.4-1.5,1.1-1.7c0.5-0.1,0.9,0.1,1.3,0.4c0,0,0,0,0,0C36.2,21,36.6,21.4,37.2,21.8C37.2,21.7,37.2,21.8,37.2,21.8
  C37.2,21.8,37.2,21.8,37.2,21.8C37.2,21.8,37.2,21.8,37.2,21.8z"/>

</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B4">
<title>B4</title>
<path style="fill:#00366F;" d="M13.5,18.3c-0.5-1.1-0.9-2.2-1.2-3.4c-0.4-1.1-0.7-2.3-1-3.5c-0.6,0.6-1.1,1.3-1.6,2
			c-0.5,0.8-1,1.7-1.4,2.6c-0.7,1.7-1.1,3.6-1.2,5.5c1-0.6,2.1-1.2,3.1-1.7C11.3,19.3,12.4,18.8,13.5,18.3z"/>
		<path style="fill:#00366F;" d="M18.5,30.5c-1.1,0.5-2.2,0.9-3.4,1.2c-1.2,0.4-2.4,0.7-3.6,1.1c0.7,0.7,1.4,1.3,2.1,1.8
			c0.8,0.5,1.7,1,2.6,1.4c1.8,0.8,3.7,1.2,5.6,1.2c-0.7-1.1-1.3-2.3-1.9-3.4C19.5,32.7,19,31.6,18.5,30.5z"/>
		<path style="fill:#00366F;" d="M37.1,22.3c-1,0.6-2.1,1.2-3.2,1.7c-1.1,0.5-2.2,1-3.3,1.5c0.5,1.1,0.9,2.2,1.2,3.4
			c0.4,1.2,0.7,2.4,1.1,3.6c0.6-0.6,1.1-1.3,1.5-1.9c0.5-0.8,1-1.7,1.4-2.6C36.7,26.1,37.1,24.2,37.1,22.3z"/>
		<path style="fill:#00366F;" d="M13.5,25.5c-1.1-0.5-2.2-1-3.3-1.5c-1.1-0.5-2.1-1.1-3.1-1.7c0,2,0.4,3.9,1.2,5.7
			c0.4,0.9,0.8,1.8,1.4,2.6c0.5,0.7,1,1.3,1.5,1.9c0.3-1.2,0.7-2.4,1.1-3.6C12.6,27.7,13,26.6,13.5,25.5z"/>
		<path style="fill:#00366F;" d="M30.7,18.3c1.1,0.5,2.2,1,3.3,1.5c1.1,0.5,2.1,1.1,3.2,1.7c-0.1-1.9-0.4-3.7-1.2-5.5
			c-0.4-0.9-0.8-1.8-1.4-2.6c-0.5-0.7-1-1.4-1.6-2c-0.3,1.2-0.6,2.3-1,3.5C31.6,16.1,31.2,17.2,30.7,18.3z"/>
		<path style="fill:#00366F;" d="M25.7,30.5c-0.5,1.1-1,2.2-1.5,3.3c-0.6,1.2-1.2,2.3-1.9,3.4c2,0,3.9-0.4,5.6-1.2
			c0.9-0.4,1.7-0.9,2.6-1.4c0.8-0.5,1.5-1.1,2.1-1.8c-1.2-0.3-2.4-0.7-3.6-1.1C27.9,31.4,26.8,31,25.7,30.5z"/>
		<path style="fill:#00366F;" d="M25.7,13.3c1.1-0.5,2.2-0.9,3.4-1.2c1.1-0.4,2.3-0.7,3.5-1c-0.6-0.6-1.3-1.1-2-1.6
			c-0.8-0.5-1.7-1-2.6-1.4c-1.8-0.8-3.6-1.2-5.5-1.2c0.6,1.1,1.2,2.1,1.8,3.2C24.7,11.1,25.2,12.2,25.7,13.3z"/>
		<path style="fill:#00366F;" d="M18.5,13.3c0.5-1.1,1-2.2,1.5-3.3c0.6-1.1,1.1-2.2,1.8-3.2c-1.9,0-3.8,0.4-5.5,1.2
			c-0.9,0.4-1.7,0.9-2.6,1.4c-0.7,0.5-1.4,1-2,1.6c1.2,0.3,2.3,0.6,3.5,1C16.3,12.4,17.4,12.9,18.5,13.3z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B5">
<title>B5</title>

			<path style="fill:#0070B4;" d="M25.1,20.2c-0.1-0.4-0.1-0.8,0-1.3c-0.4,0-0.8,0-1.3,0c-0.3-0.3-0.5-0.7-0.7-1.1
				c-0.3,0.3-0.7,0.5-1.1,0.6c-0.4-0.1-0.8-0.3-1.1-0.6c-0.2,0.4-0.4,0.8-0.7,1.1c-0.4,0.1-0.8,0.1-1.3,0c0,0.4,0,0.8,0,1.3
				c-0.3,0.3-0.7,0.5-1.1,0.7c0.3,0.3,0.5,0.7,0.6,1.1c-0.1,0.4-0.3,0.8-0.6,1.1c0.4,0.2,0.8,0.4,1.1,0.7c0.1,0.4,0.1,0.8,0,1.3
				c0.4,0,0.8,0,1.3,0c0.3,0.3,0.5,0.7,0.7,1.1c0.3-0.3,0.7-0.5,1.1-0.6c0.4,0.1,0.8,0.3,1.1,0.6c0.2-0.4,0.4-0.8,0.7-1.1
				c0.4-0.1,0.8-0.1,1.3,0c0-0.4,0-0.8,0-1.3c0.3-0.3,0.7-0.5,1.1-0.7c-0.3-0.3-0.5-0.7-0.6-1.1c0.1-0.4,0.3-0.8,0.6-1.1
				C25.8,20.7,25.4,20.5,25.1,20.2z M24.3,22.6c-0.4,0-0.7,0.1-1.1,0.1c0.2,0.3,0.3,0.6,0.4,1c-0.3-0.1-0.7-0.3-1-0.4
				c0,0.4-0.1,0.7-0.1,1.1c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2,0.3-0.4,0.6-0.6,0.9c0-0.4-0.1-0.7-0.1-1.1c-0.3,0.2-0.6,0.3-1,0.4
				c0.1-0.3,0.3-0.7,0.4-1c-0.4,0-0.7-0.1-1.1-0.1c0.3-0.2,0.6-0.4,0.9-0.6c-0.3-0.2-0.6-0.4-0.9-0.6c0.4,0,0.7-0.1,1.1-0.1
				c-0.2-0.3-0.3-0.6-0.4-1c0.3,0.1,0.7,0.3,1,0.4c0-0.4,0.1-0.7,0.1-1.1c0.2,0.3,0.4,0.6,0.6,0.9c0.2-0.3,0.4-0.6,0.6-0.9
				c0,0.4,0.1,0.7,0.1,1.1c0.3-0.2,0.6-0.3,1-0.4c-0.1,0.3-0.3,0.7-0.4,1c0.4,0,0.7,0.1,1.1,0.1c-0.3,0.2-0.6,0.4-0.9,0.6
				C23.7,22.2,24,22.4,24.3,22.6z"/>
		
			<path style="fill:#013571;" d="M34.3,17.3c-0.2,0.5-0.3,1-0.5,1.5c0.4,0.3,0.8,0.7,1.2,1.1c0.7,0.6,1.5,0.3,2.4,0.3
				c0.9,0,1.8,0.2,2.5,0.8c0.1-0.6,0.1-1.2,0.2-1.7c-0.4-0.9-2-0.9-2.9-1.1c-0.2,0-0.4-0.1-0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4
				c0.6-0.6,2-1.4,1.9-2.4c-0.3-0.5-0.7-0.9-1-1.4c-0.3,0.8-1,1.5-1.8,1.9C35.5,16.3,34.6,16.4,34.3,17.3z"/>
	
				<path style="fill:#013571;" d="M35.8,9.6c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.4-0.2
					c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.5,0.1,1,0,1.5C34.8,9.5,35.4,9.5,35.8,9.6z"/>
			
				<path style="fill:#013571;" d="M30.3,11.7c0.1,0.5,0.2,1,0.3,1.6c0.5,0.1,1,0.2,1.6,0.3c0.9,0.2,1.5-0.5,2.2-0.9
					c0.8-0.4,1.7-0.7,2.5-0.6c-0.2-0.5-0.5-1.1-0.7-1.6c-0.8-0.6-2.2,0.2-3.1,0.5c-0.2,0.1-0.3,0.1-0.5,0.2c0.1-0.2,0.1-0.3,0.2-0.5
					c0.3-0.8,1-2.2,0.5-3.1c-0.5-0.2-1.1-0.5-1.6-0.7c0.2,0.8-0.2,1.8-0.6,2.5C30.8,10.3,30.1,10.8,30.3,11.7z"/>
			
				<path style="fill:#013571;" d="M25.7,40.2c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.3,0,0.5,0.1c0.1-0.1,0.3-0.2,0.4-0.3
					c0.2-0.1,0.4-0.3,0.5-0.4c-0.5-0.1-0.9-0.4-1.3-0.8C26.3,39.4,26.1,39.9,25.7,40.2z"/>
			
				<path style="fill:#013571;" d="M19.8,35.1c-0.4-0.4-0.7-0.8-1.1-1.2c-0.5,0.2-1,0.3-1.5,0.5c-0.9,0.3-1,1.2-1.5,1.9
					c-0.5,0.7-1.1,1.5-1.9,1.8c0.5,0.3,0.9,0.7,1.4,1c1,0.1,1.8-1.3,2.4-1.9c0.1-0.1,0.2-0.3,0.4-0.4c0,0.2,0.1,0.4,0.1,0.5
					c0.2,0.8,0.2,2.4,1.1,2.9c0.6-0.1,1.1-0.1,1.7-0.2c-0.6-0.6-0.8-1.6-0.8-2.5C20.2,36.6,20.5,35.8,19.8,35.1z"/>
			
				<path style="fill:#013571;" d="M8.1,34.4c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.2
					c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.5-0.1-1,0-1.5C9.1,34.5,8.5,34.5,8.1,34.4z"/>
			
				<path style="fill:#013571;" d="M13.6,32.3c-0.1-0.5-0.2-1-0.3-1.6c-0.5-0.1-1-0.2-1.6-0.3c-0.9-0.2-1.5,0.5-2.2,0.9
					c-0.8,0.4-1.7,0.7-2.5,0.6c0.2,0.5,0.5,1.1,0.7,1.6c0.8,0.6,2.2-0.2,3.1-0.5c0.2-0.1,0.3-0.1,0.5-0.2c-0.1,0.2-0.1,0.3-0.2,0.5
					c-0.3,0.8-1,2.2-0.5,3.1c0.5,0.2,1.1,0.5,1.6,0.7c-0.2-0.8,0.2-1.8,0.6-2.5C13.1,33.7,13.8,33.2,13.6,32.3z"/>
		
				<path style="fill:#013571;" d="M16.1,39.7c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0,0.3,0,0.5-0.1
					c0.2,0,0.4,0,0.7-0.1c-0.4-0.4-0.6-0.8-0.7-1.3C17,39.2,16.6,39.5,16.1,39.7z"/>
			
				<path style="fill:#013571;" d="M28.1,36.2c-0.4-0.7-0.6-1.6-1.5-1.9c-0.5-0.2-1-0.3-1.5-0.5c-0.3,0.4-0.7,0.8-1.1,1.2
					c-0.6,0.7-0.3,1.5-0.3,2.4c0,0.9-0.2,1.8-0.8,2.5c0.6,0.1,1.2,0.1,1.7,0.2c0.9-0.4,0.9-2,1.1-2.9c0-0.2,0.1-0.4,0.1-0.5
					c0.1,0.1,0.2,0.3,0.4,0.4c0.6,0.6,1.4,2,2.4,1.9c0.5-0.3,0.9-0.7,1.4-1C29.2,37.7,28.6,37,28.1,36.2z"/>
		
				<path style="fill:#013571;" d="M40.2,25.8c-0.4,0.4-0.8,0.6-1.3,0.7c0.4,0.4,0.7,0.8,0.8,1.3c0.1-0.2,0.3-0.4,0.4-0.5
					c0.1-0.1,0.2-0.3,0.3-0.4c0-0.2,0-0.3-0.1-0.5C40.2,26.2,40.2,26,40.2,25.8z"/>
		
				<path style="fill:#013571;" d="M39.6,16.2c-0.1,0.5-0.4,0.9-0.8,1.3c0.5,0.2,1,0.4,1.3,0.7c0-0.2,0.1-0.4,0.1-0.7
					c0-0.2,0-0.3,0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4C39.9,16.5,39.8,16.3,39.6,16.2z"/>
			
				<path style="fill:#013571;" d="M34.3,34.4c0.1,0.5,0.2,1,0,1.5c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3-0.1,0.4-0.2
					c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.6C35.4,34.5,34.8,34.5,34.3,34.4z"/>
			
				<path style="fill:#013571;" d="M37,26.3c-0.1-0.1-0.3-0.2-0.4-0.4c0.2,0,0.4-0.1,0.5-0.1c0.8-0.2,2.4-0.2,2.9-1.1
					c-0.1-0.6-0.1-1.1-0.2-1.7c-0.6,0.6-1.6,0.8-2.5,0.8c-0.8,0-1.7-0.3-2.4,0.3c-0.4,0.4-0.8,0.7-1.2,1.1c0.2,0.5,0.3,1,0.5,1.5
					c0.3,0.9,1.2,1,1.9,1.5c0.7,0.5,1.5,1.1,1.8,1.9c0.3-0.5,0.7-0.9,1-1.4C39,27.7,37.7,26.9,37,26.3z"/>
			
				<path style="fill:#013571;" d="M34.4,31.2c-0.7-0.4-1.3-1.1-2.2-0.9c-0.5,0.1-1,0.2-1.6,0.3c-0.1,0.5-0.2,1-0.3,1.6
					c-0.2,0.9,0.5,1.5,0.9,2.2c0.4,0.8,0.7,1.7,0.6,2.5c0.5-0.2,1.1-0.5,1.6-0.7c0.6-0.8-0.2-2.2-0.5-3.1c-0.1-0.2-0.1-0.3-0.2-0.5
					c0.2,0.1,0.3,0.1,0.5,0.2c0.8,0.3,2.2,1,3.1,0.5c0.2-0.5,0.5-1.1,0.7-1.6C36.1,32,35.2,31.7,34.4,31.2z"/>
			
				<path style="fill:#013571;" d="M9.5,12.8c0.7,0.4,1.3,1.1,2.2,0.9c0.5-0.1,1-0.2,1.6-0.3c0.1-0.5,0.2-1,0.3-1.6
					c0.2-0.9-0.5-1.5-0.9-2.2C12.3,8.8,12,7.8,12.1,7c-0.5,0.2-1.1,0.5-1.6,0.7C10,8.5,10.8,9.9,11,10.7c0.1,0.2,0.1,0.3,0.2,0.5
					c-0.2-0.1-0.3-0.1-0.5-0.2c-0.8-0.3-2.2-1-3.1-0.5c-0.2,0.5-0.5,1.1-0.7,1.6C7.8,12,8.7,12.3,9.5,12.8z"/>
			
				<path style="fill:#013571;" d="M9.6,9.6c-0.1-0.5-0.2-1,0-1.5C9.4,8.2,9.1,8.3,8.9,8.4C8.8,8.4,8.7,8.5,8.5,8.6
					C8.5,8.7,8.4,8.9,8.3,9C8.2,9.2,8.1,9.4,8.1,9.6C8.5,9.5,9.1,9.5,9.6,9.6z"/>
			
				<path style="fill:#013571;" d="M18.2,3.8c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.3,0-0.5-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
					c-0.2,0.1-0.4,0.3-0.5,0.4c0.5,0.1,0.9,0.4,1.3,0.8C17.6,4.6,17.8,4.1,18.2,3.8z"/>
		
				<path style="fill:#013571;" d="M27.8,4.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0-0.3,0-0.5,0.1
					c-0.2,0-0.4,0-0.7,0.1c0.4,0.4,0.6,0.8,0.7,1.3C26.9,4.8,27.3,4.5,27.8,4.3z"/>
			
				<path style="fill:#013571;" d="M24,8.9c0.4,0.4,0.7,0.8,1.1,1.2c0.5-0.2,1-0.3,1.5-0.5c0.9-0.3,1-1.2,1.5-1.9
					C28.6,7,29.2,6.3,30,6c-0.5-0.3-0.9-0.7-1.4-1c-1-0.1-1.8,1.3-2.4,1.9C26.1,7,26,7.2,25.9,7.3c0-0.2-0.1-0.4-0.1-0.5
					c-0.2-0.8-0.2-2.4-1.1-2.9C24.1,4,23.5,4,22.9,4.1c0.6,0.6,0.8,1.6,0.8,2.5C23.7,7.4,23.4,8.2,24,8.9z"/>
			
				<path style="fill:#013571;" d="M15.8,7.8c0.4,0.7,0.6,1.6,1.5,1.9c0.5,0.2,1,0.3,1.5,0.5c0.3-0.4,0.7-0.8,1.1-1.2
					c0.6-0.7,0.3-1.5,0.3-2.4c0-0.9,0.2-1.8,0.8-2.5C20.4,4,19.8,4,19.2,3.9c-0.9,0.4-0.9,2-1.1,2.9c0,0.2-0.1,0.4-0.1,0.5
					c-0.1-0.1-0.2-0.3-0.4-0.4c-0.6-0.6-1.4-2-2.4-1.9c-0.5,0.3-0.9,0.7-1.4,1C14.7,6.3,15.3,7,15.8,7.8z"/>
			
				<path style="fill:#013571;" d="M9.6,26.7c0.2-0.5,0.3-1,0.5-1.5c-0.4-0.3-0.8-0.7-1.2-1.1c-0.7-0.6-1.5-0.3-2.4-0.3
					c-0.9,0-1.8-0.2-2.5-0.8c-0.1,0.6-0.1,1.2-0.2,1.7c0.4,0.9,2,0.9,2.9,1.1c0.2,0,0.4,0.1,0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4
					c-0.6,0.6-2,1.4-1.9,2.4c0.3,0.5,0.7,0.9,1,1.4c0.3-0.8,1-1.5,1.8-1.9C8.4,27.7,9.3,27.6,9.6,26.7z"/>
			
				<path style="fill:#013571;" d="M3.7,25.8c0,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.3-0.1,0.5c0.1,0.1,0.2,0.3,0.3,0.4
					c0.1,0.2,0.3,0.4,0.4,0.5c0.1-0.5,0.4-0.9,0.8-1.3C4.5,26.4,4.1,26.1,3.7,25.8z"/>
			
				<path style="fill:#013571;" d="M3.7,18.2c0.4-0.4,0.8-0.6,1.3-0.7c-0.4-0.4-0.7-0.8-0.8-1.3c-0.1,0.2-0.3,0.4-0.4,0.5
					c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.2,0,0.3,0.1,0.5C3.7,17.8,3.7,18,3.7,18.2z"/>
			
				<path style="fill:#013571;" d="M6.9,17.7c0.1,0.1,0.3,0.2,0.4,0.4c-0.2,0-0.4,0.1-0.5,0.1c-0.8,0.2-2.4,0.2-2.9,1.1
					C3.9,19.9,4,20.4,4,21c0.6-0.6,1.6-0.8,2.5-0.8c0.8,0,1.7,0.3,2.4-0.3c0.4-0.4,0.8-0.7,1.2-1.1c-0.2-0.5-0.3-1-0.5-1.5
					c-0.3-0.9-1.2-1-1.9-1.5c-0.7-0.5-1.5-1.1-1.8-1.9c-0.3,0.5-0.7,0.9-1,1.4C4.8,16.3,6.2,17.1,6.9,17.7z"/>
	
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B6">
<title>B6</title>
			<path style="fill:#D04042;" d="M8.8,22.1c-0.2-0.2-0.5-0.5-0.7-0.7c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6l-0.7,0.7
				L5,22.9c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6C8.3,22.6,8.5,22.4,8.8,22.1z"/>
			<path style="fill:#D04042;" d="M14.5,31.5c0-0.6,0.1-1.3,0.2-1.9l0.1-0.3l-0.3,0.1c-0.6,0.2-1.3,0.2-1.9,0.2
				c-1.1,0-2.2-0.2-3.2-0.7l-0.4-0.2l0.2,0.4c0.5,1.3,1.3,2.5,2.3,3.6c1.2,1.2,2.5,1.9,4,2.5l-0.2-0.4
				C14.7,33.7,14.5,32.6,14.5,31.5z"/>
			<path style="fill:#D04042;" d="M12.6,14.6c0.6,0,1.3,0.1,1.9,0.2l0.3,0.1l-0.1-0.3c-0.2-0.6-0.2-1.3-0.2-1.9
				c0-1.4,0.3-2.4,0.9-3.7L15,9.3c-1.3,0.5-2.5,1.3-3.6,2.3c-1,1-1.8,2.2-2.3,3.6l-0.2,0.4l0.4-0.2C10.4,14.9,11.5,14.6,12.6,14.6z"
				/>
			<path style="fill:#D04042;" d="M29.5,12.7c0,0.6-0.1,1.3-0.2,1.9L29.1,15l0.3-0.1c0.6-0.2,1.3-0.2,1.9-0.2c1.1,0,2.2,0.2,3.2,0.7
				l0.4,0.2l-0.2-0.4c-0.5-1.3-1.3-2.5-2.3-3.6c-1-1-2.2-1.8-3.6-2.3l-0.4-0.2l0.2,0.4C29.2,10.5,29.5,11.6,29.5,12.7z"/>
			<path style="fill:#D04042;" d="M21.8,8.8L22,8.9l0.1-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0.4-0.4,0.6-1,0.6-1.5c0-0.6-0.2-1.1-0.6-1.5
				L22,4.4l-0.7,0.7c-0.4,0.4-0.6,1-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5C21.4,8.4,21.6,8.6,21.8,8.8z"/>
			<path style="fill:#D04042;" d="M35.3,22l-0.1,0.1l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6
				l0.7-0.7L39,21.4c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6C35.7,21.6,35.5,21.8,35.3,22z"/>
			<path style="fill:#D04042;" d="M31.4,29.6c-0.6,0-1.3-0.1-1.9-0.2l-0.3-0.1l0.1,0.3c0.2,0.6,0.2,1.3,0.2,1.9
				c0,1.1-0.2,2.2-0.7,3.2l-0.2,0.4L29,35c1.3-0.5,2.5-1.3,3.6-2.3c1.2-1.2,1.9-2.5,2.5-4l-0.4,0.2C33.6,29.4,32.5,29.6,31.4,29.6z"
				/>
			<path style="fill:#D04042;" d="M22,35.3c-0.2,0.2-0.5,0.5-0.7,0.7c-0.4,0.4-0.6,1-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5l0.7,0.7l0.7-0.7
				c0.4-0.4,0.6-1,0.6-1.5c0-0.6-0.2-1.1-0.6-1.5C22.5,35.8,22.2,35.6,22,35.3z"/>
			<path style="fill:#FCB040;" d="M9.2,20.3c0.2,0.2,0.4,0.4,0.6,0.7c0.2-0.2,0.4-0.4,0.6-0.6c2-2,3.3-2.8,6-2.7L16,17.3
				c-1-0.7-2.2-1.1-3.4-1.1c-1.6,0-3.1,0.6-4.2,1.7l-1.2,1.2l0.3,0.1C8.1,19.5,8.7,19.8,9.2,20.3z"/>
			<path style="fill:#FCB040;" d="M17.5,16.6l0-0.6c-0.1-2.2,1.2-3.9,2.7-5.4c0.2-0.2,0.4-0.4,0.6-0.6l-0.1-0.1
				c-0.2-0.2-0.4-0.3-0.5-0.5c-0.5-0.5-0.8-1.1-1-1.7L19,7.3l-1.2,1.2c-1.1,1.1-1.7,2.6-1.7,4.2c0,1.2,0.4,2.4,1.1,3.4L17.5,16.6z"
				/>
			<path style="fill:#FCB040;" d="M28.1,17.6c2.2-0.1,3.9,1.2,5.4,2.7c0.2,0.2,0.4,0.4,0.6,0.6l0.1-0.1c0.2-0.2,0.3-0.4,0.5-0.5
				c0.5-0.5,1.1-0.8,1.7-1l0.3-0.1L35.5,18c-1.1-1.1-2.6-1.7-4.2-1.7c-1.2,0-2.4,0.4-3.4,1.1l-0.5,0.4L28.1,17.6z"/>
			<path style="fill:#FCB040;" d="M23.3,10.1c0.2,0.2,0.3,0.3,0.5,0.5c1.5,1.5,2.8,3.2,2.7,5.4l0,0.6l0.4-0.5c0.7-1,1.1-2.2,1.1-3.4
				c0-1.6-0.6-3.1-1.7-4.2l-1.2-1.2l-0.1,0.3c-0.2,0.7-0.5,1.2-1,1.7c-0.2,0.2-0.4,0.4-0.7,0.6L23.3,10.1z"/>
			<path style="fill:#FCB040;" d="M34.7,24c-0.2-0.2-0.3-0.4-0.5-0.5l-0.1-0.1L34,23.5c-0.2,0.2-0.3,0.3-0.5,0.5
				c-1.5,1.5-3.2,2.8-5.4,2.7l-0.6,0L28,27c1,0.7,2.2,1.1,3.4,1.1c1.6,0,3.1-0.6,4.2-1.7l1.2-1.2L36.5,25
				C35.8,24.8,35.2,24.5,34.7,24z"/>
			<path style="fill:#FCB040;" d="M20.8,34.3l-0.1-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-1.5-1.5-2.8-3.2-2.7-5.4l0-0.6l-0.4,0.5
				c-0.7,1-1.1,2.2-1.1,3.4c0,1.6,0.6,3.1,1.7,4.2l1.2,1.2l0.1-0.3c0.2-0.7,0.5-1.2,1-1.7c0.2-0.2,0.4-0.3,0.5-0.5L20.8,34.3z"/>
			<path style="fill:#FCB040;" d="M26.4,27.6l0,0.6c0.1,2.2-1.2,3.9-2.7,5.4c-0.2,0.2-0.4,0.4-0.6,0.6l0.1,0.1
				c0.2,0.2,0.4,0.3,0.5,0.5c0.5,0.5,0.8,1.1,1,1.7l0.1,0.3l1.2-1.2c1.1-1.1,1.7-2.6,1.7-4.2c0-1.2-0.4-2.4-1.1-3.4L26.4,27.6z"/>
			<path style="fill:#FCB040;" d="M15.9,26.6c-2.2,0.1-3.9-1.2-5.4-2.7c-0.2-0.2-0.3-0.3-0.5-0.5l-0.1-0.1l-0.1,0.1
				c-0.2,0.2-0.3,0.4-0.5,0.5c-0.5,0.5-1.1,0.8-1.7,1l-0.3,0.1l1.2,1.2C9.5,27.4,11,28,12.6,28c1.2,0,2.4-0.4,3.4-1.1l0.5-0.4
				L15.9,26.6z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B7">
<title>B7</title>

<path style="fill:#3F94B3;" d="M28.6,11.5c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,1.2-2.3,2.3-3.5,3.5c-0.3,0.3-0.2,0.4,0,0.6
    c1.1,1.1,2.3,2.3,3.4,3.4c0.3,0.3,0.4,0.2,0.6,0c1.1-1.1,2.2-2.3,3.4-3.4c0.3-0.3,0.3-0.4,0-0.7c-1.1-1.1-2.3-2.2-3.4-3.4
    C28.8,11.7,28.7,11.6,28.6,11.5z"/>

<path style="fill:#3F94B3;" d="M35.1,7.5c-1.5,0.1-2.6,0.5-3.7,1.2c-0.3,0.2-0.4,0.3-0.1,0.6c1.1,1.1,2.3,2.2,3.4,3.4
    c0.3,0.3,0.5,0.2,0.7-0.1c0.7-1,1.1-2.1,1.2-3.4C36.6,8,36.1,7.4,35.1,7.5z"/>

<path style="fill:#3F94B3;" d="M15.1,11.7c-1.1,1.2-2.3,2.3-3.5,3.5c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2
    c1.2,1.2,2.4,2.4,3.6,3.6c0.2,0.2,0.3,0.1,0.5-0.1c1.2-1.2,2.3-2.3,3.5-3.5c0.2-0.2,0.3-0.3,0-0.6c-1.2-1.1-2.3-2.3-3.5-3.5
    C15.5,11.5,15.4,11.5,15.1,11.7z"/>

<path style="fill:#3F94B3;" d="M18.4,8.7C18,8.9,18,9,18.3,9.3c1.1,1.1,2.3,2.2,3.4,3.4c0.2,0.3,0.4,0.3,0.6,0
    c1.1-1.1,2.2-2.3,3.4-3.4C26,9,26,8.9,25.6,8.7c-1.1-0.7-2.3-1.2-3.7-1.2C20.6,7.5,19.5,7.9,18.4,8.7z"/>

<path style="fill:#3F94B3;" d="M8.6,12.6c0.2,0.3,0.3,0.4,0.6,0.1c1.1-1.1,2.2-2.3,3.4-3.4c0.3-0.3,0.2-0.5-0.1-0.7
    c-1-0.7-2.1-1.1-3.4-1.2C7.9,7.4,7.4,8,7.4,8.9C7.5,10.4,7.9,11.6,8.6,12.6z"/>

<path style="fill:#3F94B3;" d="M8.6,18.4c-0.7,1.1-1.2,2.3-1.2,3.7c0,1.3,0.5,2.5,1.2,3.6c0.2,0.3,0.3,0.4,0.7,0.1
    c1.1-1.1,2.2-2.3,3.4-3.4c0.2-0.2,0.3-0.4,0-0.6c-1.2-1.1-2.3-2.3-3.4-3.4C8.9,18,8.8,18.1,8.6,18.4z"/>

<path style="fill:#3F94B3;" d="M35.4,25.7c0.7-1.1,1.2-2.3,1.2-3.7c0-1.3-0.5-2.5-1.2-3.6c-0.2-0.3-0.3-0.4-0.7-0.1
    c-1.1,1.1-2.2,2.3-3.4,3.4c-0.3,0.3-0.2,0.4,0,0.7c1.1,1.1,2.3,2.2,3.4,3.4C35.1,26.1,35.2,26,35.4,25.7z"/>

<path style="fill:#3F94B3;" d="M28.9,32.3c1.1-1.1,2.2-2.2,3.3-3.3c0.3-0.3,0.3-0.4,0-0.8c-1.1-1.1-2.2-2.2-3.3-3.3
    c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-1.2,1.2-2.3,2.3-3.5,3.5c-0.3,0.3-0.2,0.4,0,0.6c1.1,1.1,2.3,2.2,3.4,3.4
    C28.5,32.6,28.6,32.6,28.9,32.3z"/>

<path style="fill:#3F94B3;" d="M36.6,35.2c-0.1-1.5-0.5-2.7-1.2-3.7c-0.2-0.2-0.3-0.4-0.6-0.1c-1.1,1.2-2.3,2.3-3.4,3.4
    c-0.3,0.3-0.2,0.4,0,0.6c1.1,0.7,2.2,1.2,3.5,1.2C36.1,36.7,36.6,36.1,36.6,35.2z"/>

<path style="fill:#3F94B3;" d="M15.4,32.6c0.2,0,0.3-0.1,0.4-0.2c1.1-1.1,2.2-2.3,3.4-3.4c0.3-0.3,0.3-0.4,0-0.7
    c-1.1-1.1-2.3-2.2-3.4-3.4c-0.3-0.3-0.4-0.3-0.7,0c-1.1,1.1-2.2,2.2-3.3,3.3c-0.3,0.3-0.3,0.4,0,0.8c1.1,1.1,2.2,2.2,3.3,3.3
    C15.2,32.4,15.3,32.5,15.4,32.6z"/>

<path style="fill:#3F94B3;" d="M9.1,36.6c1.2-0.1,2.4-0.5,3.4-1.2c0.3-0.2,0.4-0.3,0.1-0.7c-1.1-1.1-2.3-2.2-3.4-3.4
    c-0.3-0.3-0.4-0.2-0.6,0.1c-0.7,1-1.2,2.2-1.2,3.7C7.4,36.1,7.9,36.7,9.1,36.6z"/>

<path style="fill:#3F94B3;" d="M25.6,35.5c0.3-0.2,0.4-0.3,0.1-0.7c-1.1-1.1-2.3-2.2-3.4-3.4c-0.2-0.2-0.4-0.3-0.6,0
    c-1.1,1.2-2.3,2.3-3.4,3.4c-0.3,0.3-0.2,0.4,0.1,0.6c1.1,0.7,2.3,1.2,3.7,1.2C23.3,36.6,24.5,36.2,25.6,35.5z"/>

</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B8">
<title>B8</title>

					<path style="fill:#5692B0;" d="M8.9,21.9c-0.2-0.2-0.5-0.5-0.7-0.7c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6l-0.7,0.7
						l0.7,0.7c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6C8.4,22.4,8.7,22.1,8.9,21.9z"/>
				
					<path style="fill:#5692B0;" d="M14.6,31.2c0-0.6,0.1-1.3,0.2-1.9l0.1-0.3l-0.3,0.1c-0.6,0.2-1.3,0.2-1.9,0.2
						c-1.1,0-2.2-0.2-3.2-0.7L9,28.4l0.2,0.4c0.5,1.3,1.3,2.5,2.3,3.5c1.2,1.2,2.5,1.9,4,2.5l-0.2-0.4
						C14.8,33.4,14.6,32.3,14.6,31.2z"/>
				
					<path style="fill:#5692B0;" d="M12.7,14.4c0.6,0,1.3,0.1,1.9,0.2l0.3,0.1l-0.1-0.3c-0.2-0.6-0.2-1.3-0.2-1.9
						c0-1.4,0.3-2.4,0.9-3.6L15,9.1c-1.3,0.5-2.5,1.3-3.5,2.3c-1,1-1.8,2.2-2.3,3.5L9,15.4l0.4-0.2C10.5,14.7,11.6,14.4,12.7,14.4z"
						/>
			
					<path style="fill:#5692B0;" d="M29.4,12.6c0,0.6-0.1,1.3-0.2,1.9l-0.1,0.3l0.3-0.1c0.6-0.2,1.3-0.2,1.9-0.2
						c1.1,0,2.2,0.2,3.2,0.7l0.4,0.2l-0.2-0.4c-0.5-1.3-1.3-2.5-2.3-3.5c-1-1-2.2-1.8-3.5-2.3l-0.4-0.2l0.2,0.4
						C29.2,10.3,29.4,11.5,29.4,12.6z"/>
				
					<path style="fill:#5692B0;" d="M21.9,8.7L22,8.8l0.1-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0.4-0.4,0.6-1,0.6-1.5c0-0.6-0.2-1.1-0.6-1.5
						L22,4.3L21.3,5c-0.4,0.4-0.6,1-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5C21.5,8.3,21.7,8.5,21.9,8.7z"/>
				
					<path style="fill:#5692B0;" d="M35.2,21.8l-0.1,0.1l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,1,0.6,1.5,0.6
						c0.6,0,1.1-0.2,1.5-0.6l0.7-0.7l-0.7-0.7c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6C35.6,21.4,35.4,21.6,35.2,21.8z"/>
				
					<path style="fill:#5692B0;" d="M31.3,29.3c-0.6,0-1.3-0.1-1.9-0.2L29.1,29l0.1,0.3c0.2,0.6,0.2,1.3,0.2,1.9
						c0,1.1-0.2,2.2-0.7,3.2l-0.2,0.4l0.4-0.2c1.3-0.5,2.5-1.3,3.5-2.3c1.2-1.2,1.9-2.5,2.5-4l-0.4,0.2
						C33.5,29.1,32.4,29.3,31.3,29.3z"/>
				
					<path style="fill:#5692B0;" d="M22,35c-0.2,0.2-0.5,0.5-0.7,0.7c-0.4,0.4-0.6,1-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5l0.7,0.7l0.7-0.7
						c0.4-0.4,0.6-1,0.6-1.5c0-0.6-0.2-1.1-0.6-1.5C22.5,35.5,22.2,35.2,22,35z"/>
			
					<path style="fill:#5692B0;" d="M9.3,20c0.2,0.2,0.4,0.4,0.6,0.7c0.2-0.2,0.4-0.4,0.6-0.6c1.9-1.9,3.3-2.8,6-2.6L16,17.1
						c-1-0.7-2.2-1.1-3.4-1.1c-1.6,0-3,0.6-4.1,1.7L7.3,19l0.3,0.1C8.3,19.2,8.8,19.6,9.3,20z"/>
				
					<path style="fill:#5692B0;" d="M17.6,16.4l0-0.6c-0.1-2.2,1.2-3.9,2.7-5.4c0.2-0.2,0.4-0.4,0.6-0.6l-0.1-0.1
						c-0.2-0.2-0.4-0.3-0.5-0.5c-0.5-0.5-0.8-1.1-1-1.7l-0.1-0.3l-1.2,1.2c-1.1,1.1-1.7,2.6-1.7,4.1c0,1.2,0.4,2.4,1.1,3.4
						L17.6,16.4z"/>
				
					<path style="fill:#5692B0;" d="M28.1,17.4c2.2-0.1,3.9,1.2,5.4,2.7c0.2,0.2,0.4,0.4,0.6,0.6l0.1-0.1c0.2-0.2,0.3-0.4,0.5-0.5
						c0.5-0.5,1.1-0.8,1.7-1l0.3-0.1l-1.2-1.2c-1.1-1.1-2.6-1.7-4.1-1.7c-1.2,0-2.4,0.4-3.4,1.1l-0.5,0.4L28.1,17.4z"/>
			
					<path style="fill:#5692B0;" d="M23.3,10c0.2,0.2,0.3,0.3,0.5,0.5c1.5,1.5,2.8,3.2,2.7,5.4l0,0.6l0.4-0.5c0.7-1,1.1-2.2,1.1-3.4
						c0-1.6-0.6-3-1.7-4.1l-1.2-1.2l-0.1,0.3c-0.2,0.7-0.5,1.2-1,1.7c-0.2,0.2-0.4,0.4-0.7,0.6L23.3,10z"/>
				
					<path style="fill:#5692B0;" d="M34.7,23.7c-0.2-0.2-0.3-0.4-0.5-0.5L34,23.1l-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.5
						c-1.5,1.5-3.2,2.8-5.4,2.7l-0.6,0l0.5,0.4c1,0.7,2.2,1.1,3.4,1.1c1.6,0,3-0.6,4.1-1.7l1.2-1.2l-0.3-0.1
						C35.7,24.6,35.2,24.2,34.7,23.7z"/>
			
					<path style="fill:#5692B0;" d="M20.8,33.9l-0.1-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-1.5-1.5-2.8-3.2-2.7-5.4l0-0.6l-0.4,0.5
						c-0.7,1-1.1,2.2-1.1,3.4c0,1.6,0.6,3,1.7,4.1l1.2,1.2l0.1-0.3c0.2-0.7,0.5-1.2,1-1.7c0.2-0.2,0.4-0.3,0.5-0.5L20.8,33.9z"/>
			
				<path style="fill:#5692B0;" d="M26.4,27.3l0,0.6c0.1,2.2-1.2,3.9-2.7,5.4c-0.2,0.2-0.4,0.4-0.6,0.6l0.1,0.1
					c0.2,0.2,0.4,0.3,0.5,0.5c0.5,0.5,0.8,1.1,1,1.7l0.1,0.3l1.2-1.2c1.1-1.1,1.7-2.6,1.7-4.1c0-1.2-0.4-2.4-1.1-3.4L26.4,27.3z"/>
				
					<path style="fill:#5692B0;" d="M15.9,26.4c-2.2,0.1-3.9-1.2-5.4-2.7c-0.2-0.2-0.3-0.3-0.5-0.5L10,23.1l-0.1,0.1
						c-0.2,0.2-0.3,0.4-0.5,0.5c-0.5,0.5-1.1,0.8-1.7,1l-0.3,0.1L8.5,26c1.1,1.1,2.6,1.7,4.1,1.7c1.2,0,2.4-0.4,3.4-1.1l0.5-0.4
						L15.9,26.4z"/>

</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B9">
<title>B9</title>
<path id="b" style="fill:#FFFFFF;" d="M24.7,37.3c0-1-0.2-1.8-0.6-2.5c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.4
		c0.3-0.3,0.5-0.6,0.5-0.9c0-0.3,0-0.4-0.1-0.6c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.4,0.1,0.8,0,1c-0.1,0.2-0.4,0.3-0.7,0.4c-0.2,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.5c0.3,0.1,0.5,0.1,0.6-0.1c0.2-0.2,0.3-0.4,0.3-0.7
		c0.1-0.3,0.1-0.5,0.2-0.8c0-0.3,0-0.5,0-0.5c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.4,0.3-0.6
		c0.1-0.3,0-0.6,0-1c0.8,0.7,1.5,1.3,2.3,1.9c0.8,0.6,1.7,1,2.8,1.4c0.1,0.4,0.2,0.9,0.2,1.4c0,0.5-0.1,1-0.3,1.5
		c-0.3-0.2-0.7-0.3-1.2-0.4c-0.5-0.1-0.8,0-1.1,0.2c-0.3,0.2-0.4,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.3,0,0.5,0.2,0.7
		c0.2,0.2,0.5,0.3,0.9,0.4c0,0.2,0,0.4-0.1,0.7c-0.1,0.2-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3
		c0.1-0.1,0.1-0.2,0-0.3C27.4,37,27.2,37,27,37c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.4,0.3-0.5,0.6c-0.2,0.2-0.2,0.5-0.2,0.7
		c0,0.7,0.2,1.2,0.7,1.6c0.5,0.4,1,0.6,1.6,0.6c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,0.9-0.5,1.3-0.9c0.4-0.4,0.6-0.7,0.9-1.2
		c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.9,0.3-1.8,0.3-2.6c0-0.8,0-1.6-0.2-2.4c1.5,0.3,3.2,0.3,4.9-0.2c0.4-0.1,0.8-0.2,1.2-0.4
		c0.4-0.2,0.8-0.5,1.2-0.9c0.3-0.4,0.6-0.8,0.8-1.3c0.2-0.5,0.3-1.1,0.3-1.7c0-0.6-0.2-1.1-0.6-1.6c-0.4-0.5-1-0.7-1.6-0.8
		c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.2,0.1,0.3,0
		c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.5,0.1,0.8,0.4c0.3,0.3,0.4,0.6,0.4,1
		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1-0.4-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.5-0.3-0.8-0.2c-0.8,0.1-1.3,0.3-1.7,0.7c-0.3,0.3-0.3,0.6-0.2,1.1c0.1,0.5,0.2,0.8,0.4,1.1c-0.5,0.2-1,0.3-1.5,0.3
		c-0.5,0-1-0.1-1.4-0.2c-0.4-1-0.8-1.9-1.4-2.7c-0.6-0.8-1.2-1.6-1.9-2.3c0.4,0.1,0.7,0.1,1,0c0.3-0.1,0.5-0.1,0.6-0.3
		c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6c0.1,0,0.3,0,0.5,0c0.3,0,0.5-0.1,0.8-0.2c0.3-0.1,0.5-0.2,0.7-0.4
		c0.2-0.2,0.2-0.4,0.1-0.6c0.3-0.2,0.6-0.2,0.9,0c0.3,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.1-0.5,0.1-1,0
		c-0.1,0-0.1,0-0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.4,0.2,0.5,0.2
		c0.1,0.1,0.3,0.1,0.6,0.1c0.3,0,0.6-0.2,0.9-0.5c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.7-0.3-1c0.7,0.4,1.6,0.6,2.6,0.6
		c2,0,3.7-1,4-2.2v-0.7c-0.3-1.3-1.9-2.2-4-2.2c-1,0-1.9,0.2-2.6,0.6c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.8-0.4-1.1
		c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.4,0-0.6,0.1c-0.1,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.4
		c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0.4-0.1,0.7-0.1,1,0c0.2,0.1,0.3,0.4,0.3,0.7c0,0.3-0.1,0.6-0.4,0.8
		c-0.3,0.2-0.6,0.2-0.9,0c0.1-0.3,0.1-0.5-0.1-0.6c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3,0-0.5,0-0.5,0
		c0.2-0.2,0.3-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3,0-0.6,0-1,0c0.7-0.7,1.4-1.5,1.9-2.3
		c0.6-0.8,1-1.7,1.4-2.8c0.4-0.1,0.8-0.2,1.4-0.2c0.5,0,1,0.1,1.5,0.3c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,0.8,0.2,1.1
		c0.4,0.4,0.9,0.7,1.7,0.7c0.3,0,0.5-0.1,0.8-0.3c0.2-0.2,0.4-0.5,0.4-0.8c0.2,0,0.4,0,0.7,0.1c0.2,0.1,0.5,0.2,0.7,0.3
		c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-0.8,0.4c-0.2,0-0.3-0.1-0.4-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2,0-0.3,0c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6
		c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.5,0.2,0.8,0.2c0.7,0,1.2-0.2,1.6-0.7c0.4-0.5,0.6-1,0.6-1.6c0-0.6-0.1-1.2-0.3-1.7
		c-0.2-0.5-0.5-0.9-0.8-1.3c-0.3-0.4-0.7-0.6-1.2-0.9c-0.4-0.2-0.8-0.4-1.2-0.5c-1.8-0.4-3.4-0.4-5-0.2c0.3-1.5,0.3-3.2-0.1-4.9
		c-0.1-0.4-0.2-0.8-0.5-1.2c-0.2-0.4-0.5-0.8-0.9-1.2c-0.4-0.4-0.8-0.6-1.2-0.9C29.2,3.2,28.7,3,28,3c-0.6,0-1.2,0.2-1.6,0.6
		c-0.5,0.4-0.7,1-0.7,1.6c0,0.3,0.1,0.5,0.2,0.8c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.2,0.6,0.3c0.2,0,0.4,0,0.4-0.2
		c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.1-0.2-0.2-0.3C27.2,6,27.1,5.9,27,5.7c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.1-0.5,0.4-0.8
		c0.3-0.3,0.6-0.4,1-0.4c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7
		c-0.4,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.8,0.3,1.4,0.7,1.7c0.3,0.2,0.6,0.3,1.1,0.2c0.5-0.1,0.8-0.2,1.2-0.4
		c0.2,0.5,0.3,0.9,0.3,1.5c0,0.5-0.1,1-0.2,1.4c-1.1,0.4-2,0.8-2.8,1.4c-0.8,0.6-1.5,1.2-2.3,1.9c0.1-0.4,0.1-0.7,0-1
		c-0.1-0.3-0.1-0.5-0.3-0.6c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.3c0-0.1,0-0.3,0-0.5c0-0.3-0.1-0.5-0.2-0.8
		c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.2-0.6-0.1c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.1,0.6,0.4c0.1,0.2,0.1,0.5,0,1c0,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
		c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0.1-0.6c0-0.4-0.2-0.7-0.5-0.9C25.8,9,25.4,8.9,25,8.9
		c-0.3,0-0.6,0.1-0.9,0.2c0.3-0.7,0.5-1.5,0.5-2.4c0-2.2-1.2-4-2.6-4s-2.6,1.8-2.6,4c0,0.9,0.2,1.8,0.5,2.5c-0.3-0.2-0.6-0.2-1-0.2
		c-0.5,0-0.8,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,0.9c0,0.3,0,0.4,0.1,0.6c0.1,0.1,0.1,0.3,0.2,0.5c0,0.1,0.1,0.1,0.2,0.1
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.4-0.1-0.8,0-1c0.1-0.2,0.4-0.3,0.7-0.4c0.3,0,0.5,0.1,0.7,0.4
		c0.2,0.3,0.2,0.6,0,0.9c-0.3-0.1-0.5-0.1-0.6,0.1c-0.2,0.2-0.3,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.3,0,0.5,0,0.5
		c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3,0,0.6,0,1c-0.8-0.7-1.5-1.3-2.3-1.9
		c-0.8-0.6-1.7-1-2.8-1.4c-0.1-0.4-0.2-0.9-0.2-1.4c0-0.5,0.1-1,0.3-1.5c0.3,0.2,0.7,0.3,1.2,0.4c0.5,0.1,0.8,0,1.1-0.2
		c0.3-0.2,0.4-0.4,0.5-0.7c0.1-0.3,0.2-0.6,0.2-1c0.1-0.3,0-0.5-0.2-0.7c-0.2-0.2-0.5-0.3-0.9-0.4c0-0.2,0-0.4,0.1-0.7
		c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,0.8
		c0,0.2-0.1,0.3-0.2,0.4C17,5.9,16.9,6,16.8,6.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.2,0.3,0.2,0.5,0.2
		c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.4-0.3,0.5-0.6c0.2-0.2,0.2-0.5,0.2-0.8c0-0.7-0.2-1.2-0.7-1.6C17.2,3.3,16.6,3,16,3
		c-0.6,0-1.2,0.1-1.7,0.3c-0.5,0.2-0.9,0.5-1.3,0.9c-0.4,0.4-0.6,0.7-0.9,1.2c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.9-0.3,1.7-0.3,2.6
		c0,0.8,0,1.6,0.2,2.4c-1.5-0.3-3.2-0.3-4.9,0.2c-0.4,0.1-0.8,0.2-1.2,0.4C5,12.4,4.6,12.7,4.3,13c-0.3,0.4-0.6,0.8-0.8,1.3
		c-0.2,0.5-0.3,1.1-0.3,1.7c0,0.6,0.2,1.1,0.6,1.6c0.4,0.5,1,0.7,1.6,0.8c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.4-0.3,0.6-0.5
		C6.9,17.4,6.9,17.2,7,17c0-0.2,0-0.4-0.2-0.5c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0.4,0.2,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.8,0.2c0.4,0,0.7-0.1,1-0.2
		c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.3,0.3-0.6,0.2-1.1c-0.1-0.5-0.2-0.8-0.4-1.1c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.2
		c0.4,1,0.8,1.9,1.4,2.7c0.6,0.8,1.2,1.6,1.9,2.3c-0.4-0.1-0.7-0.1-1,0c-0.3,0.1-0.5,0.1-0.6,0.3c-0.2,0.1-0.2,0.3-0.2,0.5
		c0,0.2,0.1,0.4,0.2,0.6c-0.1,0-0.3,0-0.5,0c-0.3,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.2,0.4-0.1,0.6
		c-0.3,0.2-0.6,0.2-0.9,0c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.5,0.3-0.7s0.5-0.1,1,0c0.1,0,0.1,0,0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
		c0-0.1,0-0.2,0-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.2-0.9,0.5
		C9.1,18.2,9,18.6,9,19c0,0.3,0.1,0.7,0.3,1c-0.7-0.4-1.6-0.6-2.6-0.6c-2.2,0-4,1.2-4,2.6c0,1.4,1.8,2.6,4,2.6c1,0,1.9-0.2,2.6-0.6
		c-0.2,0.3-0.3,0.7-0.3,1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,0.9,0.5c0.3,0,0.5,0,0.6-0.1c0.2-0.1,0.3-0.1,0.5-0.2
		c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.4,0.1-0.8,0.1-1,0c-0.2-0.1-0.3-0.4-0.3-0.7
		c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.6-0.2,0.9,0c-0.1,0.3-0.1,0.5,0.1,0.6c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.1,0.8,0.2
		c0.3,0,0.5,0,0.5,0c-0.2,0.2-0.3,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0,1,0
		c-0.7,0.7-1.4,1.5-1.9,2.3c-0.6,0.8-1,1.7-1.4,2.8c-0.4,0.1-0.8,0.2-1.4,0.2c-0.5,0-1-0.1-1.5-0.3c0.2-0.3,0.3-0.7,0.4-1.2
		c0.1-0.5,0-0.8-0.2-1.1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.3,0-0.5,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.4,0.8
		c-0.2,0-0.4,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.1-0.7,0.4-1
		c0.3-0.3,0.5-0.4,0.8-0.4c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0,0.3,0
		C6.9,27.3,7,27.2,7,26.9c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.2-0.5-0.2-0.7-0.2c-0.7,0-1.2,0.2-1.6,0.7
		c-0.4,0.5-0.6,1-0.6,1.6c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,0.9,0.8,1.3c0.3,0.4,0.7,0.6,1.2,0.9c0.4,0.2,0.8,0.4,1.2,0.5
		c1.8,0.4,3.4,0.4,5,0.2c-0.3,1.5-0.3,3.2,0.1,4.9c0.1,0.4,0.2,0.8,0.5,1.2c0.2,0.4,0.5,0.8,0.9,1.2c0.4,0.4,0.8,0.6,1.2,0.9
		c0.5,0.2,1,0.3,1.7,0.3c0.6,0,1.2-0.2,1.7-0.6c0.5-0.4,0.8-1,0.8-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.3-0.4-0.5-0.6
		C17.5,37.1,17.3,37,17,37c-0.2,0-0.4,0-0.4,0.2c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.1,0.5-0.4,0.8c-0.3,0.3-0.6,0.4-1,0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.4-0.5
		c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0.4-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.7c-0.1-0.8-0.3-1.4-0.7-1.7
		c-0.3-0.2-0.6-0.3-1.1-0.2c-0.5,0.1-0.8,0.2-1.2,0.4c-0.2-0.5-0.3-0.9-0.3-1.5c0-0.5,0.1-1,0.2-1.4c1.1-0.4,2-0.8,2.8-1.4
		c0.8-0.6,1.6-1.2,2.3-1.9c-0.1,0.4-0.1,0.7,0,1c0,0.3,0.1,0.5,0.3,0.6c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3
		c0,0.1,0,0.3,0,0.5c0,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.2,0.6,0.1c0.2,0.3,0.2,0.6,0,0.9
		c-0.2,0.3-0.4,0.5-0.8,0.4c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.2-0.1-0.5,0-1c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.6c0,0.4,0.2,0.7,0.5,0.9
		c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.7-0.1,1.1-0.3c-0.4,0.7-0.6,1.6-0.6,2.6c0,2.2,1.1,3.9,2.5,4h0.2C23.6,41.2,24.7,39.5,24.7,37.3z
		"/>

</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B10">
  <title>B10</title>
  <path style="fill:#C04947;" d="M14.6,33.7c0.3,0.5,0.6,0.7,0.9,0.9c0.9,0.6,2.1,0.7,3.4,0.3c-0.1,0.4-0.1,0.7-0.1,1.1
  c0,3.1,2.5,5.2,3.2,5.8c0.7-0.6,3.2-2.7,3.2-5.8c0-0.4,0-0.8-0.1-1.1c1.3,0.4,2.5,0.3,3.4-0.3c0.3-0.2,0.6-0.5,0.9-0.9
  c-1-0.4-2.5-1.3-4-2.8c-1.2-1.2-1.9-2.3-2.4-3.3c-0.2-0.4-0.6-0.7-1-0.7c-0.5,0-0.9,0.3-1,0.7c-0.5,0.9-1.2,2.1-2.4,3.3
  C17.1,32.4,15.6,33.3,14.6,33.7z"/>
<path style="fill:#C04947;" d="M2.2,22c0.6,0.7,2.7,3.2,5.8,3.2c0.4,0,0.8,0,1.1-0.1c-0.4,1.3-0.3,2.5,0.3,3.4
  c0.2,0.3,0.5,0.6,0.9,0.9c0.4-1,1.3-2.5,2.8-4c1.2-1.2,2.3-1.9,3.3-2.4c0.4-0.2,0.7-0.6,0.7-1c0-0.5-0.3-0.9-0.7-1
  c-0.9-0.5-2.1-1.2-3.3-2.4c-1.5-1.5-2.3-3-2.8-4c-0.5,0.3-0.7,0.6-0.9,0.9c-0.6,0.9-0.7,2.1-0.3,3.4c-0.4-0.1-0.7-0.1-1.1-0.1
  C4.8,18.8,2.8,21.3,2.2,22z"/>
<path style="fill:#C04947;" d="M18.8,8c0,0.4,0,0.8,0.1,1.1c-1.3-0.4-2.5-0.3-3.4,0.3c-0.3,0.2-0.6,0.5-0.9,0.9
  c1,0.4,2.5,1.3,4,2.8c1.2,1.2,1.9,2.3,2.4,3.3c0.2,0.4,0.6,0.7,1,0.7s0.9-0.3,1-0.7c0.5-0.9,1.2-2.1,2.4-3.3c1.5-1.5,3-2.3,4-2.8
  c-0.3-0.5-0.6-0.7-0.9-0.9c-0.9-0.6-2.1-0.7-3.4-0.3c0.1-0.4,0.1-0.7,0.1-1.1c0-3.1-2.5-5.2-3.2-5.8h0C21.3,2.8,18.8,4.8,18.8,8z
  "/>
<path style="fill:#C04947;" d="M36,18.8c-0.4,0-0.8,0-1.1,0.1c0.4-1.3,0.3-2.5-0.3-3.4c-0.2-0.3-0.5-0.6-0.9-0.9
  c-0.4,1-1.3,2.5-2.8,4c-1.2,1.2-2.3,1.9-3.3,2.4c-0.4,0.2-0.7,0.6-0.7,1c0,0.5,0.3,0.9,0.7,1c0.9,0.5,2.1,1.2,3.3,2.4
  c1.5,1.5,2.3,3,2.8,4c0.5-0.3,0.7-0.6,0.9-0.9c0.6-0.9,0.7-2.1,0.3-3.4c0.4,0.1,0.7,0.1,1.1,0.1c2.8,0,4.8-2.1,5.6-3v-0.5
  C40.9,20.8,38.9,18.8,36,18.8z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B11">
  <title>B11</title>
  <path style="fill:#FCB040;" d="M36.1,21.8C36,21.8,36,21.8,36.1,21.8c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1-0.5-0.3-0.7-0.5
      c-0.7-0.6-1.2-1.4-1.5-2.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.1-0.4-0.1-0.5
      c0-0.4-0.1-0.7-0.1-1.1c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3
      c0-0.1,0-0.1,0-0.1c0-0.3,0.1-0.6,0.2-0.9c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3
      c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0-0.5,0-1,0-1.5c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
      c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
      c-0.1,0-0.3,0-0.4,0c-0.5,0-1,0.1-1.5,0.2c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0C30.2,10,30.1,10,30,10
      c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c-0.4,0.1-0.7,0.1-1.1,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.8,0.1
      c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.3,0-0.6,0-0.9-0.1c-0.2,0-0.3,0-0.5-0.1c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.2-0.7-0.4-1-0.6
      c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
      C22.2,8.4,22.1,8.2,22,8c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0.3-0.2,0.5-0.4,0.8c0,0.1-0.1,0.1-0.1,0.2
      c0,0.1-0.1,0.1-0.1,0.2C21.1,8.9,21,9,20.9,9c0,0,0,0,0,0c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.1-0.2,0.1
      c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0
      c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.7,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0
      c-0.6,0-1.1-0.1-1.7-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.4-0.1-0.7-0.1
      c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.8-0.1-1.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0.1-1,0.1
      c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0.3,0.1,0.6,0.1,0.8
      c0,0,0,0,0,0c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0,0,0c0.2,0.7,0.3,1.5,0.4,2.2c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.4l0,0
      c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5
      c0,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.4,0.9-0.7,1.4C9.2,20.9,9.1,21,9,21.1c0,0.1-0.1,0.1-0.1,0.1
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.3-0.7,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0.1,0,0.1,0.1
      c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.2,0.7,0.5,1,0.8c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.2,0.4c0.2,0.3,0.4,0.7,0.5,1.1
      c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2
      c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.6-0.1,1.2-0.2,1.9c-0.1,0.5-0.2,0.9-0.3,1.4c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.5-0.2,1-0.2,1.5
      c0,0.5,0,0.9,0.1,1.4c0,0,0,0,0,0c0,0.2,0.1,0.2,0.3,0.2c0.5,0.1,0.9,0.1,1.4,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
      c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.1,1.3-0.3,1.9-0.4c0.5-0.1,1.1-0.2,1.6-0.2c0,0,0,0,0.1,0
      c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0,0.9,0c0.7,0,1.3,0.2,2,0.5c0.5,0.2,1,0.6,1.4,1c0.3,0.3,0.5,0.6,0.7,0.9c0,0,0,0,0,0.1
      c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.3-0.5,0.5-0.7c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
      c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.3-0.2,0.5-0.3,0.8-0.4c0.8-0.3,1.7-0.4,2.6-0.4c0.1,0,0.2,0,0.3,0
      c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.5,0.1,1,0.1,1.5,0.2c0.1,0,0.2,0,0.3,0
      c0.3,0.1,0.5,0.1,0.8,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.5,0.1,1,0.2,1.5,0.2c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0
      c0.2,0,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0.1-0.2c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0-0.4,0-0.6
      c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.4-0.1-0.7c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1
      C34.1,31,34,30.8,34,30.5c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.7-0.3-1.4-0.4-2.1
      c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.7c0-0.4,0-0.9,0.1-1.3c0,0,0-0.1,0-0.1c0-0.3,0.1-0.7,0.2-1c0.2-0.6,0.5-1.2,1-1.7
      c0.4-0.5,0.9-0.8,1.5-1.1c0,0,0.1,0,0-0.1C36.2,21.9,36.1,21.9,36.1,21.8z M33.9,22.1c-0.5,0.5-1.1,1.1-1.6,1.6
      c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.1,0.2c0,1.2,0,2.3,0,3.5
      c0,0.3,0,0.6,0,0.8c0,0.2,0,0.5,0,0.7c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
      c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
      c-0.9,0-1.7,0-2.6,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0-0.6,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0.1
      c-0.8,0.8-1.6,1.6-2.4,2.4c-0.1,0.1-0.1,0.1-0.2,0c-0.8-0.8-1.6-1.6-2.5-2.4c-0.1-0.1-0.1-0.1-0.2-0.1c-1.2,0-2.4,0-3.6,0
      c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.9,0-1.9,0-2.8,0c-0.1,0-0.1,0-0.1-0.1c0-0.8,0-1.7,0-2.5c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2
      c0-0.9,0-1.9,0-2.8c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-0.9c0-0.1,0-0.1-0.1-0.2c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.5-0.5-0.7-0.7
      c0,0-0.1,0-0.1-0.1c-0.4-0.4-0.8-0.8-1.2-1.2c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1,0-0.2c0.3-0.3,0.7-0.7,1-1c0,0,0,0,0.1-0.1
      c0.3-0.3,0.6-0.6,1-1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.2c0-0.4,0-0.7,0-1.1c0-1.2,0-2.5,0-3.7
      c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.5,0-1,0-1.4c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1,0c0.9,0,1.7,0,2.6,0
      c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0c1,0,2.1,0,3.1,0c0.1,0,0.1,0,0.2-0.1c0.4-0.4,0.8-0.8,1.2-1.2
      c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.5-0.5,0.8-0.8c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.4
      c0.1,0.1,0.1,0.1,0.2,0.1c0.6,0,1.2,0,1.8,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c1.1,0,2.2,0,3.2,0c0,0,0.1,0,0.1,0
      c0.3,0,0.7,0,1,0c0.1,0,0.2,0,0.2,0c0.1,0.1,0,0.2,0,0.2c0,1.2,0,2.5,0,3.7c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0.1c0,0.6,0,1.3,0,1.9
      c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0.1,0.2c0.8,0.8,1.6,1.6,2.4,2.4C34.1,22,34.1,21.9,33.9,22.1z"/>
  <path style="fill:#3F94B5;" d="M30.7,21.9c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.7-0.7-1.1-1.1c0,0-0.1-0.1-0.1-0.1
      c0-0.3,0-0.5,0-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-1.2,0-2.5,0-3.7c0,0,0-0.1,0-0.1
      c0,0-0.1,0-0.1,0c-1.1,0-2.2,0-3.3,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.4,0-0.7,0-1.1,0c-0.1,0-0.2,0-0.3-0.1
      c-0.6-0.6-1.1-1.1-1.7-1.7c-0.1-0.1-0.1-0.1-0.2,0c0,0,0,0,0,0c-0.6,0.6-1.1,1.1-1.7,1.7C20,15,19.9,15,19.8,15c-1.3,0-2.7,0-4,0
      c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0-0.6,0c0,0.1,0,0.1,0,0.1c0,1.3,0,2.7,0,4c0,0.2,0,0.3,0,0.5
      c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.4-0.9,0.9-1.3,1.3
      c-0.1,0.1-0.1,0.1,0,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.9,0.9C15,23.9,15,23.9,15,24
      c0,0.5,0,0.9,0,1.4c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0
      c0,0,0,0.1,0,0.1c0,0.7,0,1.5,0,2.2c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.8,0,1.7,0,2.5,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
      c0.1,0,0.2,0,0.3,0c0.6,0,1.3,0,1.9,0c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0-0.1,0.1
      c0,0,0,0,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.5,0.5,0.8,0.8c0.1,0.1,0.1,0.1,0.1,0c0,0,0,0,0.1-0.1c0.6-0.6,1.1-1.1,1.7-1.7
      c0,0,0.1-0.1,0.2-0.1c0.3,0,0.6,0,1,0c0.5,0,1.1,0,1.6,0c0.7,0,1.5,0,2.2,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.9,0-1.8,0-2.7
      c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0.1-0.2c0.6-0.6,1.2-1.2,1.7-1.7
      C30.7,21.9,30.7,21.9,30.7,21.9z M26,23.6C25.9,23.7,25.9,23.7,26,23.6C25.9,23.7,25.9,23.7,26,23.6c-0.2,0.6-0.6,1-1,1.4
      c-0.4,0.4-0.9,0.7-1.4,1c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.2-1.1,0.3-1.7,0.3c-0.6,0-1.1-0.1-1.7-0.3c0,0,0,0,0,0c0,0,0,0,0,0
      c-0.5-0.2-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.1-1.1,0.3-1.7
      c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.5,0.6-1,1-1.4c0.4-0.4,0.9-0.7,1.4-1c0,0,0,0,0,0c0,0,0,0,0,0c0.5-0.2,1.1-0.3,1.7-0.3
      c0.6,0,1.1,0.1,1.7,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.5,0.2,1,0.6,1.4,1c0.4,0.4,0.7,0.9,1,1.4c0,0,0,0,0,0c0,0,0,0,0,0
      c0.2,0.5,0.3,1.1,0.3,1.7C26.3,22.5,26.2,23.1,26,23.6z"/>
  <path style="fill:#CF3C40;" d="M32.9,21.8c-0.7-0.7-1.5-1.5-2.2-2.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.4,0-0.8,0-1.2c0,0,0-0.1,0-0.1
      c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4c0-1.3,0-2.6,0-3.8c0,0,0-0.1,0-0.1c-0.1-0.1-0.2,0-0.3,0c-1.2,0-2.5,0-3.7,0
      c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.6,0-1.2,0-1.9,0c-0.1,0-0.1,0-0.2-0.1c-0.5-0.5-1.1-1.1-1.6-1.6
      c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.1-0.1-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0c-0.7,0.7-1.3,1.3-2,2
      c0,0-0.1,0.1-0.2,0.1c-1.3,0-2.5,0-3.8,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0
      c0,0-0.1,0-0.1,0c-0.4,0-0.9,0-1.3,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5
      c0,1.4,0,2.9,0,4.3c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3c-0.7,0.7-1.4,1.4-2.2,2.2c-0.1,0.1-0.1,0.1,0,0.2
      c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.8,0.8,1.2,1.1
      c0.1,0.1,0.1,0.1,0.1,0.2c0,1.2,0,2.5,0,3.7c0,0.2,0,0.3,0,0.5h0c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0c0,0,0,0.1,0,0.1
      c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0c0.7,0,1.3,0,2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c1.2,0,2.4,0,3.6,0
      c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0.4,0.4,0.8,0.8,1.1,1.1c0.1,0.1,0.3,0.3,0.4,0.4
      c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.1,0.1,0.2,0c0.7-0.7,1.4-1.4,2.1-2.1
      c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.6,0,0.9,0c0,0,0.1,0,0.1,0c1.4,0,2.9,0,4.3,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.7,0
      c0,0,0-0.1,0-0.1c0-1.4,0-2.8,0-4.3c0-0.6,0-1.2,0-1.9c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.4-0.4c0.3-0.3,0.6-0.5,0.8-0.8
      c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.6-0.6,0.9-0.9C33,21.9,33,21.9,32.9,21.8z M31.3,22
      C31.3,22,31.3,22,31.3,22c-0.7,0.7-1.3,1.3-1.9,1.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.6,0,1.2,0,1.8c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0.9,0,1.9,0,2.8c0,0.1,0,0.1-0.1,0.1c-0.9,0-1.8,0-2.7,0c-0.4,0-0.9,0-1.3,0
      c-0.4,0-0.7,0-1.1,0c-0.1,0-0.2,0-0.2,0.1c-0.6,0.6-1.2,1.2-1.8,1.8c-0.1,0.1-0.1,0.1-0.2,0c-0.3-0.3-0.6-0.6-0.9-0.9
      c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.2-0.2-0.4-0.4c0-0.1-0.1-0.1-0.2-0.1c-0.7,0-1.3,0-2,0
      c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.9,0-1.8,0-2.8,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0-0.8,0-1.6,0-2.4c0,0,0-0.1,0-0.1
      c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4,0-0.5c0-0.3,0-0.7,0-1c0-0.2,0-0.5,0-0.7c0-0.1,0-0.1-0.1-0.2c-0.3-0.3-0.5-0.5-0.8-0.8
      c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-0.9c0,0,0,0-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1-0.1
      c0.6-0.6,1.2-1.2,1.8-1.9c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0-1.5,0-3,0-4.5c0,0,0-0.1,0-0.1
      c0.3,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c1.4,0,2.7,0,4.1,0c0.1,0,0.1,0,0.2-0.1c0.6-0.6,1.2-1.2,1.8-1.8
      c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2,0c0.6,0.6,1.2,1.2,1.9,1.8c0.1,0.1,0.1,0.1,0.2,0.1c0.4,0,0.7,0,1.1,0c0,0,0.1,0,0.1,0
      c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c1.2,0,2.5,0,3.7,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,1.4,0,2.7,0,4.1c0,0,0,0,0,0.1
      c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0.2,0,0.4,0,0.7c0,0.1,0,0.2,0.1,0.3c0.6,0.6,1.2,1.2,1.8,1.8C31.3,21.9,31.3,21.9,31.3,22
      C31.3,21.9,31.3,22,31.3,22z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B12">
  <title>B12</title>
  <path style="fill:#FFFFFF;" d="M34.7,21.8c-0.3-0.3-0.6-0.6-0.9-0.9c-0.5-0.5-1.1-1.1-1.6-1.6c0,0-0.1-0.1-0.1-0.2
  c0-0.4,0-0.8,0-1.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-1.8,0-3.6,0-5.4c0-0.1,0-0.1,0-0.2
  c-0.1,0-0.1,0-0.2,0c-1.6,0-3.2,0-4.8,0c-0.1,0-0.3,0-0.4,0l0,0c0,0-0.1,0-0.1,0c-0.5,0-1,0-1.5,0c-0.2,0-0.3,0-0.4-0.2
  c-0.8-0.8-1.7-1.6-2.5-2.5c-0.1-0.1-0.1-0.1-0.2,0c0,0,0,0-0.1,0.1c-0.8,0.8-1.6,1.6-2.4,2.4C19.3,11.9,19.2,12,19,12
  c-1.9,0-3.9,0-5.8,0c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.8,0c0,0.1,0,0.1,0,0.2c0,1.9,0,3.9,0,5.8
  c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1-0.1,0.2c-0.2,0.2-0.4,0.4-0.6,0.6
  c-0.6,0.6-1.3,1.3-1.9,1.9c-0.1,0.1-0.1,0.1,0,0.3c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.4,0.4,0.9,0.9,1.3,1.3
  c0.1,0.1,0.1,0.1,0.1,0.2c0,0.7,0,1.3,0,2c0,0.1,0,0.2,0,0.4l0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7l0,0
  c0,0.1,0,0.1,0,0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0c1.2,0,2.4,0,3.6,0c0.1,0,0.1,0,0.2,0l0,0
  c0.1,0,0.3,0,0.4,0c0.9,0,1.8,0,2.7,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.3,0.6,0.6,0.9,0.9
  c0,0-0.1,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2c0.1,0.1,0.1,0.1,0.2,0
  c0,0,0.1-0.1,0.1-0.1c0.8-0.8,1.6-1.7,2.5-2.5c0.1-0.1,0.1-0.1,0.2-0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.4,0c1.1,0,2.1,0,3.2,0
  c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1c0-1.3,0-2.6,0-3.9c0-0.1,0-0.2,0-0.3l0,0c0-0.1,0-0.1,0-0.2c0-0.8,0-1.7,0-2.5
  c0-0.1,0-0.2,0.1-0.3c0.8-0.8,1.7-1.7,2.5-2.5C34.8,21.9,34.8,21.9,34.7,21.8z M27.9,24.4C27.9,24.4,27.9,24.4,27.9,24.4
  C27.9,24.4,27.9,24.4,27.9,24.4c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1.1-2,1.4c0,0,0,0,0,0c0,0,0,0,0,0
  c-0.8,0.3-1.6,0.5-2.4,0.5c-0.8,0-1.6-0.1-2.4-0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.8-0.3-1.5-0.8-2-1.4c-0.6-0.6-1.1-1.3-1.4-2
  c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.8-0.5-1.6-0.5-2.4c0-0.8,0.1-1.6,0.5-2.4c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.8,0.8-1.5,1.4-2
  c0.6-0.6,1.3-1.1,2-1.4c0,0,0,0,0,0c0,0,0,0,0,0c0.8-0.3,1.6-0.5,2.4-0.5c0.8,0,1.6,0.1,2.4,0.5c0,0,0,0,0,0c0,0,0,0,0,0
  c0.8,0.3,1.5,0.8,2,1.4c0.6,0.6,1.1,1.3,1.4,2c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.8,0.5,1.6,0.5,2.4C28.3,22.8,28.2,23.6,27.9,24.4z
  "/>

<path style="fill:#13356B;" d="M37.9,21.8c-1.1-1-2.1-2.1-3.2-3.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.6,0-1.1,0-1.7
  c0-0.1,0-0.1,0-0.2c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0-0.6c0-1.9,0-3.7,0-5.6c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.3,0-0.4,0
  c-1.8,0-3.6,0-5.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0c0,0,0,0-0.1,0c-0.9,0-1.8,0-2.7,0c-0.1,0-0.2,0-0.2-0.1
  C24.5,8.5,23.7,7.7,23,7c-0.3-0.3-0.6-0.6-0.9-0.9c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0.1
  c-0.9,1-1.9,1.9-2.9,2.9c-0.1,0.1-0.1,0.1-0.2,0.1c-1.8,0-3.7,0-5.5,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6,0
  c-0.2,0-0.3,0-0.5,0l0,0c-0.1,0-0.1,0-0.2,0c-0.6,0-1.2,0-1.9,0c-0.1,0-0.1,0-0.1,0.1c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.4
  c0,0.2,0,0.4,0,0.7c0,2.1,0.1,4.1,0,6.2c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4c-1,1-2.1,2.1-3.1,3.1
  c-0.2,0.2-0.2,0.1,0,0.3c0.1,0.1,0.3,0.3,0.4,0.4l0,0c0.3,0.3,0.6,0.6,0.9,0.9c0,0,0.1,0.1,0.1,0.1c0.6,0.6,1.1,1.1,1.7,1.7
  c0.1,0.1,0.1,0.2,0.1,0.3c0,1.8,0,3.6,0,5.3c0,0.2,0,0.5,0,0.7l0,0c0,0.3,0,0.6,0,0.9l0,0c0,0.1,0,0.1,0,0.2c0,0.6,0,1.1,0,1.7
  c0,0.2,0,0.2,0.2,0.2c0,0,0,0,0,0c1,0,1.9,0,2.9,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c1.7,0,3.5,0,5.2,0c0,0,0.1,0,0.1,0l0,0
  c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.6,0.6,1.1,1.1,1.7,1.6c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.3,0.3l0,0
  c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.2,0.1,0.2,0.3,0c1-1,2-2,3-3c0.2-0.2,0.4-0.3,0.6-0.3c0.4,0,0.9,0,1.3,0c0,0,0.1,0,0.1,0
  c2.1,0,4.2,0,6.3,0c0,0,0.1,0,0.1,0c0.3,0,0.6,0,1,0c0-0.1,0-0.1,0-0.2c0-2,0-4.1,0-6.1c0-0.9,0-1.8,0-2.7c0-0.1,0-0.2,0.1-0.3
  c0.2-0.2,0.4-0.4,0.5-0.5c0.4-0.4,0.8-0.8,1.2-1.2c0,0,0,0,0.1,0c0,0,0,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.4-0.4,0.9-0.9,1.3-1.3
  C38.1,21.9,38.1,21.9,37.9,21.8z M35.6,22C35.6,22,35.5,22.1,35.6,22c-1,1-1.8,1.9-2.7,2.7c-0.1,0.1-0.2,0.2-0.2,0.4
  c0,0.8,0,1.7,0,2.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,1.4,0,2.7,0,4.1c0,0.2,0,0.2-0.2,0.2
  c-1.3,0-2.6,0-3.9,0c-0.6,0-1.3,0-1.9,0c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0-0.3,0.1c-0.9,0.9-1.8,1.8-2.6,2.7
  c-0.2,0.2-0.1,0.2-0.3,0c-0.4-0.4-0.9-0.9-1.3-1.3c0,0-0.1-0.1-0.1-0.1c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.2-0.4-0.3-0.5-0.5
  c-0.1-0.1-0.1-0.1-0.2-0.1c-0.9,0-1.9,0-2.8,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-1.3,0-2.7,0-4,0c-0.1,0-0.1,0-0.2,0
  c0-0.1,0-0.1,0-0.2c0-1.2,0-2.3,0-3.5c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0-1.4c0-0.3,0-0.7,0-1
  c0-0.1,0-0.2-0.1-0.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.8-0.8-1.2-1.2
  c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.9-0.9,1.8-1.8,2.7-2.7c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3
  c0-0.2,0-0.4,0-0.6c0-2.2,0-4.3,0-6.5c0-0.1,0-0.1,0-0.2c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0c2,0,4,0,6,0
  c0.1,0,0.2,0,0.3-0.1c0.9-0.9,1.7-1.8,2.6-2.6c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2,0c0.9,0.9,1.8,1.8,2.7,2.7
  c0.1,0.1,0.2,0.1,0.3,0.1c0.5,0,1,0,1.6,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0,0-0.1c0.1,0,0.2,0,0.3,0c1.8,0,3.6,0,5.3,0
  c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,2,0,4,0,5.9c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3l0,0c0,0.3,0,0.6,0,1
  c0,0.2,0.1,0.3,0.2,0.5c0.9,0.9,1.8,1.7,2.6,2.6c0,0,0,0,0.1,0.1C35.6,21.9,35.6,22,35.6,22z"/>
</symbol>






<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C1">
  <title>C1</title>
  <path style="fill:#D03D41;" d="M27.4,20.9c0.7-0.3,1.4-0.3,1.9-0.2c-0.4-0.4-0.8-0.9-1.2-1.3c0-0.6-0.1-1.2-0.1-1.7
  c-0.3,0.5-0.8,1-1.5,1.3c-0.6,0.3-1.2,0.3-1.8,0.3c-0.1-0.6,0-1.2,0.3-1.8c0.3-0.7,0.7-1.2,1.3-1.5c-0.6,0-1.2-0.1-1.7-0.1
  c-0.4-0.4-0.9-0.8-1.3-1.2c0.2,0.6,0.1,1.2-0.2,1.9c-0.3,0.6-0.6,1.1-1.1,1.5c-0.5-0.4-0.8-0.9-1.1-1.5c-0.3-0.7-0.3-1.4-0.2-1.9
  c-0.4,0.4-0.9,0.8-1.3,1.2c-0.6,0-1.2,0.1-1.7,0.1c0.5,0.3,1,0.8,1.3,1.5c0.3,0.6,0.3,1.2,0.3,1.8c-0.6,0.1-1.2,0-1.8-0.3
  c-0.7-0.3-1.2-0.7-1.5-1.3c0,0.6-0.1,1.2-0.1,1.7c-0.4,0.4-0.8,0.9-1.2,1.3c0.6-0.2,1.2-0.1,1.9,0.2c0.6,0.3,1.1,0.6,1.5,1.1
  c-0.4,0.5-0.9,0.8-1.5,1.1c-0.7,0.3-1.4,0.3-1.9,0.2c0.4,0.4,0.8,0.9,1.2,1.3c0,0.6,0.1,1.2,0.1,1.7c0.3-0.5,0.8-1,1.5-1.3
  c0.6-0.3,1.2-0.3,1.8-0.3c0.1,0.6,0,1.2-0.3,1.8c-0.3,0.7-0.7,1.2-1.3,1.5c0.6,0,1.2,0.1,1.7,0.1c0.4,0.4,0.9,0.8,1.3,1.2
  c-0.2-0.6-0.1-1.2,0.2-1.9c0.3-0.6,0.6-1.1,1.1-1.5c0.5,0.4,0.8,0.9,1.1,1.5c0.3,0.7,0.3,1.4,0.2,1.9c0.4-0.4,0.9-0.8,1.3-1.2
  c0.6,0,1.2-0.1,1.7-0.1c-0.5-0.3-1-0.8-1.3-1.5c-0.3-0.6-0.3-1.2-0.3-1.8c0.6-0.1,1.2,0,1.8,0.3c0.7,0.3,1.2,0.7,1.5,1.3
  c0-0.6,0.1-1.2,0.1-1.7c0.4-0.4,0.8-0.9,1.2-1.3c-0.6,0.2-1.2,0.1-1.9-0.2c-0.6-0.3-1.1-0.6-1.5-1.1C26.3,21.5,26.8,21.2,27.4,20.9z
   M22.9,22.4c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
  c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c0.1-0.1,0.1-0.3,0.2-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.3-0.1,0.4-0.1
  c0.1-0.1,0.1-0.3,0.1-0.4c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.3-0.1,0.4-0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1
  c-0.1,0.1-0.1,0.3-0.2,0.4C22.8,22.1,22.9,22.3,22.9,22.4z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C2">
<title>C2</title>
<path  style="fill:#00366D;" d="M25.5,18.5c-0.4-0.6-0.8-1.3-1.3-1.9c-0.7,0.1-1.5,0.3-2.2,0.5c-0.7-0.2-1.5-0.3-2.2-0.5
c-0.4,0.6-0.8,1.3-1.3,1.9c-0.6,0.4-1.3,0.8-1.9,1.2c0.1,0.7,0.3,1.5,0.5,2.2c-0.2,0.7-0.3,1.5-0.5,2.2c0.6,0.4,1.3,0.8,1.9,1.2
c0.4,0.6,0.8,1.3,1.3,1.9c0.7-0.1,1.5-0.3,2.2-0.5c0.7,0.2,1.5,0.3,2.2,0.5c0.4-0.6,0.8-1.3,1.3-1.9c0.6-0.4,1.3-0.8,1.9-1.2
c-0.1-0.7-0.3-1.5-0.5-2.2c0.2-0.7,0.3-1.5,0.5-2.2C26.8,19.4,26.1,18.9,25.5,18.5z M18.5,23.8c-0.3,0.3-0.9,0.4-1.2,0.1
c0.1-0.4,0.5-0.8,1-0.8c0.2,0,0.4,0.1,0.5,0.2C18.8,23.5,18.7,23.7,18.5,23.8z M18.3,20.8c-0.5,0-0.9-0.3-1-0.8
c0.4-0.2,0.9-0.2,1.2,0.1c0.1,0.1,0.2,0.3,0.3,0.5C18.6,20.8,18.5,20.8,18.3,20.8z M20.1,17.3c0.4,0.1,0.8,0.5,0.8,1
c0,0.2-0.1,0.4-0.2,0.5c-0.2,0-0.4-0.1-0.5-0.3C19.8,18.2,19.8,17.7,20.1,17.3z M20.1,26.7c-0.2-0.4-0.2-0.9,0.1-1.2
c0.1-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5C20.8,26.2,20.5,26.5,20.1,26.7z M22.7,23.7c-0.2-0.1-0.5-0.1-0.7-0.2
c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.1-0.4-0.3-0.6-0.4c0.1-0.2,0.1-0.5,0.2-0.7c-0.1-0.2-0.1-0.5-0.2-0.7
c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.2,0.3-0.4,0.4-0.6c0.2,0.1,0.5,0.1,0.7,0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.1,0.2,0.3,0.4,0.4,0.6
c0.2,0.1,0.4,0.3,0.6,0.4c-0.1,0.2-0.1,0.5-0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7c-0.2,0.1-0.4,0.3-0.6,0.4C23,23.3,22.9,23.5,22.7,23.7z
 M23.9,26.7c-0.4-0.1-0.8-0.5-0.8-1c0-0.2,0.1-0.4,0.2-0.5c0.2,0,0.4,0.1,0.5,0.3C24.2,25.8,24.2,26.3,23.9,26.7z M23.8,18.6
c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.5,0.3-0.9,0.8-1C24.2,17.7,24.2,18.2,23.8,18.6z M25.5,23.8
c-0.1-0.1-0.2-0.3-0.3-0.5c0.2-0.1,0.3-0.2,0.5-0.2c0.5,0,0.9,0.3,1,0.8C26.3,24.2,25.8,24.1,25.5,23.8z M25.7,20.8
c-0.2,0-0.4-0.1-0.5-0.2c0-0.2,0.1-0.4,0.3-0.5c0.3-0.3,0.9-0.4,1.2-0.1C26.6,20.5,26.2,20.9,25.7,20.8z"/>

</symbol>

  


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C3">
<title>C3</title>
<path style="fill:#00376F;" d="M27.2,20c1.1-1.2,2.1-2.8,0.8-4.1c0,0,0,0,0,0c0,0,0,0,0,0c-1.3-1.3-2.9-0.3-4.1,0.8
c-0.1-1.6-0.5-3.5-2.3-3.5c0,0,0,0,0,0c0,0,0,0,0,0c-1.8,0-2.2,1.9-2.3,3.5c-1.2-1.1-2.8-2.1-4.1-0.8c0,0,0,0,0,0c0,0,0,0,0,0
c-1.3,1.3-0.3,2.9,0.8,4.1c-1.6,0.1-3.5,0.5-3.5,2.3c0,0,0,0,0,0c0,0,0,0,0,0c0,1.8,1.9,2.2,3.5,2.3c-1.1,1.2-2.1,2.8-0.8,4.1
c0,0,0,0,0,0c0,0,0,0,0,0c1.3,1.3,2.9,0.3,4.1-0.8c0.1,1.6,0.5,3.5,2.3,3.5c0,0,0,0,0,0c0,0,0,0,0,0c1.8,0,2.2-1.9,2.3-3.5
c1.2,1.1,2.8,2.1,4.1,0.8c0,0,0,0,0,0c0,0,0,0,0,0c1.3-1.3,0.3-2.9-0.8-4.1c1.6-0.1,3.5-0.5,3.5-2.3c0,0,0,0,0,0c0,0,0,0,0,0
C30.7,20.6,28.8,20.1,27.2,20z M25.2,23.9c-0.4,0.4-0.7,0.7-1.1,1.1c-0.4,0.4-0.7,0.7-1.1,1.1c-0.5,0-1,0-1.5,0c-0.5,0-1,0-1.5,0
c-0.4-0.4-0.7-0.7-1.1-1.1c-0.4-0.4-0.7-0.7-1.1-1.1c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-1.1
c0.4-0.4,0.7-0.7,1.1-1.1c0.5,0,1,0,1.5,0c0.5,0,1,0,1.5,0c0.4,0.4,0.7,0.7,1.1,1.1c0.4,0.4,0.7,0.7,1.1,1.1c0,0.5,0,1,0,1.5
C25.2,22.9,25.2,23.4,25.2,23.9z"/>
</symbol>
        
        
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C4">
  <title>C4</title>
  <path style="fill:#D03C40;" d="M32.4,23.9c-0.2-0.7-0.4-1.4-0.8-2c0.4-0.6,0.6-1.3,0.8-2c-0.3-0.1-0.6-0.3-0.8-0.4
  c-0.1,0.7-0.2,1.4-0.7,1.9c-0.5,0.4-1.2,0.6-1.8,0.3c-0.5-0.2-0.9-0.7-1-1.3c-0.1-0.4,0-0.8,0.2-1.1c-0.4-0.1-0.7-0.3-1-0.6
  c-0.4-0.5-0.4-1.1-0.2-1.6c0.2-0.6,0.8-1,1.5-1c0.7,0,1.3,0.4,1.8,0.8c0.1-0.3,0.2-0.6,0.3-0.9c-0.6-0.3-1.3-0.7-2-0.8
  c-0.1-0.7-0.5-1.3-0.8-2c-0.3,0.1-0.6,0.2-0.9,0.3c0.4,0.5,0.9,1.1,0.8,1.8c0,0.6-0.4,1.2-1,1.5c-0.5,0.2-1.2,0.1-1.6-0.2
  c-0.3-0.3-0.5-0.6-0.6-1c-0.3,0.2-0.7,0.3-1.1,0.2c-0.6-0.1-1.1-0.5-1.3-1c-0.2-0.6-0.1-1.3,0.3-1.8c0.5-0.5,1.2-0.6,1.9-0.7
  c-0.1-0.3-0.3-0.6-0.4-0.8c-0.7,0.2-1.4,0.4-2,0.8c-0.6-0.4-1.3-0.6-2-0.8c-0.1,0.3-0.3,0.6-0.4,0.8c0.7,0.1,1.4,0.2,1.9,0.7
  c0.4,0.5,0.6,1.2,0.3,1.8c-0.2,0.5-0.7,0.9-1.3,1c-0.4,0.1-0.8,0-1.1-0.2c-0.1,0.4-0.3,0.7-0.6,1c-0.5,0.4-1.1,0.4-1.6,0.2
  c-0.6-0.2-1-0.8-1-1.5c0-0.7,0.4-1.3,0.8-1.8c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3,0.6-0.7,1.3-0.8,2c-0.7,0.1-1.3,0.5-2,0.8
  c0.1,0.3,0.2,0.6,0.3,0.9c0.5-0.4,1.1-0.9,1.8-0.8c0.6,0,1.2,0.4,1.5,1c0.2,0.5,0.1,1.2-0.2,1.6c-0.3,0.3-0.6,0.5-1,0.6
  c0.2,0.3,0.3,0.7,0.2,1.1c-0.1,0.6-0.5,1.1-1,1.3c-0.6,0.2-1.3,0.1-1.8-0.3c-0.5-0.5-0.6-1.2-0.7-1.9c-0.3,0.1-0.6,0.3-0.8,0.4
  c0.2,0.7,0.4,1.4,0.8,2c-0.4,0.6-0.6,1.3-0.8,2c0.3,0.1,0.6,0.3,0.8,0.4c0.1-0.7,0.2-1.4,0.7-1.9c0.5-0.4,1.2-0.6,1.8-0.3
  c0.5,0.2,0.9,0.7,1,1.3c0.1,0.4,0,0.8-0.2,1.1c0.4,0.1,0.7,0.3,1,0.6c0.4,0.5,0.4,1.1,0.2,1.6c-0.2,0.6-0.8,1-1.5,1
  c-0.7,0-1.3-0.4-1.8-0.8c-0.1,0.3-0.2,0.6-0.3,0.9c0.6,0.3,1.3,0.7,2,0.8c0.1,0.7,0.5,1.3,0.8,2c0.3-0.1,0.6-0.2,0.9-0.3
  c-0.4-0.5-0.9-1.1-0.8-1.8c0-0.6,0.4-1.2,1-1.5c0.5-0.2,1.2-0.1,1.6,0.2c0.3,0.3,0.5,0.6,0.6,1c0.3-0.2,0.7-0.3,1.1-0.2
  c0.6,0.1,1.1,0.5,1.3,1c0.2,0.6,0.1,1.3-0.3,1.8c-0.5,0.5-1.2,0.6-1.9,0.7c0.1,0.3,0.3,0.6,0.4,0.8c0.7-0.2,1.4-0.4,2-0.8
  c0.6,0.4,1.3,0.6,2,0.8c0.1-0.3,0.3-0.6,0.4-0.8c-0.7-0.1-1.4-0.2-1.9-0.7c-0.4-0.5-0.6-1.2-0.3-1.8c0.2-0.5,0.7-0.9,1.3-1
  c0.4-0.1,0.8,0,1.1,0.2c0.1-0.4,0.3-0.7,0.6-1c0.5-0.4,1.1-0.4,1.6-0.2c0.6,0.2,1,0.8,1,1.5c0,0.7-0.4,1.3-0.8,1.8
  c0.3,0.1,0.6,0.2,0.9,0.3c0.3-0.6,0.7-1.3,0.8-2c0.7-0.1,1.3-0.5,2-0.8c-0.1-0.3-0.2-0.6-0.3-0.9c-0.5,0.4-1.1,0.9-1.8,0.8
  c-0.6,0-1.2-0.4-1.5-1c-0.2-0.5-0.1-1.2,0.2-1.6c0.3-0.3,0.6-0.5,1-0.6c-0.2-0.3-0.3-0.7-0.2-1.1c0.1-0.6,0.5-1.1,1-1.3
  c0.6-0.2,1.3-0.1,1.8,0.3c0.5,0.5,0.6,1.2,0.7,1.9C31.8,24.1,32.1,24,32.4,23.9z M27.2,23.3c0,0.3,0,0.5,0.1,0.7
  c-0.2,0.1-0.4,0.3-0.6,0.5c-0.4,0.4-0.6,0.9-0.6,1.4c-0.5,0-1,0.2-1.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6C24,27,23.8,27,23.5,27
  c-0.6,0-1,0.2-1.4,0.6c-0.4-0.4-0.8-0.6-1.4-0.6c-0.3,0-0.5,0-0.7,0.1c-0.1-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-0.9-0.6-1.4-0.6
  c0-0.5-0.2-1-0.6-1.4c-0.2-0.2-0.4-0.3-0.6-0.5c0.1-0.2,0.1-0.5,0.1-0.7c0-0.6-0.2-1-0.6-1.4c0.4-0.4,0.6-0.8,0.6-1.4
  c0-0.3,0-0.5-0.1-0.7c0.2-0.1,0.4-0.3,0.6-0.5c0.4-0.4,0.6-0.9,0.6-1.4c0.5,0,1-0.2,1.4-0.6c0.2-0.2,0.3-0.4,0.5-0.6
  c0.2,0.1,0.5,0.1,0.7,0.1c0.6,0,1-0.2,1.4-0.6c0.4,0.4,0.8,0.6,1.4,0.6c0.3,0,0.5,0,0.7-0.1c0.1,0.2,0.3,0.4,0.5,0.6
  c0.4,0.4,0.9,0.6,1.4,0.6c0,0.5,0.2,1,0.6,1.4c0.2,0.2,0.4,0.3,0.6,0.5c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.6,0.2,1,0.6,1.4
  C27.4,22.3,27.2,22.7,27.2,23.3z"/>
  <path style="fill:#D03C40;" d="M12.2,12c0.3,1.2,0.7,2.3,1.1,3.4c0.4-0.3,1-0.6,1.6-0.8c0.1-0.6,0.4-1.2,0.8-1.6
      C14.5,12.7,13.3,12.3,12.2,12z M14.3,14.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0-0.1c0,0,0,0,0-0.1
      c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.4,0,0.7,0.3,0.7,0.7
      C14.5,13.8,14.5,14,14.3,14.1z"/>
  <path style="fill:#D03C40;" d="M13.8,13.4c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.3,0.3,0.3,0.5,0.2
      C14.2,13.7,14.1,13.4,13.8,13.4z"/>
  <path style="fill:#D03C40;" d="M31,15.4c0.4-1.1,0.7-2.3,1.1-3.4c-1.2,0.3-2.3,0.7-3.4,1.1c0.3,0.4,0.6,1,0.8,1.6
      C29.9,14.8,30.5,15.1,31,15.4z M30.3,12.9C30.3,12.9,30.4,12.9,30.3,12.9c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2
      c0.1,0.1,0.2,0.3,0.2,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
      C29.7,13.3,30,13,30.3,12.9z"/>
  <path style="fill:#D03C40;" d="M30.7,13.6c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.3,0-0.3,0.3-0.2,0.5
      C30.3,14,30.6,13.9,30.7,13.6z"/>
  <path style="fill:#D03C40;" d="M13.5,30.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1c0.3,0,0.3-0.3,0.2-0.5
      C13.9,29.9,13.5,29.9,13.5,30.2z"/>
  <path style="fill:#D03C40;" d="M32,31.8c-0.3-1.2-0.7-2.3-1.1-3.4c-0.4,0.3-1,0.6-1.6,0.8c-0.1,0.6-0.4,1.2-0.8,1.6
      C29.7,31.2,30.9,31.5,32,31.8z M29.9,29.7c0.1-0.1,0.3-0.2,0.5-0.2c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0.1c0,0,0,0,0,0.1
      c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.4,0-0.7-0.3-0.7-0.7
      C29.7,30,29.7,29.8,29.9,29.7z"/>
  <path style="fill:#D03C40;" d="M13.2,28.4c-0.4,1.1-0.7,2.3-1.1,3.4c1.2-0.3,2.3-0.7,3.4-1.1c-0.3-0.4-0.6-1-0.8-1.6
      C14.3,29,13.7,28.8,13.2,28.4z M13.9,30.9C13.9,30.9,13.8,30.9,13.9,30.9c-0.1,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.2
      c-0.1-0.1-0.2-0.3-0.2-0.4c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.4,0.3-0.7,0.7-0.7c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5
      C14.5,30.6,14.2,30.9,13.9,30.9z"/>
  <path style="fill:#D03C40;" d="M34,22.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.5,0-0.5,0.2
      C33.5,22.1,33.8,22.3,34,22.1z"/>
  <path style="fill:#D03C40;" d="M30.4,30.5c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.3-0.3-0.3-0.5-0.2
      C30,30.1,30.1,30.5,30.4,30.5z"/>
  <path style="fill:#D03C40;" d="M8.1,21.9c1,0.6,2.1,1.2,3.2,1.7c0.1-0.5,0.3-1.1,0.6-1.7c-0.3-0.5-0.5-1.1-0.6-1.7
      C10.2,20.8,9.1,21.3,8.1,21.9z M10.9,22.4c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.3-0.2-0.5
      c0-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.2,0.7-0.2,1,0c0.1,0.1,0.2,0.3,0.2,0.5C11.1,22.1,11,22.3,10.9,22.4z"/>
  <path style="fill:#D03C40;" d="M10.2,21.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.2,0.2,0.5,0,0.5-0.2
      C10.7,21.7,10.4,21.5,10.2,21.7z"/>
  <path style="fill:#D03C40;" d="M22.1,32.2c-0.5,0.3-1.1,0.5-1.7,0.6c0.5,1.1,1.1,2.1,1.7,3.2c0.6-1,1.2-2.1,1.7-3.2
      C23.2,32.7,22.6,32.5,22.1,32.2z M22.6,34.1C22.6,34.1,22.6,34.1,22.6,34.1c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2
      c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.3-0.2-0.7,0-1c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2C22.9,33.4,22.9,33.8,22.6,34.1
      C22.6,34.1,22.6,34.1,22.6,34.1z"/>
  <path style="fill:#D03C40;" d="M22.1,33.3c-0.2,0-0.4,0.3-0.2,0.5c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
      C22.5,33.6,22.3,33.3,22.1,33.3z"/>
  <path style="fill:#D03C40;" d="M36.1,21.9c-1-0.6-2.1-1.2-3.2-1.7c-0.1,0.5-0.3,1.1-0.6,1.7c0.3,0.5,0.5,1.1,0.6,1.7
      C34,23.1,35.1,22.5,36.1,21.9z M33.3,21.4c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.5
      c0,0.2-0.1,0.3-0.2,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0.2-0.7,0.2-1,0c-0.1-0.1-0.2-0.3-0.2-0.5C33.1,21.7,33.2,21.5,33.3,21.4z
      "/>
  <path style="fill:#D03C40;" d="M22.1,10.5c0.2,0,0.4-0.3,0.2-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1
      C21.7,10.2,21.9,10.5,22.1,10.5z"/>
  <path style="fill:#D03C40;" d="M22.1,11.7c0.5-0.3,1.1-0.5,1.7-0.6c-0.5-1.1-1.1-2.1-1.7-3.2c-0.6,1-1.2,2.1-1.7,3.2
      C21,11.1,21.6,11.3,22.1,11.7z M21.6,9.7C21.6,9.7,21.6,9.7,21.6,9.7c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2
      c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.3,0.2,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C21.3,10.4,21.3,10,21.6,9.7
      C21.6,9.7,21.6,9.7,21.6,9.7z"/>
<path style="fill:#00366F;" d="M26.5,20.7c-0.3-0.1-0.6-0.2-0.9-0.3c0.1-0.3,0.3-0.6,0.4-0.8c0-0.1-0.1-0.1-0.1-0.2
c-0.2-0.3-0.4-0.6-0.4-0.9c-0.3-0.1-0.6-0.2-0.9-0.4c-0.1,0-0.1-0.1-0.2-0.1c-0.3,0.1-0.6,0.3-0.8,0.4c-0.1-0.3-0.2-0.6-0.3-0.9
c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.2-1-0.4c-0.3,0.2-0.6,0.3-1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0.3-0.2,0.6-0.3,0.9
c-0.3-0.1-0.6-0.3-0.8-0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.4-0.9,0.4c-0.1,0.3-0.2,0.6-0.4,0.9c0,0.1-0.1,0.1-0.1,0.2
c0.1,0.3,0.3,0.6,0.4,0.8c-0.3,0.1-0.6,0.2-0.9,0.3c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.7-0.4,1c0.2,0.3,0.3,0.6,0.4,1
c0,0.1,0,0.1,0,0.2c0.3,0.1,0.6,0.2,0.9,0.3c-0.1,0.3-0.3,0.6-0.4,0.8c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.4,0.9
c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.1,0.1,0.2,0.1c0.3-0.1,0.6-0.3,0.8-0.4c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0,0.1,0,0.2,0
c0.4,0,0.7,0.2,1,0.4c0.3-0.2,0.6-0.3,1-0.4c0.1,0,0.1,0,0.2,0c0.1-0.3,0.2-0.6,0.3-0.9c0.3,0.1,0.6,0.3,0.8,0.4
c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,0.9-0.4c0.1-0.3,0.2-0.6,0.4-0.9c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.3-0.3-0.6-0.4-0.8
c0.3-0.1,0.6-0.2,0.9-0.3c0-0.1,0-0.1,0-0.2c0-0.4,0.2-0.7,0.4-1c-0.2-0.3-0.3-0.6-0.4-1C26.5,20.9,26.5,20.8,26.5,20.7z
M22.8,22.2c0,0.2,0,0.3,0,0.5c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2,0-0.3,0-0.5,0
c0-0.2,0-0.3,0-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0-0.2,0-0.3,0-0.5c0.2,0,0.3,0,0.5,0
c0.1-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0,0.3,0,0.5,0c0,0.2,0,0.3,0,0.5c0.1,0.1,0.2,0.2,0.4,0.3
C23.1,22,23,22.1,22.8,22.2z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C5">
<title>C5</title>
			<path style="fill:#F7941C;" d="M34.4,23.1c-0.4-0.2-0.8-0.3-1.2-0.5c-0.4,0.8-1,1.4-1.9,1.4c-1,0.1-1.7-0.5-2.4-1.1
				c-0.3-0.3-0.5-0.6-0.7-0.9c0.2-0.3,0.5-0.6,0.7-0.9c0.7-0.7,1.4-1.2,2.4-1.1c1,0.1,1.5,0.7,1.9,1.4c0.4-0.2,0.8-0.4,1.2-0.5
				C34,20.2,33.6,19.5,33,19c0.3-0.7,0.3-1.5,0.3-2.3c-0.4,0.1-0.9,0.1-1.3,0.1c0,0.9-0.2,1.7-1,2.2c-0.8,0.6-1.7,0.5-2.6,0.2
				c-0.4-0.1-0.7-0.2-1.1-0.4c0-0.4,0.1-0.7,0.2-1.1c0.3-0.9,0.6-1.7,1.5-2.2c0.9-0.4,1.6-0.2,2.4,0.3c0.2-0.4,0.5-0.7,0.8-1.1
				c-0.7-0.4-1.4-0.8-2.1-0.9c-0.1-0.7-0.5-1.4-0.9-2.1c-0.3,0.3-0.7,0.5-1.1,0.8c0.5,0.8,0.7,1.5,0.3,2.4c-0.4,0.9-1.3,1.3-2.2,1.5
				c-0.4,0.1-0.8,0.2-1.1,0.2c-0.2-0.3-0.3-0.7-0.4-1.1c-0.2-0.9-0.4-1.8,0.2-2.6c0.5-0.8,1.3-1,2.2-1c0-0.4,0.1-0.9,0.1-1.3
				c-0.8,0-1.6,0-2.3,0.3c-0.5-0.6-1.1-1-1.9-1.4c-0.2,0.4-0.3,0.8-0.5,1.2c0.8,0.4,1.4,1,1.4,1.9c0.1,1-0.5,1.7-1.1,2.4
				c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3-0.2-0.6-0.5-0.9-0.7c-0.7-0.7-1.2-1.4-1.1-2.4c0.1-1,0.7-1.5,1.4-1.9c-0.2-0.4-0.4-0.8-0.5-1.2
				c-0.7,0.4-1.4,0.8-1.9,1.4c-0.7-0.3-1.5-0.3-2.3-0.3c0.1,0.4,0.1,0.9,0.1,1.3c0.9,0,1.7,0.2,2.2,1c0.6,0.8,0.5,1.7,0.2,2.6
				c-0.1,0.4-0.2,0.7-0.4,1.1c-0.4,0-0.7-0.1-1.1-0.2c-0.9-0.3-1.7-0.6-2.2-1.5c-0.4-0.9-0.2-1.6,0.3-2.4c-0.4-0.2-0.7-0.5-1.1-0.8
				c-0.4,0.7-0.8,1.4-0.9,2.1c-0.7,0.1-1.4,0.5-2.1,0.9c0.3,0.3,0.5,0.7,0.8,1.1c0.8-0.5,1.5-0.7,2.4-0.3c0.9,0.4,1.3,1.3,1.5,2.2
				c0.1,0.4,0.2,0.8,0.2,1.1c-0.3,0.2-0.7,0.3-1.1,0.4c-0.9,0.2-1.8,0.4-2.6-0.2c-0.8-0.5-1-1.3-1-2.2c-0.4,0-0.9-0.1-1.3-0.1
				c0,0.8,0,1.6,0.3,2.3c-0.6,0.5-1,1.1-1.4,1.9c0.4,0.2,0.8,0.3,1.2,0.5c0.4-0.8,1-1.4,1.9-1.4c1-0.1,1.7,0.5,2.4,1.1
				c0.3,0.3,0.5,0.6,0.7,0.9c-0.2,0.3-0.5,0.6-0.7,0.9c-0.7,0.7-1.4,1.2-2.4,1.1c-1-0.1-1.5-0.7-1.9-1.4c-0.4,0.2-0.8,0.4-1.2,0.5
				c0.4,0.7,0.8,1.4,1.4,1.9c-0.3,0.7-0.3,1.5-0.3,2.3c0.4-0.1,0.9-0.1,1.3-0.1c0-0.9,0.2-1.7,1-2.2c0.8-0.6,1.7-0.5,2.6-0.2
				c0.4,0.1,0.7,0.2,1.1,0.4c0,0.4-0.1,0.7-0.2,1.1c-0.3,0.9-0.6,1.7-1.5,2.2c-0.9,0.4-1.6,0.2-2.4-0.3c-0.2,0.4-0.5,0.7-0.8,1.1
				c0.7,0.4,1.4,0.8,2.1,0.9c0.1,0.7,0.5,1.4,0.9,2.1c0.3-0.3,0.7-0.5,1.1-0.8c-0.5-0.8-0.7-1.5-0.3-2.4c0.4-0.9,1.3-1.3,2.2-1.5
				c0.4-0.1,0.8-0.2,1.1-0.2c0.2,0.3,0.3,0.7,0.4,1.1c0.2,0.9,0.4,1.8-0.2,2.6c-0.5,0.8-1.3,1-2.2,1c0,0.4-0.1,0.9-0.1,1.3
				c0.8,0,1.6,0,2.3-0.3c0.5,0.6,1.1,1,1.9,1.4c0.2-0.4,0.3-0.8,0.5-1.2c-0.8-0.4-1.4-1-1.4-1.9c-0.1-1,0.5-1.7,1.1-2.4
				c0.3-0.3,0.6-0.5,0.9-0.7c0.3,0.2,0.6,0.5,0.9,0.7c0.7,0.7,1.2,1.4,1.1,2.4c-0.1,1-0.7,1.5-1.4,1.9c0.2,0.4,0.4,0.8,0.5,1.2
				c0.7-0.4,1.4-0.8,1.9-1.4c0.7,0.3,1.5,0.3,2.3,0.3c-0.1-0.4-0.1-0.9-0.1-1.3c-0.9,0-1.7-0.2-2.2-1c-0.6-0.8-0.5-1.7-0.2-2.6
				c0.1-0.4,0.2-0.7,0.4-1.1c0.4,0,0.7,0.1,1.1,0.2c0.9,0.3,1.7,0.6,2.2,1.5c0.4,0.9,0.2,1.6-0.3,2.4c0.4,0.2,0.7,0.5,1.1,0.8
				c0.4-0.7,0.8-1.4,0.9-2.1c0.7-0.1,1.4-0.5,2.1-0.9c-0.3-0.3-0.5-0.7-0.8-1.1c-0.8,0.5-1.5,0.7-2.4,0.3c-0.9-0.4-1.3-1.3-1.5-2.2
				c-0.1-0.4-0.2-0.8-0.2-1.1c0.3-0.2,0.7-0.3,1.1-0.4c0.9-0.2,1.8-0.4,2.6,0.2c0.8,0.5,1,1.3,1,2.2c0.4,0,0.9,0.1,1.3,0.1
				c0-0.8,0-1.6-0.3-2.3C33.6,24.5,34,23.8,34.4,23.1z M27.2,23.4c-0.6,0.2-1.1,0.4-1.5,0.8c-0.1,0.6,0,1.1,0.1,1.7
				c-0.6-0.1-1.1-0.2-1.7-0.1c-0.3,0.4-0.6,1-0.8,1.5c-0.4-0.4-0.9-0.7-1.4-0.9c-0.5,0.2-1,0.5-1.4,0.9c-0.2-0.6-0.4-1.1-0.8-1.5
				c-0.6-0.1-1.1,0-1.7,0.1c0.1-0.6,0.2-1.1,0.1-1.7c-0.4-0.3-1-0.6-1.5-0.8c0.4-0.4,0.7-0.9,0.9-1.4c-0.2-0.5-0.5-1-0.9-1.4
				c0.6-0.2,1.1-0.4,1.5-0.8c0.1-0.6,0-1.1-0.1-1.7c0.6,0.1,1.1,0.2,1.7,0.1c0.3-0.4,0.6-1,0.8-1.5c0.4,0.4,0.9,0.7,1.4,0.9
				c0.5-0.2,1-0.5,1.4-0.9c0.2,0.6,0.4,1.1,0.8,1.5c0.6,0.1,1.1,0,1.7-0.1c-0.1,0.6-0.2,1.1-0.1,1.7c0.4,0.3,1,0.6,1.5,0.8
				c-0.4,0.4-0.7,0.9-0.9,1.4C26.5,22.5,26.8,23,27.2,23.4z"/>
		
				<path style="fill:#CF4040;" d="M20.6,13c0,0.7,0.5,1.2,1,1.7c0.1,0.1,0.3,0.3,0.4,0.4c0.1-0.1,0.3-0.3,0.4-0.4
					c0.5-0.5,0.9-0.9,1-1.7c0-0.8-0.5-1.3-1.1-1.7c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1-0.1-0.2-0.3-0.2-0.4C21,11.7,20.5,12.1,20.6,13z"
					/>
			
				<path style="fill:#CF4040;" d="M16.2,14.9c0.4,0.6,1,0.8,1.7,1c0.2,0.1,0.4,0.1,0.6,0.1c0.1-0.2,0.1-0.4,0.2-0.6
					c0.2-0.6,0.3-1.3,0-1.9c-0.4-0.7-1.1-0.9-1.8-0.9c0,0.2,0,0.3,0,0.5c-0.1-0.1-0.3-0.1-0.4-0.2C16,13.5,15.8,14.2,16.2,14.9z"/>
			
				<path style="fill:#CF4040;" d="M27.1,12.6c-0.7,0-1.4,0.1-1.8,0.9c-0.3,0.6-0.2,1.3,0,1.9c0.1,0.2,0.1,0.4,0.2,0.6
					c0.2,0,0.4-0.1,0.6-0.1c0.7-0.2,1.3-0.4,1.7-1c0.4-0.7,0.2-1.4-0.2-2c-0.1,0.1-0.3,0.1-0.4,0.2C27.1,12.9,27.1,12.8,27.1,12.6z"
					/>
		
				<path style="fill:#CF4040;" d="M13.4,18.7c0.6,0.3,1.3,0.2,1.9,0c0.2-0.1,0.4-0.1,0.6-0.2c0-0.2-0.1-0.4-0.1-0.6
					c-0.2-0.7-0.4-1.3-1-1.7c-0.7-0.4-1.4-0.2-2,0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0-0.3,0-0.5,0C12.6,17.6,12.7,18.3,13.4,18.7z"/>
		
				<path style="fill:#CF4040;" d="M12.9,23.4c0.7,0,1.2-0.5,1.7-1c0.1-0.1,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
					c-0.5-0.5-0.9-0.9-1.7-1c-0.8,0-1.3,0.5-1.7,1.1c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.2
					C11.6,22.9,12.1,23.4,12.9,23.4z"/>
			
				<path style="fill:#CF4040;" d="M33.1,14.7c-0.4,0.4-0.8,0.9-1.2,1.5c0.6,0,1.3-0.1,1.9-0.3c1.4-0.3,3.2-1.3,3.3-2.7
					C35.8,12.6,34.1,13.7,33.1,14.7z"/>
			
				<path style="fill:#CF4040;" d="M31,20.6c-0.7,0-1.2,0.5-1.7,1c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4
					c0.5,0.5,0.9,0.9,1.7,1c0.8,0,1.3-0.5,1.7-1.1c-0.1-0.1-0.3-0.2-0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.2C32.3,21.1,31.8,20.6,31,20.6z"
					/>
		
				<path style="fill:#CF4040;" d="M29.1,16.3c-0.6,0.4-0.8,1-1,1.7c-0.1,0.2-0.1,0.4-0.1,0.6c0.2,0.1,0.4,0.1,0.6,0.2
					c0.6,0.2,1.3,0.3,1.9,0c0.7-0.4,0.9-1.1,0.9-1.8c-0.2,0-0.3,0-0.5,0c0.1-0.1,0.1-0.3,0.2-0.4C30.4,16.1,29.8,15.8,29.1,16.3z"/>
			
				<path style="fill:#CF4040;" d="M23.3,31c0-0.7-0.5-1.2-1-1.7c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0.1-0.3,0.3-0.4,0.4
					c-0.5,0.5-0.9,0.9-1,1.7c0,0.8,0.5,1.3,1.1,1.7c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.2,0.3,0.2,0.4C22.8,32.3,23.4,31.9,23.3,31z"
					/>
			
				<path style="fill:#CF4040;" d="M30.4,25.3c-0.6-0.3-1.3-0.2-1.9,0c-0.2,0.1-0.4,0.1-0.6,0.2c0,0.2,0.1,0.4,0.1,0.6
					c0.2,0.7,0.4,1.3,1,1.7c0.7,0.4,1.4,0.2,2-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.2,0,0.3,0,0.5,0C31.3,26.4,31.2,25.7,30.4,25.3z"/>
			
				<path style="fill:#CF4040;" d="M39.4,22c-0.8-1.2-2.8-1.1-4.2-0.7c-0.6,0.2-1.2,0.4-1.8,0.7c0.6,0.3,1.2,0.5,1.8,0.7
					C36.6,23.1,38.7,23.2,39.4,22z"/>
			
				<path style="fill:#CF4040;" d="M16.8,31.4c0.7,0,1.4-0.1,1.8-0.9c0.3-0.6,0.2-1.3,0-1.9c-0.1-0.2-0.1-0.4-0.2-0.6
					c-0.2,0-0.4,0.1-0.6,0.1c-0.7,0.2-1.3,0.4-1.7,1c-0.4,0.7-0.2,1.4,0.2,2c0.1-0.1,0.3-0.1,0.4-0.2C16.8,31.1,16.8,31.2,16.8,31.4
					z"/>
			
				<path style="fill:#CF4040;" d="M14.8,27.7c0.6-0.4,0.8-1,1-1.7c0.1-0.2,0.1-0.4,0.1-0.6c-0.2-0.1-0.4-0.1-0.6-0.2
					c-0.6-0.2-1.3-0.3-1.9,0c-0.7,0.4-0.9,1.1-0.9,1.8c0.2,0,0.3,0,0.5,0c-0.1,0.1-0.1,0.3-0.2,0.4C13.5,27.9,14.1,28.2,14.8,27.7z"
					/>
		
				<path style="fill:#CF4040;" d="M8.6,21.3c-1.4-0.4-3.4-0.5-4.2,0.7c0.8,1.2,2.8,1.1,4.2,0.7c0.6-0.2,1.2-0.4,1.8-0.7
					C9.8,21.7,9.2,21.5,8.6,21.3z"/>
			
				<path style="fill:#CF4040;" d="M33.8,28.1c-0.6-0.1-1.2-0.2-1.9-0.3c0.3,0.5,0.7,1,1.2,1.5c1,1,2.7,2.1,4,1.5
					C37,29.3,35.2,28.4,33.8,28.1z"/>
			
				<path style="fill:#CF4040;" d="M29.2,33.2c-0.4-0.4-0.9-0.8-1.5-1.2c0,0.6,0.1,1.3,0.3,1.9c0.3,1.4,1.3,3.2,2.7,3.3
					C31.3,35.9,30.2,34.2,29.2,33.2z"/>
			
				<path style="fill:#CF4040;" d="M21.9,33.6c-0.3,0.6-0.5,1.2-0.7,1.8c-0.4,1.4-0.5,3.4,0.7,4.2c1.2-0.8,1.1-2.8,0.7-4.2
					C22.5,34.7,22.2,34.1,21.9,33.6z"/>
			
				<path style="fill:#CF4040;" d="M15.9,33.9c0.1-0.6,0.2-1.2,0.3-1.9c-0.5,0.3-1,0.7-1.5,1.2c-1,1-2.1,2.7-1.5,4
					C14.6,37.1,15.5,35.3,15.9,33.9z"/>
			
				<path style="fill:#CF4040;" d="M28,10.1c-0.1,0.6-0.2,1.2-0.3,1.9c0.5-0.3,1-0.7,1.5-1.2c1-1,2.1-2.7,1.5-4
					C29.3,6.9,28.3,8.7,28,10.1z"/>
			
				<path style="fill:#CF4040;" d="M10.8,29.3c0.4-0.4,0.8-0.9,1.2-1.5c-0.6,0-1.3,0.1-1.9,0.3c-1.4,0.3-3.2,1.3-3.3,2.7
					C8.1,31.4,9.8,30.3,10.8,29.3z"/>
			
				<path style="fill:#CF4040;" d="M14.7,10.8c0.4,0.4,0.9,0.8,1.5,1.2c0-0.6-0.1-1.3-0.3-1.9c-0.3-1.4-1.3-3.2-2.7-3.3
					C12.5,8.1,13.7,9.8,14.7,10.8z"/>
			
				<path style="fill:#CF4040;" d="M21.9,10.4c0.3-0.6,0.5-1.2,0.7-1.8c0.4-1.4,0.5-3.4-0.7-4.2c-1.2,0.8-1.1,2.8-0.7,4.2
					C21.4,9.3,21.7,9.9,21.9,10.4z"/>
			
				<path style="fill:#CF4040;" d="M10.1,15.9c0.6,0.1,1.2,0.2,1.9,0.3c-0.3-0.5-0.7-1-1.2-1.5c-1-1-2.7-2.1-4-1.5
					C6.9,14.7,8.7,15.6,10.1,15.9z"/>
			
				<path style="fill:#CF4040;" d="M27.6,29.1c-0.4-0.6-1-0.8-1.7-1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0.2-0.1,0.4-0.2,0.6
					c-0.2,0.6-0.3,1.3,0,1.9c0.4,0.7,1.1,0.9,1.8,0.9c0-0.2,0-0.3,0-0.5c0.1,0.1,0.3,0.1,0.4,0.2C27.9,30.5,28.1,29.8,27.6,29.1z"/>
		
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C6">
<title>C6</title>
<path  style="fill:#D04042;" d="M13.9,19.8c-0.9,0.4-1.7,1-2.3,1.7
c-0.2,0.2-0.4,0.4-0.6,0.7c0.2,0.2,0.4,0.5,0.6,0.7c0.7,0.7,1.4,1.3,2.3,1.7c2,0.9,4,0.1,5.6-1.2l1.3,1.3c-1.2,1.7-2.1,3.6-1.2,5.6
c0.4,0.9,1,1.7,1.7,2.3c0.2,0.2,0.4,0.4,0.5,0.5l0.1,0.1l0.1-0.1c0.2-0.2,0.4-0.4,0.5-0.5c0.7-0.7,1.3-1.4,1.7-2.3
c0.9-2,0.1-4-1.2-5.6l1.3-1.3c1.7,1.2,3.6,2.1,5.6,1.2c0.9-0.4,1.7-1,2.3-1.7c0.2-0.2,0.4-0.4,0.5-0.5l0.1-0.1L32.9,22
c-0.2-0.2-0.4-0.4-0.5-0.5c-0.7-0.7-1.4-1.3-2.3-1.7c-2-0.9-4-0.1-5.6,1.2l-1.3-1.3c1.2-1.7,2.1-3.6,1.2-5.6
c-0.4-0.9-1-1.7-1.7-2.3c-0.2-0.2-0.4-0.4-0.5-0.5L22,11.1l-0.1,0.1c-0.2,0.2-0.4,0.4-0.5,0.5c-0.7,0.7-1.3,1.4-1.7,2.3
c-0.9,2-0.1,4,1.2,5.6l-1.3,1.3C17.9,19.7,15.9,18.9,13.9,19.8z"/>
</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C7">
<title>C7</title>
<path style="fill:#FA9F2C;" d="M22,18c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,1.2-2.3,2.3-3.5,3.5c-0.3,0.3-0.2,0.4,0.1,0.6
c1.1,1.1,2.3,2.2,3.4,3.4c0.2,0.2,0.4,0.3,0.6,0c1.1-1.2,2.3-2.3,3.4-3.4c0.3-0.2,0.3-0.4,0-0.6c-1.2-1.1-2.3-2.3-3.4-3.4
C22.2,18.2,22.1,18.1,22,18z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C8">
<title>C8</title>
<path  style="fill:#14366B;" d="M14,19.5c-0.9,0.4-1.6,1-2.3,1.7
c-0.2,0.2-0.4,0.4-0.6,0.7c0.2,0.2,0.4,0.4,0.6,0.7c0.7,0.7,1.4,1.3,2.3,1.7c2,0.9,3.9,0.1,5.6-1.2l1.2,1.2
c-1.2,1.6-2.1,3.6-1.2,5.6c0.4,0.9,1,1.6,1.7,2.3c0.2,0.2,0.4,0.4,0.5,0.5l0.1,0.1l0.1-0.1c0.2-0.2,0.4-0.3,0.5-0.5
c0.7-0.7,1.3-1.4,1.7-2.3c0.9-2,0.1-3.9-1.2-5.6l1.2-1.2c1.6,1.2,3.6,2.1,5.6,1.2c0.9-0.4,1.6-1,2.3-1.7c0.2-0.2,0.4-0.4,0.5-0.5
l0.1-0.1l-0.1-0.1c-0.2-0.2-0.3-0.4-0.5-0.5c-0.7-0.7-1.4-1.3-2.3-1.7c-2-0.9-3.9-0.1-5.6,1.2l-1.2-1.2c1.2-1.6,2.1-3.6,1.2-5.6
c-0.4-0.9-1-1.6-1.7-2.3c-0.2-0.2-0.4-0.4-0.5-0.5L22,10.9L21.9,11c-0.2,0.2-0.4,0.3-0.5,0.5c-0.7,0.7-1.3,1.4-1.7,2.3
c-0.9,2-0.1,3.9,1.2,5.6l-1.2,1.2C17.9,19.5,16,18.7,14,19.5z"/>
</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C9">
<title>C9</title>
<path style="fill:#14366B;" d="M23.5,24.8c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.2-0.1-0.4-0.1-0.6c-0.3,0.2-0.6,0.2-0.9,0.2
c-0.3,0-0.6-0.1-0.8-0.2c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3,0.4-0.6,0.7-0.8,1
c0.5,0,0.9,0,1.2,0.2c0.4,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.4,0.5-0.8,0.7c0.1,0.1,0.2,0.3,0.4,0.5
c0.2,0.3,0.4,0.6,0.5,0.9c0.2,0.3,0.3,0.6,0.3,1c0,0.3-0.1,0.5-0.4,0.7c0,0.5,0,0.9-0.1,1.2c0.3-0.2,0.7-0.3,1.1-0.3
c0.3,0,0.6,0.1,0.9,0.3c-0.1-0.3-0.1-0.7-0.1-1.1c-0.3-0.2-0.4-0.4-0.4-0.7c0-0.3,0.1-0.6,0.3-1c0.2-0.3,0.3-0.6,0.5-0.9
c0.2-0.3,0.3-0.5,0.4-0.5c-0.5-0.2-0.7-0.4-0.8-0.7c-0.1-0.3-0.1-0.5,0.1-0.8c0.2-0.2,0.4-0.4,0.8-0.6c0.4-0.2,0.8-0.2,1.2-0.2
c-0.3-0.3-0.6-0.6-0.8-1C23.9,25.7,23.7,25.3,23.5,24.8z"/>
<path style="fill:#14366B;" d="M20.6,19.1c0.2,0.4,0.3,0.7,0.4,1c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.7,0.1,1,0.3
c0.1-0.3,0.3-0.7,0.5-1.1c0.2-0.4,0.5-0.8,0.7-1.2c0.3-0.4,0.5-0.8,0.9-1c-0.5,0.1-0.9,0-1.2-0.2c-0.4-0.2-0.7-0.3-0.8-0.6
c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.5,0.8-0.7c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2-0.3-0.4-0.6-0.5-0.9c-0.2-0.3-0.3-0.6-0.3-1
c0-0.3,0.1-0.5,0.4-0.7c0-0.4,0-0.7,0.1-1c-0.3,0.2-0.6,0.3-0.9,0.3c-0.3,0-0.7-0.1-1-0.3c0.1,0.3,0.1,0.7,0.1,1.1
c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.1,0.6-0.3,1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.3,0.5-0.4,0.5c0.5,0.2,0.7,0.4,0.8,0.7
c0.1,0.3,0,0.5-0.1,0.8c-0.2,0.2-0.4,0.4-0.8,0.6c-0.4,0.2-0.8,0.2-1.2,0.2c0.3,0.3,0.6,0.6,0.8,1C20.1,18.2,20.4,18.6,20.6,19.1z
"/>
<path style="fill:#14366B;" d="M16.1,22.9c0.3,0.2,0.5,0.4,0.6,0.8c0.2,0.4,0.2,0.8,0.2,1.2c0.3-0.3,0.6-0.6,1-0.9
c0.4-0.3,0.8-0.5,1.2-0.7c0.4-0.2,0.8-0.4,1.2-0.5c0.2-0.1,0.3-0.1,0.4-0.1c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.4,0.1-0.6
c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.3-0.8-0.6-1-0.8c0.1,0.5,0,0.9-0.2,1.2c-0.2,0.4-0.4,0.6-0.6,0.8
c-0.3,0.2-0.5,0.2-0.8,0.1c-0.3-0.1-0.5-0.3-0.7-0.8c-0.1,0.1-0.3,0.2-0.5,0.4c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.2-0.6,0.2-1,0.3
c-0.3,0-0.5-0.1-0.7-0.4c-0.5,0.1-0.9,0-1.2-0.1c0.2,0.3,0.3,0.6,0.3,1c0,0.4-0.1,0.7-0.3,1c0.4-0.2,0.8-0.2,1.3-0.2
c0.2-0.3,0.4-0.4,0.7-0.4c0.3,0,0.6,0.1,1,0.3c0.3,0.2,0.6,0.3,0.9,0.5c0.3,0.2,0.5,0.3,0.5,0.4c0.2-0.5,0.4-0.7,0.7-0.8
C15.6,22.7,15.9,22.8,16.1,22.9z"/>
<path style="fill:#14366B;" d="M33.4,21.9c0-0.3,0.1-0.7,0.3-1c-0.4,0.1-0.8,0.2-1.2,0.1c-0.2,0.3-0.4,0.4-0.7,0.4
c-0.3,0-0.6-0.1-1-0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.3-0.5-0.4c-0.2,0.5-0.4,0.7-0.7,0.8c-0.3,0.1-0.5,0-0.8-0.1
c-0.3-0.2-0.5-0.4-0.6-0.8c-0.2-0.4-0.2-0.8-0.2-1.2c-0.3,0.3-0.6,0.6-1,0.8c-0.4,0.3-0.8,0.5-1.2,0.7c-0.4,0.2-0.8,0.4-1.2,0.5
c0,0,0,0-0.1,0c0.1,0.2,0.1,0.4,0.1,0.6c0,0.5-0.2,0.9-0.5,1.3c0.1,0,0.3,0.1,0.5,0.1c0.4,0.1,0.8,0.3,1.2,0.5
c0.4,0.2,0.8,0.4,1.2,0.7c0.4,0.3,0.8,0.6,1,0.8c-0.1-0.5,0-0.9,0.2-1.2c0.2-0.4,0.4-0.6,0.6-0.8c0.3-0.2,0.5-0.2,0.8-0.1
c0.3,0.1,0.5,0.3,0.7,0.8c0.1,0,0.3-0.1,0.5-0.3c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.2,1-0.3c0.3,0,0.5,0.1,0.7,0.4
c0.5-0.1,0.9,0,1.2,0.1C33.5,22.6,33.4,22.3,33.4,21.9z"/>
</symbol>
        
        
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C10">
<title>C10</title>
<path style="fill:#14366B;" d="M24.5,22c0-1.4-1.1-2.5-2.5-2.5
c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5C23.4,24.5,24.5,23.4,24.5,22z"/>

</symbol>

<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C11">
<title>C11</title>
c-0.3,0.1-0.9,0.2-0.9,0.7c0.2,0.1,0.4,0.1,0.6,0.2c0-0.1,0-0.4,0.1-0.3c0.2,0,0.1,0.3,0.1,0.4c0.2,0,0.3,0,0.5,0
c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2,0,0.3
c0.2,0,0.3,0,0.5,0c0-0.1-0.1-0.4,0.1-0.4c0.2,0,0.1,0.2,0.1,0.3c0.2,0,0.4-0.1,0.6-0.2C23.2,25.3,22.7,25.3,22.4,25.1z"/>
<path style="display:inline;fill:#CF3C40;" d="M19.9,24c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.8-0.5-1.1-0.2c0.1,0.2,0.2,0.4,0.3,0.6
c0.1-0.1,0.2-0.3,0.3-0.2c0.1,0.1-0.1,0.3-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.1
c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.1,0.2-0.3,0.3-0.2
c0.1,0.1-0.1,0.3-0.2,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.3-0.3,0-0.8-0.2-1.1C19.9,24.4,19.9,24.2,19.9,24z"/>
<path style="display:inline;fill:#CF3C40;" d="M23.8,20c0.2,0,0.4,0,0.6,0.1c0.3,0.2,0.8,0.5,1.1,0.2c-0.1-0.2-0.2-0.4-0.3-0.6
c-0.1,0.1-0.2,0.3-0.3,0.2c-0.1-0.1,0.1-0.3,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0.1-0.2,0.3-0.3,0.2
c-0.1-0.1,0.1-0.3,0.2-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3,0.3,0,0.8,0.2,1.1C23.8,19.6,23.8,19.8,23.8,20z"/>
<path style="display:inline;fill:#CF3C40;" d="M18.7,22.5c0.1-0.2,0.2-0.4,0.3-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
c-0.1-0.3-0.2-0.9-0.7-0.9c-0.1,0.2-0.1,0.4-0.2,0.6c0.1,0,0.4,0,0.3,0.1c0,0.2-0.3,0.1-0.4,0.1c0,0.2,0,0.3,0,0.5
c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
c0,0.2,0,0.3,0,0.5c0.1,0,0.4-0.1,0.4,0.1c0,0.2-0.2,0.1-0.3,0.1c0,0.2,0.1,0.4,0.2,0.6C18.5,23.4,18.6,22.9,18.7,22.5z"/>
<path style="display:inline;fill:#CF3C40;" d="M21.3,18.9c0.2,0.1,0.4,0.2,0.5,0.3c0.1-0.2,0.3-0.3,0.5-0.3
c0.3-0.1,0.9-0.2,0.9-0.7c-0.2-0.1-0.4-0.1-0.6-0.2c0,0.1,0,0.4-0.1,0.3c-0.2,0-0.1-0.3-0.1-0.4c-0.2,0-0.3,0-0.5,0
c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.3
c-0.2,0-0.3,0-0.5,0c0,0.1,0.1,0.4-0.1,0.4c-0.2,0-0.1-0.2-0.1-0.3c-0.2,0-0.4,0.1-0.6,0.2C20.4,18.7,21,18.8,21.3,18.9z"/>
<path style="display:inline;fill:#CF3C40;" d="M19.3,20.2c0.2-0.1,0.4-0.1,0.6-0.1c0-0.2,0-0.4,0.1-0.6c0.2-0.3,0.5-0.8,0.2-1.1
c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.3,0.2,0.2,0.3c-0.1,0.1-0.3-0.1-0.3-0.2c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2
c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.4
c0.1,0.1,0.3,0.2,0.2,0.3c-0.1,0.1-0.3-0.1-0.3-0.2c-0.1,0.2-0.2,0.4-0.3,0.6C18.5,20.6,18.9,20.3,19.3,20.2z"/>
<path style="display:inline;fill:#CF3C40;" d="M25.8,21.2c0-0.2-0.1-0.4-0.2-0.6c-0.5,0-0.5,0.5-0.7,0.9c-0.1,0.2-0.2,0.4-0.3,0.5
c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0.3,0.2,0.9,0.7,0.9c0.1-0.2,0.1-0.4,0.2-0.6c-0.1,0-0.4,0-0.3-0.1c0-0.2,0.3-0.1,0.4-0.1
c0-0.2,0-0.3,0-0.5c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0.1-0.1,0.2-0.1,0.2-0.1
c0.1,0,0.2,0,0.3,0c0-0.2,0-0.3,0-0.5c-0.1,0-0.4,0.1-0.4-0.1C25.4,21.2,25.7,21.2,25.8,21.2z"/>
<path style="display:inline;fill:#CF3C40;" d="M24.4,23.8C24.2,23.9,24,24,23.8,24c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.8-0.2,1.1
c0.2-0.1,0.4-0.2,0.6-0.3c-0.1-0.1-0.3-0.2-0.2-0.3c0.1-0.1,0.3,0.1,0.3,0.2c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.4
c-0.1-0.1-0.3-0.2-0.2-0.3c0.1-0.1,0.3,0.1,0.3,0.2c0.1-0.2,0.2-0.4,0.3-0.6C25.2,23.4,24.7,23.7,24.4,23.8z"/>
<path style="display:inline;fill:#CF3C40;" d="M25,23.2c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2,0.1-0.5,0.1-0.7,0c0,0-0.1,0-0.1,0
c-0.4-0.2-0.4-0.6-0.8-0.8c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0.4-0.2,0.5-0.6,0.8-0.8c0,0,0.1,0,0.1,0
c0.2-0.1,0.5-0.1,0.7,0c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.1
c-0.1,0.2-0.2,0.5-0.5,0.6c0,0-0.1,0-0.1,0c-0.4,0.1-0.8-0.1-1.2,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0,0,0,0,0,0
c0.2-0.4-0.1-0.8,0-1.2c0,0,0-0.1,0-0.1c0.1-0.2,0.3-0.4,0.6-0.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.2
c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c0.1,0.2,0.1,0.5,0,0.7c0,0,0,0.1,0,0.1c-0.2,0.4-0.6,0.4-0.8,0.8c0,0,0,0,0,0
c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.2-0.4-0.6-0.5-0.8-0.8c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.5,0-0.7
c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0.2,0.1,0.5,0.2,0.6,0.5
c0,0,0,0.1,0,0.1c0.1,0.4-0.1,0.8,0,1.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.4-0.2-0.8,0.1-1.2,0
c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0c0.1,0.1,0.1,0.1,0.2,0.2
c0.1,0.1,0.1,0.2,0.1,0.3c0.2-0.1,0.5-0.1,0.7,0c0,0,0.1,0,0.1,0c0.4,0.2,0.4,0.6,0.8,0.8c0,0,0,0,0,0c0,0,0,0,0,0.1
c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.4,0.2-0.5,0.6-0.8,0.8c0,0-0.1,0-0.1,0c-0.2,0.1-0.5,0.1-0.7,0c0,0.1-0.1,0.2-0.1,0.3
c0,0.1-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.3,0.1c0.1-0.2,0.2-0.5,0.5-0.6c0,0,0.1,0,0.1,0c0.4-0.1,0.8,0.1,1.2,0
c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.2,0.4,0.1,0.8,0,1.2c0,0,0,0.1,0,0.1c-0.1,0.2-0.3,0.4-0.6,0.5
c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c-0.1-0.2-0.1-0.5,0-0.7
c0,0,0-0.1,0-0.1c0.2-0.4,0.6-0.4,0.8-0.8c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.2,0.4,0.6,0.5,0.8,0.8
c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.5,0,0.7c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0.1-0.3
c-0.2-0.1-0.5-0.2-0.6-0.5c0,0,0-0.1,0-0.1c-0.1-0.4,0.1-0.8,0-1.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0
c0.4,0.2,0.8-0.1,1.2,0c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0
C25.1,23.3,25.1,23.3,25,23.2z M22.7,22C22.7,22,22.7,22,22.7,22C22.7,22,22.7,22,22.7,22l0,0.1c0,0,0,0,0,0
c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.2,0.1-0.2,0.1c0,0,0,0,0,0l0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0c0,0,0,0,0,0
c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0l-0.1,0c0,0,0,0,0,0s0,0,0,0l-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1
c-0.1,0-0.1-0.2-0.1-0.2c0,0,0,0,0,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.2,0.1-0.3
c0,0,0,0,0,0l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1c0,0,0,0,0,0l0-0.1
c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0s0,0,0,0
l0.1,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.2,0.1,0.2c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0.1c0,0,0,0,0,0
c0.1,0,0.2,0,0.2,0.1C22.8,21.7,22.8,21.8,22.7,22C22.7,21.9,22.7,21.9,22.7,22L22.7,22z"/>

</symbol>


<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C12">
<title>C12</title>
<path style="fill:#C04947;" d="M22.7,26.5c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2,0.2-0.4,0.4-0.7,0.5c-0.5,0.2-1.3,0.3-1.3,1
c0.3,0.1,0.6,0.2,0.9,0.2c0-0.2-0.1-0.5,0.2-0.5c0.2,0,0.2,0.4,0.1,0.6c0.2,0,0.4,0,0.7,0.1c0-0.2,0-0.3-0.1-0.5
c0-0.1,0-0.3,0.1-0.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.5c0.2,0,0.4,0,0.7-0.1
c0-0.2-0.1-0.5,0.1-0.6c0.2,0,0.2,0.3,0.2,0.5c0.3-0.1,0.6-0.1,0.9-0.2C23.9,26.8,23.2,26.7,22.7,26.5z"/>
<path style="fill:#C04947;" d="M19.1,24.9c-0.3,0-0.6-0.1-0.9-0.2c-0.5-0.2-1.1-0.7-1.6-0.2c0.1,0.3,0.3,0.6,0.5,0.8
c0.1-0.1,0.3-0.4,0.5-0.2c0.2,0.2-0.1,0.4-0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.2
c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c0.2,0.2,0.3,0.3,0.5,0.4
c0.1-0.2,0.3-0.5,0.5-0.3c0.2,0.2-0.1,0.4-0.2,0.5c0.3,0.2,0.5,0.3,0.8,0.5c0.4-0.5,0-1.1-0.2-1.6C19.1,25.4,19.1,25.2,19.1,24.9
z"/>
<path style="fill:#C04947;" d="M24.8,19.2c0.3,0,0.6,0.1,0.9,0.2c0.5,0.2,1.1,0.7,1.6,0.2c-0.1-0.3-0.3-0.6-0.5-0.8
c-0.1,0.1-0.3,0.4-0.5,0.2c-0.2-0.2,0.1-0.4,0.3-0.5c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.2,0.3-0.3,0.4
c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.2-0.2,0.4-0.3
c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1,0.2-0.3,0.5-0.5,0.3c-0.2-0.2,0.1-0.4,0.2-0.5c-0.3-0.2-0.5-0.3-0.8-0.5c-0.4,0.5,0,1.1,0.2,1.6
C24.7,18.6,24.8,18.9,24.8,19.2z"/>
<path style="fill:#C04947;" d="M17.4,22.8c0.1-0.3,0.3-0.5,0.5-0.7c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.3-1.3-1-1.3
c-0.1,0.3-0.2,0.6-0.2,0.9c0.2,0,0.5-0.1,0.5,0.2c0,0.2-0.4,0.2-0.6,0.1c0,0.2,0,0.4-0.1,0.7c0.2,0,0.3,0,0.5-0.1
c0.1,0,0.3,0,0.4,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.5-0.1c0,0.2,0,0.4,0.1,0.7
c0.2,0,0.5-0.1,0.6,0.1c0,0.2-0.3,0.2-0.5,0.2c0.1,0.3,0.1,0.6,0.2,0.9C17.1,24,17.2,23.3,17.4,22.8z"/>
<path style="fill:#C04947;" d="M21.2,17.5c0.3,0.1,0.5,0.3,0.7,0.5c0.2-0.2,0.4-0.4,0.7-0.5c0.5-0.2,1.3-0.3,1.3-1
c-0.3-0.1-0.6-0.2-0.9-0.2c0,0.2,0.1,0.5-0.2,0.5c-0.2,0-0.2-0.4-0.1-0.6c-0.2,0-0.4,0-0.7-0.1c0,0.2,0,0.3,0.1,0.5
c0,0.1,0,0.3-0.1,0.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.5c-0.2,0-0.4,0-0.7,0.1
c0,0.2,0.1,0.5-0.1,0.6c-0.2,0-0.2-0.3-0.2-0.5c-0.3,0.1-0.6,0.1-0.9,0.2C19.9,17.2,20.7,17.3,21.2,17.5z"/>
<path style="fill:#C04947;" d="M18.2,19.4c0.3-0.1,0.6-0.2,0.9-0.2c0-0.3,0.1-0.6,0.2-0.9c0.2-0.5,0.7-1.1,0.2-1.6
c-0.3,0.1-0.6,0.3-0.8,0.5c0.1,0.1,0.4,0.3,0.2,0.5c-0.2,0.2-0.4-0.1-0.5-0.3c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.3
c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.4
c-0.2,0.2-0.3,0.3-0.4,0.5c0.2,0.1,0.5,0.3,0.3,0.5c-0.2,0.2-0.4-0.1-0.5-0.2c-0.2,0.3-0.3,0.5-0.5,0.8
C17.1,20,17.7,19.6,18.2,19.4z"/>
<path style="fill:#C04947;" d="M27.6,20.9c-0.1-0.3-0.1-0.6-0.2-0.9c-0.7,0-0.8,0.8-1,1.3c-0.1,0.3-0.3,0.5-0.5,0.7
c0.2,0.2,0.4,0.4,0.5,0.7c0.2,0.5,0.3,1.3,1,1.3c0.1-0.3,0.2-0.6,0.2-0.9c-0.2,0-0.5,0.1-0.5-0.2c0-0.2,0.4-0.2,0.6-0.1
c0-0.2,0-0.4,0.1-0.7c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.3,0-0.4-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.4-0.1
c0.2,0,0.3,0,0.5,0.1c0-0.2,0-0.4-0.1-0.7c-0.2,0-0.5,0.1-0.6-0.1C27.1,20.8,27.5,20.9,27.6,20.9z"/>
<path style="fill:#C04947;" d="M25.6,24.7c-0.3,0.1-0.6,0.2-0.9,0.2c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.5-0.7,1.1-0.2,1.6
c0.3-0.1,0.6-0.3,0.8-0.5c-0.1-0.1-0.4-0.3-0.2-0.5c0.2-0.2,0.4,0.1,0.5,0.3c0.2-0.1,0.3-0.3,0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.4
c0.2-0.2,0.3-0.3,0.4-0.5c-0.2-0.1-0.5-0.3-0.3-0.5c0.2-0.2,0.4,0.1,0.5,0.2c0.2-0.3,0.3-0.5,0.5-0.8
C26.8,24,26.1,24.5,25.6,24.7z"/>
<path style="fill:#C04947;" d="M26.5,23.7c-0.1-0.1-0.1-0.3-0.2-0.5c-0.3,0.2-0.7,0.2-1.1,0.1c0,0-0.1,0-0.1-0.1
c-0.5-0.3-0.6-0.9-1.2-1.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.6-0.2,0.7-0.9,1.2-1.2c0,0,0.1,0,0.1-0.1
c0.3-0.1,0.7-0.1,1.1,0.1c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3-0.1-0.5-0.2
c-0.1,0.4-0.3,0.7-0.7,0.8c0,0-0.1,0-0.1,0c-0.6,0.2-1.1-0.2-1.7,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
c0.2-0.6-0.1-1.1,0-1.7c0,0,0-0.1,0-0.1c0.1-0.3,0.4-0.6,0.8-0.7c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.2,0-0.4
c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.1-0.5,0.2c0.2,0.3,0.2,0.7,0.1,1.1c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.9,0.6-1.2,1.2
c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.2-0.6-0.9-0.7-1.2-1.2c0,0,0-0.1-0.1-0.1c-0.1-0.3-0.1-0.7,0.1-1.1
c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1,0,0.2,0,0.4c0,0.2-0.1,0.3-0.2,0.5c0.4,0.1,0.7,0.3,0.8,0.7
c0,0,0,0.1,0,0.1c0.2,0.6-0.2,1.1,0,1.7c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.6-0.2-1.1,0.1-1.7,0
c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.4-0.7-0.8c-0.1,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0-0.4,0c0.1,0.1,0.2,0.2,0.2,0.3
c0.1,0.1,0.1,0.3,0.2,0.5c0.3-0.2,0.7-0.2,1.1-0.1c0,0,0.1,0,0.1,0.1c0.5,0.3,0.6,0.9,1.2,1.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
s0,0.1,0,0.1c0,0,0,0,0,0c-0.6,0.2-0.7,0.9-1.2,1.2c0,0-0.1,0-0.1,0.1c-0.3,0.1-0.7,0.1-1.1-0.1c-0.1,0.2-0.1,0.3-0.2,0.5
c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0.1,0.5,0.2c0.1-0.4,0.3-0.7,0.7-0.8c0,0,0.1,0,0.1,0
c0.6-0.2,1.1,0.2,1.7,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.2,0.6,0.1,1.1,0,1.7c0,0,0,0.1,0,0.1
c-0.1,0.3-0.4,0.6-0.8,0.7c0.1,0.1,0.1,0.3,0.2,0.5c0,0.1,0,0.2,0,0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.5-0.2
c-0.2-0.3-0.2-0.7-0.1-1.1c0,0,0-0.1,0.1-0.1c0.3-0.5,0.9-0.6,1.2-1.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
c0.2,0.6,0.9,0.7,1.2,1.2c0,0,0,0.1,0.1,0.1c0.1,0.3,0.1,0.7-0.1,1.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0.2
c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.3,0.2-0.5c-0.4-0.1-0.7-0.3-0.8-0.7c0,0,0-0.1,0-0.1c-0.2-0.6,0.2-1.1,0-1.7c0,0,0,0,0,0
c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.6,0.2,1.1-0.1,1.7,0c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.4,0.7,0.8
c0.1-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.4,0C26.7,23.9,26.6,23.9,26.5,23.7z M23.2,22C23.2,22,23.2,22,23.2,22
C23.2,22,23.2,22.1,23.2,22l0,0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c0,0,0,0,0,0l-0.1,0.1
c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.4-0.1c0,0,0,0,0,0l-0.1,0c0,0,0,0,0,0
s0,0,0,0l-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0,0,0,0,0,0l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
l-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.3,0-0.4-0.2c-0.1-0.1,0-0.3,0.1-0.4c0,0,0,0,0,0l0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1
c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0,0,0,0l0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.1
c0,0,0,0,0,0c0-0.1,0-0.3,0.2-0.4c0.1-0.1,0.3,0,0.4,0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0s0,0,0,0l0.1,0c0,0,0,0,0,0
c0.1-0.1,0.2-0.2,0.4-0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0,0,0l0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1c0,0,0,0,0,0
c0.1,0,0.3,0,0.4,0.2c0.1,0.1,0,0.3-0.1,0.4C23.2,21.9,23.2,21.9,23.2,22L23.2,22z"/>

</symbol>

        

        
        
        
        </svg> `,



        'symbols': {
            'Z': 3,
            'A': 12,
            'B': 12,
            'C': 12
        },
    
    },


    {
        'id': 3,
        'svg': `<svg width="0" height="0" class="hidden">
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z1">
            <title>Z1</title>
            <path fill="#d7e8cd" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z3">
            <title>Z3</title>
            <path fill="#8de0b8" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z2">
            <title>Z2</title>
            <path fill="#92c193" d="M.07-.21h44v44h-44z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="Z2">
        <title>Z2</title>
        <path fill="#FFFFFF" d="M.07-.21h44v44h-44z"></path>
    </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="selection-color">
            <path fill="#665c54" d="M0 0h44v44H0z"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="icon">
            <title>icon</title>
            <path fill="#d7e8cd" d="M0 0h44v44H0z"></path>
            <path d="M12 0h20v12h-5.86L22 7.86 17.86 12H12zm0 32v-5.86L7.86 22 12 17.86V12H0v20zm20 0v-5.86L36.14 22 32 17.86V12h12v20zm0 0h-5.86L22 36.14 17.86 32H12v12h20z" fill="#5bb56f"></path>
            <path d="M22 43.87l-1.2-1.58c-.23-.31-.5-.65-.79-1a12.72 12.72 0 0 0-4.45-3.73 12.71 12.71 0 0 0-5.79-.51c-.46 0-.88.09-1.27.14l-2 .27.27-2c.05-.39.1-.82.14-1.27a12.71 12.71 0 0 0-.51-5.79A12.72 12.72 0 0 0 2.71 24c-.35-.29-.69-.56-1-.79L.13 22l1.58-1.2c.31-.23.65-.5 1-.79a12.72 12.72 0 0 0 3.73-4.45A12.71 12.71 0 0 0 7 9.77c0-.45-.09-.88-.14-1.27l-.27-2 2 .27L9.77 7a12.71 12.71 0 0 0 5.79-.51A12.72 12.72 0 0 0 20 2.71c.29-.35.56-.69.79-1L22 .13l1.2 1.58c.23.31.5.65.79 1a12.72 12.72 0 0 0 4.45 3.73 12.71 12.71 0 0 0 5.79.56l1.27-.14 2-.27-.27 2c0 .39-.1.82-.14 1.27a12.71 12.71 0 0 0 .51 5.79A12.72 12.72 0 0 0 41.29 20c.35.29.69.56 1 .79L43.87 22l-1.58 1.2c-.31.23-.65.5-1 .79a12.72 12.72 0 0 0-3.73 4.45 12.71 12.71 0 0 0-.51 5.79c0 .45.09.88.14 1.27l.27 2-2-.27c-.39 0-.81-.1-1.27-.14a12.71 12.71 0 0 0-5.79.51 12.72 12.72 0 0 0-4.4 3.69c-.29.35-.56.69-.79 1zM11.72 34a13 13 0 0 1 5 .84A14.92 14.92 0 0 1 22 39a14.92 14.92 0 0 1 5.29-4.21A14.88 14.88 0 0 1 34 34a14.88 14.88 0 0 1 .77-6.73A14.92 14.92 0 0 1 39 22a14.92 14.92 0 0 1-4.21-5.29A14.88 14.88 0 0 1 34 10a14.88 14.88 0 0 1-6.73-.77A14.92 14.92 0 0 1 22 5a14.92 14.92 0 0 1-5.29 4.21A14.88 14.88 0 0 1 10 10a14.88 14.88 0 0 1-.77 6.73A14.92 14.92 0 0 1 5 22a14.92 14.92 0 0 1 4.21 5.29A14.88 14.88 0 0 1 10 34h1.72z" fill="#195f28"></path>
            <path d="M18.14 15.57c0-1.42.87-2.57 1.93-2.57S22 14.15 22 15.57c0-1.42.86-2.57 1.93-2.57s1.93.64 1.93 2.57a6.86 6.86 0 0 1-1.36 3.93 6.86 6.86 0 0 1 3.93-1.36c1.42 0 2.57.87 2.57 1.93S29.85 22 28.43 22c1.42 0 2.57.86 2.57 1.93s-.64 1.93-2.57 1.93a6.86 6.86 0 0 1-3.93-1.36 6.86 6.86 0 0 1 1.36 3.93c0 1.42-.87 2.57-1.93 2.57S22 29.85 22 28.43c0 1.42-.86 2.57-1.93 2.57s-1.93-.64-1.93-2.57a6.86 6.86 0 0 1 1.36-3.93 6.86 6.86 0 0 1-3.93 1.36c-1.42 0-2.57-.86-2.57-1.93S14.15 22 15.57 22c-1.42 0-2.57-.86-2.57-1.93s.64-1.93 2.57-1.93a6.86 6.86 0 0 1 3.93 1.36 6.86 6.86 0 0 1-1.36-3.93z" fill="#ef2e7f" fill-rule="evenodd"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="font-color">
            <path fill="#665c54" d="M0 0h44v44H0z"></path>
        </symbol>






        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C12">
        <title>C12</title>
        <path  style="display:inline;fill:#293FC5;" d="M26.1,22.2l4.5-1
		L26,20.5l3.8-2.7l-4.5,1.1l2.5-3.9L24,17.8l0.8-4.6l-2.4,4l-1-4.5l-0.7,4.6l-2.7-4l1.1,4.5l-3.9-2.5l2.8,3.8l-4.6-0.8l4,2.4l-4.5,1
		l4.6,0.7l-3.8,2.7l4.5-1.1l-2.5,3.9l3.8-2.8l-0.8,4.6l2.4-4l1,4.5l0.7-4.6l2.7,3.8l-1.1-4.5l3.9,2.5l-2.8-3.8l4.6,0.8L26.1,22.2z
		 M21.7,24.3c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8C24.5,23,23.2,24.3,21.7,24.3z"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C11">
        <title>C11</title>
        <path  style="display:inline;fill:#293FC5;" d="M26.8,18.1l-3.1-2.3
		h-3.9l-3.1,2.3l-1.2,3.7l1.2,3.7l3.1,2.3h3.9l3.1-2.3l1.2-3.7L26.8,18.1z M25.5,23.9l-2.2-0.6l0.6,2.2l-1.6-1.7l-0.6,2.3l-0.6-2.3
		l-1.6,1.7l0.6-2.2l-2.2,0.6l1.7-1.6l-2.3-0.6l2.3-0.6l-1.7-1.6l2.2,0.6L19.5,18l1.6,1.7l0.6-2.3l0.6,2.3l1.6-1.7l-0.6,2.2l2.2-0.6
		l-1.7,1.6l2.3,0.6l-2.3,0.6L25.5,23.9z"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C10">
        <title>C10</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M21.7,18.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S22.9,18.8,21.7,18.8z"/>
		<path style="fill:#293FC5;" d="M32.8,20.9c0,0-1.1-4.8-7.6-3.6V17c0,0,1.1-4.9-3.5-7.3l0,0l0,0l0,0l0,0c-0.1,0-4.8,1.2-3.6,7.6
			h-0.3c0,0-4.9-1.1-7.3,3.5l0,0l0,0l0,0l0,0c0,0.1,1.2,4.8,7.6,3.6v0.3c0,0-1.1,4.9,3.5,7.3v-0.1V32c0,0,4.8-1.1,3.6-7.6h0.3
			C25.5,24.4,30.4,25.5,32.8,20.9L32.8,20.9L32.8,20.9z M27.3,21.3h-2.9c-0.1,1.3-1.1,2.3-2.4,2.5v2.8h-0.5v-2.8
			c-1.3-0.1-2.3-1.2-2.4-2.5h-3v-0.5h3c0.1-1.3,1.2-2.3,2.5-2.4v-3.3H22v3.3l0,0c1.3,0.1,2.3,1.2,2.4,2.4h2.9V21.3z"/>
	</g>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C9">
        <title>C9</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M30.3,16v2.8h-5.7v-5.5h3.1v-2.5H16.1v2.5h2.8v5.5h-6.1V16h-2.5v11.6h2.5v-2.9h6.1V31h-2.8v2.5
			h11.6V31h-3.1v-6.2h5.7v2.9h2.5V16H30.3z M25.9,23.8h-2.7v2.7h-3.4v-2.7h-2.7v-3.4h2.7v-2.7h3.4v2.7h2.7V23.8z"/>
		<polygon style="fill:#293FC5;" points="24.6,21.5 22.3,21.5 22.3,18.9 20.7,18.9 20.7,21.5 18.3,21.5 18.3,22.9 20.7,22.9 
			20.7,25.4 22.3,25.4 22.3,22.9 24.6,22.9 		"/>
	</g>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C8">
        <title>C8</title>
        <g style="display:inline;">
		<path style="fill:#293FC5;" d="M21.9,10.3c-6.2,0-11.1,5-11.1,11.1c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1
			C33,15.3,28,10.3,21.9,10.3z M21.9,30.9c-5.2,0-9.5-4.2-9.5-9.5c0-5.2,4.2-9.5,9.5-9.5s9.5,4.2,9.5,9.5
			C31.4,26.6,27.1,30.9,21.9,30.9z"/>
		<path style="fill:#293FC5;" d="M21.9,13c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5S26.6,13,21.9,13z
			 M22.1,25.4c-2.3,0-4.1-1.9-4.1-4.1s1.9-4.1,4.1-4.1c2.3,0,4.1,1.9,4.1,4.1S24.4,25.4,22.1,25.4z"/>
	</g>
        </symbol>



        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C7">
        <title>C7</title>
    	<g style="display:inline;">
		<path  style="fill:#293FC5;" d="M21.8,10.5c-6.2,0-11.1,5-11.1,11.1
			s5,11.1,11.1,11.1s11.1-5,11.1-11.1S27.9,10.5,21.8,10.5z M27.5,27.3l-3.4-0.1l-2.3,2.5l-2.3-2.5l-3.4,0.1l0.1-3.4l-2.5-2.3
			l2.5-2.3L16,15.9l3.4,0.1l2.3-2.5L24,16l3.4-0.1l-0.1,3.4l2.5,2.3l-2.4,2.3L27.5,27.3z"/>
		<polygon  style="fill:#293FC5;" points="21.8,14.8 23.7,16.9 
			26.5,16.8 26.5,19.7 28.5,21.6 26.5,23.6 26.5,26.4 23.7,26.3 21.8,28.4 19.8,26.3 17,26.4 17.1,23.6 15,21.6 17.1,19.7 17,16.8 
			19.8,16.9 		"/>
	</g>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C6">
        <title>C6</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M28.7,17.3l-1.4-2l-2-1.4L23,13.2h-2.4l-2.3,0.8l-2,1.4l-1.4,2l-0.8,2.3v2.4l0.8,2.3l1.4,2l2,1.4
			l2.3,0.8H23l2.3-0.8l2-1.4l1.4-2l0.8-2.3v-2.4L28.7,17.3z M28.5,22l-0.7,2l-1.3,1.7l-1.7,1.2l-2,0.6l-2.2,0l-2-0.7L17,25.6
			l-1.2-1.7l-0.6-2l0-2.1l0.7-2l1.3-1.7l1.7-1.2l2-0.6l2.1,0l2,0.7l1.7,1.3l1.2,1.7l0.6,2L28.5,22z"/>
		<path style="fill:#293FC5;" d="M27.3,18l-1.1-1.6l-1.6-1.1l-1.8-0.6h-1.9L19,15.3l-1.6,1.1L16.3,18l-0.6,1.8v1.9l0.6,1.8l1.1,1.6
			l1.6,1.1l1.8,0.6h1.9l1.8-0.6l1.6-1.1l1.1-1.6l0.6-1.8v-1.9L27.3,18z M25.2,22l-1.8-0.3l1.3,1.3l-1.6-0.8l0.8,1.6l-1.3-1.3
			l0.3,1.8l-0.8-1.6l-0.3,1.8l-0.3-1.8l-0.8,1.6l0.3-1.8l-1.3,1.3l0.8-1.6L18.9,23l1.3-1.3L18.4,22l1.6-0.8l-1.8-0.3l1.8-0.3
			l-1.6-0.8l1.8,0.3l-1.3-1.3l1.6,0.8L19.7,18l1.3,1.3l-0.3-1.8l0.8,1.6l0.3-1.8l0.3,1.8l0.8-1.6l-0.3,1.8l1.3-1.3l-0.8,1.6l1.6-0.8
			L23.4,20l1.8-0.3l-1.6,0.8l1.8,0.3l-1.8,0.3L25.2,22z"/>
	</g>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C5">
        <title>C5</title>
    	<g  style="display:inline;">
		<path style="fill:#293FC5;" d="M21.7,23.2c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S23.1,23.2,21.7,23.2z"/>
		<circle style="fill:#293FC5;" cx="17.9" cy="21.5" r="2.5"/>
		<path style="fill:#293FC5;" d="M21.7,22.7c0.5,0,0.9,0.1,1.3,0.3c-0.3-0.5-0.5-1-0.5-1.6c0-0.5,0.1-0.9,0.3-1.3
			c-0.3,0.1-0.7,0.2-1.1,0.2c-0.5,0-0.9-0.1-1.3-0.3c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.4C20.9,22.8,21.3,22.7,21.7,22.7z"
			/>
		<circle style="fill:#293FC5;" cx="21.8" cy="17.4" r="2.5"/>
		<path style="fill:#293FC5;" d="M26.4,17.3c-0.2,0-0.5,0-0.7,0.1c0-0.2,0.1-0.4,0.1-0.6c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1
			c0,0.2,0,0.4,0.1,0.6c-0.2,0-0.4-0.1-0.5-0.1c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1c0.2,0,0.4,0,0.6-0.1
			c-0.1,0.3-0.1,0.6-0.1,0.8c0,2.3,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1c0-0.3,0-0.6-0.1-0.9c0.2,0,0.5,0.1,0.7,0.1
			c2.3,0,4.1-1.8,4.1-4.1S28.7,17.3,26.4,17.3z M25.6,24.5c-0.5,0-0.9-0.1-1.3-0.3c0.3,0.5,0.5,1,0.5,1.6c0,1.7-1.3,3-3,3
			s-3-1.3-3-3c0-0.5,0.1-1,0.4-1.4c-0.4,0.2-0.8,0.2-1.2,0.2c-1.7,0-3-1.3-3-3s1.3-3,3-3c0.5,0,0.9,0.1,1.3,0.3
			c-0.3-0.4-0.4-0.9-0.4-1.5c0-1.7,1.3-3,3-3s3,1.3,3,3c0,0.5-0.1,0.9-0.3,1.3c0.3-0.1,0.7-0.2,1.1-0.2c1.7,0,3,1.3,3,3
			C28.6,23.1,27.3,24.5,25.6,24.5z"/>
		<path style="fill:#293FC5;" d="M25.6,19c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S27,19,25.6,19z"/>
	</g>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C4">
        <title>C4</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M30,18.3l-4.9-4.9V8.5h-6.4v5.1
			c-1,1-3.2,3.2-4.8,4.7h-5v7.2h4.7l5.2,6.4v3.3h6.4V31l5.6-5.6h4.3v-7.2L30,18.3L30,18.3z M27.3,24.3l-2.9,2.9l-2.5-2.4l-2.4,2.4
			l-2.9-2.9l2.4-2.4l-2.4-2.4l2.9-2.9l2.4,2.4l2.4-2.4l2.9,2.9l-2.4,2.4L27.3,24.3z"/>
		<polygon  style="fill:#293FC5;" points="26,19.4 24.5,17.9 21.9,20.4 
			19.4,17.9 17.9,19.4 20.4,21.9 17.9,24.5 19.4,26 21.9,23.4 24.5,26 26,24.5 23.4,21.9 		"/>
	</g>
	
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C3">
        <title>C3</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M25.6,18.1l-4.2-9.5l-4.3,9.8l-8.8,3.7l9.2,4.3l3.9,8.9l4.3-9l9.3-4.1L25.6,18.1z M25.3,25.3h-7.2
			v-6.8h7.2V25.3z"/>
		<g>
			<rect x="19.8" y="20" style="fill:#293FC5;" width="3.9" height="4"/>
			<g>
				<g>
					<polygon style="fill:#293FC5;" points="26.7,16.8 28.1,12.9 32.1,11.6 30.7,15.5 					"/>
				</g>
				<polygon style="fill:#293FC5;" points="16.6,16.9 15.2,13 11.1,11.7 12.6,15.6 				"/>
			</g>
			<g>
				<polygon style="fill:#293FC5;" points="16.3,27.2 14.9,31.1 10.8,32.4 12.3,28.5 				"/>
				<polygon style="fill:#293FC5;" points="26.4,27.1 27.8,31 31.8,32.4 30.4,28.5 				"/>
			</g>
		</g>
	</g>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C2">
        <title>C2</title>
        <g  style="display:inline;">
		<path style="fill:#293FC5;" d="M21.8,9.7C18.3,15.4,9.2,22,9.2,22c5.1,3.5,12.6,12.8,12.6,12.8C27.7,26.6,35,22,35,22
			C29.1,18.9,21.8,9.7,21.8,9.7z M24.4,23v5.1h-5.1V23h-4.6v-1h4.6v-5.1h5.1V22h4.4v1H24.4z"/>
		<rect x="20.7" y="18.5" style="fill:#293FC5;" width="2.3" height="8"/>
	</g>
        </symbol>
    
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="C1">
        <title>C1</title>
      
        <g  style="display:inline;">
		<path  style="fill:#293FC5;" d="M28.9,19.4L24.5,15v-2.9h-5.3v3.1c-0.2,0-4.2,4.2-4.2,4.2h-2.5v4.9h2.4l4.6,4.6v2.4h5.1
			v-2.7l4.4-4.4h2.4v-4.9L28.9,19.4L28.9,19.4z M25.6,24l-1.7,1.7l-2.1-2.1l-2.1,2.1L17.9,24l2.1-2.1l-2.2-2.2l1.7-1.7l2.2,2.2
			l2.2-2.2l1.7,1.7l-2.2,2.2L25.6,24z"/>
		<polygon style="fill:#293FC5;" points="24.7,24 22.5,21.8 24.6,19.7 23.9,18.9 21.7,21.1 19.6,18.9 18.8,19.7 21,21.8 
			18.8,24 19.5,24.8 21.7,22.6 23.9,24.8 		"/>
	</g>
        </symbol>



        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B12">
        <title>B12</title>
    	<polygon  style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		29.9,31.5 26,33.7 21.6,34.4 17.2,33.6 13.4,31.4 10.5,27.9 9,23.7 9.1,19.3 10.6,15.1 13.5,11.7 17.4,9.5 21.8,8.8 26.2,9.6 
		30,11.8 32.9,15.3 34.4,19.5 34.3,23.9 32.8,28.1 	"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B11">
        <title>B11</title>
     
		<polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		26.6,7.7 17.4,7.7 10.1,13.1 7.2,21.8 10.1,30.4 17.4,35.8 26.6,35.8 33.9,30.4 36.8,21.8 33.9,13.1 	"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B10">
        <title>B10</title>
		<path style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="M37,20.9c0,0-1.5-6.5-10.3-4.9v-0.4
			c0,0,1.5-6.7-4.8-9.9l0,0l0,0l0,0l0,0c-0.1,0-6.5,1.6-4.9,10.3h-0.4c0,0-6.7-1.5-9.9,4.8l0,0l0,0l0,0l0,0c0,0.1,1.6,6.5,10.3,4.9
			v0.4c0,0-1.5,6.7,4.8,9.9l0,0l0,0c0,0,6.5-1.5,4.9-10.3H27C27,25.7,33.7,27.2,37,20.9L37,20.9L37,20.9z"/>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B9">
        <title>B9</title>
    
		<polygon  style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		31.5,12.1 31.5,4.9 12.1,4.9 12.1,12.1 5.6,12.1 5.6,31.5 12.1,31.5 12.1,37.6 31.4,37.6 31.4,31.5 38.6,31.5 38.6,12.1 	"/>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B8">
        <title>B8</title>
        <path style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="
		M38.6,12.1h-3.7c0,0,0-0.1,0-0.1c0-1.9-1.5-3.5-3.4-3.5V4.9H12.1v3.5c-0.1,0-0.2,0-0.3,0c-2,0-3.5,1.6-3.5,3.5c0,0,0,0.1,0,0.1H5.6
		v19.4h3c0,0,0,0,0,0c0,1.9,1.6,3.5,3.5,3.5v2.6h19.3V35c0.1,0,0.2,0,0.3,0c2,0,3.5-1.6,3.5-3.5c0,0,0,0,0,0h3.4V12.1z"/>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B7">
        <title>B7</title>
        <path  style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="
		M36.9,13.8c-4.1,0-7.5-3.3-7.5-7.5c0-0.6,0.1-1.2,0.2-1.8H14.2c0.1,0.6,0.2,1.2,0.2,1.8c0,4.1-3.3,7.5-7.5,7.5
		c-0.5,0-0.9,0-1.4-0.1v15.9c0.5-0.1,0.9-0.1,1.4-0.1c4.1,0,7.5,3.3,7.5,7.5c0,0.2,0,0.4,0,0.6h15.1c0-0.2,0-0.4,0-0.6
		c0-4.1,3.3-7.5,7.5-7.5c0.6,0,1.1,0.1,1.6,0.2v-16C38,13.7,37.4,13.8,36.9,13.8z"/>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B6">
        <title>B6</title>
        <polygon  style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		14.8,7.6 29.1,7.6 36.2,14.7 36.2,28.7 29.2,35.7 14.5,35.7 7.8,29 7.8,14.5 	"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B5">
        <title>B5</title>
        <path style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="
		M30.1,14c-0.2,0-0.5,0-0.7,0c0-0.1,0-0.2,0-0.3c0-4.1-3.3-7.5-7.5-7.5c-4.1,0-7.5,3.3-7.5,7.5c0,0.1,0,0.1,0,0.2
		c-0.2,0-0.5,0-0.7,0c-4.1,0-7.5,3.3-7.5,7.5s3.3,7.5,7.5,7.5c0.2,0,0.4,0,0.6,0c0,0.3-0.1,0.6-0.1,0.9c0,4.1,3.3,7.5,7.5,7.5
		s7.5-3.3,7.5-7.5c0-0.3,0-0.5,0-0.8c0.3,0,0.6,0.1,0.9,0.1c4.1,0,7.5-3.3,7.5-7.5S34.2,14,30.1,14z"/>
        </symbol>


        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B4">
        <title>B4</title>
        <circle  style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" cx="22.1" cy="21.8" r="16.9"/>
        </symbol>

        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B3">
        <title>B3</title>
        <g>
        <polygon  style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		14.3,14.2 7.6,10.9 7.6,31.6 14.5,29.2 12.1,36.2 32,36.2 29.5,29.3 36.4,31.4 36.4,11.8 29.3,14.6 31.6,7.4 13,7.4 	"/>
    </g>
        </symbol>






        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B2">
        <title>B2</title>
  
		<polygon style="display:inline;fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="
		7.6,24.6 7.6,14.2 10.4,11.8 14.3,14.2 12.8,9.7 14.3,7.6 29.4,7.6 31.1,9.2 29.4,14.3 34.3,12.2 36.4,14.3 36.4,24 36.4,24.4 
		36.4,29.5 33.4,32 29.6,29.6 31.1,34.1 29.6,36.2 14.5,36.2 12.8,34.6 14.5,29.5 9.5,31.6 7.6,29.5 7.6,24.9 	"/>
        </symbol>




        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="B1">
        <title>B1</title>
        <g>
        <path style="fill:#293FC5;" d="M29.5,36.2h-15v-7H7.6v-15h6.7V7.6h15.1v6.7h7v15h-6.9C29.5,29.3,29.5,36.2,29.5,36.2z M15,35.7
            h13.9v-6.9h6.9V14.9h-7V8.1h-14v6.7H8.1v13.9H15V35.7z"/>
    </g>
        </symbol>











        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A9">
<title>A9</title>
<g  style="display:inline;">
		<g>
			<rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
		</g>
		<g>
			<rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
		</g>
		<g>
			<rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
		</g>
		<g>
			<rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
		</g>
		<g>
			<g>
				
					<rect x="32.4" y="4.1" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="9.7" height="2.7"/>
				
					<rect x="37.8" y="1.4" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="2.6" height="10.7"/>
			</g>
			<g>
				
					<rect x="1.4" y="4" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="9.7" height="2.7"/>
				
					<rect x="3" y="1.3" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="2.6" height="10.7"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="1.3" y="37.2" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="9.7" height="2.7"/>
				
					<rect x="3.1" y="32" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="2.6" height="10.7"/>
			</g>
			<g>
				
					<rect x="32.4" y="37.3" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="9.7" height="2.7"/>
				
					<rect x="37.8" y="31.9" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" width="2.6" height="10.7"/>
			</g>
		</g>
	</g>
</symbol>



        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A8">
<title>A8</title>
<g  style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <g>
        <polyline style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" points="42.9,6 
            40.7,3.8 35.5,9 38.2,11.7 40.6,9.3 34.1,2.8 31.6,5.3 34.3,8 39.6,2.7 37.3,0.4 				"/>
        <polyline style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" points="0.5,6 
            2.8,3.8 8,9 5.3,11.7 2.8,9.3 9.4,2.8 11.9,5.3 9.2,8 3.9,2.7 6.2,0.4 				"/>
    </g>
    <g>
        <polyline style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" points="0.8,37.3 
            3,39.6 8.3,34.3 5.6,31.6 3.1,34.1 9.6,40.6 12.1,38.1 9.4,35.4 4.1,40.7 6.4,43 				"/>
        <polyline style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;" points="43.2,37.3 
            41,39.6 35.7,34.3 38.4,31.6 40.9,34.1 34.4,40.6 31.9,38.1 34.6,35.4 39.9,40.7 37.6,43 				"/>
    </g>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
</g>
</symbol>

    
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A7">
<title>A7</title>
<g  style="display:inline;">
<g>
    <rect x="15.3" y="1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="42" y="15.9" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.4" y="16.1" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <rect x="15.1" y="41.8" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.4,1 1.4,13.5 6.9,7.9 4.1,3.5 
        8.5,6.3 10.6,4.2 13.8,1 			"/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.4,1 42.4,13.5 36.9,7.9 39.7,3.5 
        35.3,6.3 33.2,4.2 30,1 			"/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.4,42.4 1.4,29.9 6.9,35.5 4,39.9 
        8.4,37.1 10.5,39.2 13.7,42.4 			"/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.3,42.4 42.3,29.9 36.8,35.5 
        39.7,39.9 35.3,37.1 33.2,39.2 30,42.4 			"/>
</g>
</g>
</symbol>
      
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A6">
<title>A6</title>
<g style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
<g>
    <g>
        <g>
            <rect x="32.3" y="1.1" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="10.1" height="10.1"/>
            <rect x="35.7" y="4.6" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="3.2" height="3.2"/>
        </g>
        <g>
            <rect x="1.3" y="1.1" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="10.1" height="10.1"/>
            <rect x="4.8" y="4.6" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="3.2" height="3.2"/>
        </g>
    </g>
    <g>
        <g>
            
                <rect x="32.2" y="32.2" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="10.1" height="10.1"/>
            <rect x="35.7" y="35.7" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="3.2" height="3.2"/>
        </g>
        <g>
            <rect x="1.3" y="32.2" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="10.1" height="10.1"/>
            <rect x="4.7" y="35.7" style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" width="3.2" height="3.2"/>
        </g>
    </g>
</g>
</g>
</symbol>
      
  
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A5">
<title>A5</title>
<g  style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
<g>
    <g>
        <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.3,13.6 1.3,1.1 13.7,1.1 10.1,4.5 
            5.1,3 8.4,5.8 5.6,8.8 3.1,5.1 4.7,10.2 				"/>
        <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.4,13.6 42.4,1.1 30,1.1 33.6,4.5 
            38.6,3 35.3,5.8 38.1,8.8 40.6,5.1 39,10.2 				"/>
    </g>
    <g>
        <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.6,29.9 42.6,42.4 30.2,42.4 
            33.8,39 38.8,40.5 35.5,37.7 38.3,34.7 40.8,38.4 39.2,33.3 				"/>
        <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.5,29.9 1.5,42.4 13.9,42.4 
            10.3,39 5.3,40.5 8.6,37.7 5.8,34.7 3.3,38.4 4.9,33.3 				"/>
    </g>
</g>
</g>
</symbol>
      
    
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A4">
<title>A4</title>
<g style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="13.7,1.1 1.3,1.1 1.3,13.6 6.5,8.3 
        9.4,9 8.6,6.2 10.5,4.3 			"/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="29.8,1.1 42.2,1.1 42.2,13.6 37,8.3 
        34.1,9 34.9,6.2 33,4.3 			"/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="13.7,42.4 1.3,42.4 1.3,29.9 
        6.6,35.2 9.5,34.5 8.6,37.3 10.5,39.2 			"/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="29.8,42.4 42.2,42.4 42.2,29.9 
        37,35.2 34.1,34.5 34.9,37.3 33,39.2 			"/>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
</g>
</symbol>
      
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A3">
<title>A3</title>
<g  style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.3,30 42.3,42.5 29.9,42.5 
        33.1,39.3 			"/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.3,30 1.3,42.5 13.7,42.5 10.5,39.3 
                    "/>
</g>
<g>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="1.3,13.6 1.3,1.1 13.7,1.1 10.5,4.3 
                    "/>
    <polygon style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" points="42.3,13.6 42.3,1.1 29.9,1.1 
        33.1,4.3 			"/>
</g>
</g>

</symbol>
      
  
<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A2">
<title>A2</title>
<g style="display:inline;">
<g>
    <rect x="15.2" y="1.1" style="fill:#293FC5;" width="13.2" height="0.5"/>
</g>
<g>
    <rect x="41.9" y="16" style="fill:#293FC5;" width="0.5" height="12.1"/>
</g>
<g>
    <rect x="1.3" y="16.2" style="fill:#293FC5;" width="0.5" height="11.6"/>
</g>
<g>
    <rect x="15" y="41.9" style="fill:#293FC5;" width="13.4" height="0.5"/>
</g>
<g>
    <path style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="M10.5,4.3l3.2-3.2H1.3v12.2v0.3l3.4-3.4
        c0.8,0.8,1.8,1.3,3,1.3c2.3,0,4.2-1.9,4.2-4.2C11.9,6.1,11.4,5,10.5,4.3z"/>
    <path style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="M33.1,4.3L30,1.1h12.4v12.2v0.3L39,10.2
        c-0.8,0.8-1.8,1.3-3,1.3c-2.3,0-4.2-1.9-4.2-4.2C31.8,6.1,32.3,5,33.1,4.3z"/>
</g>
<g>
    <path style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="M33.3,39.2l-3.2,3.2h12.4V30.2v-0.3l-3.4,3.4
        c-0.8-0.8-1.8-1.3-3-1.3c-2.3,0-4.2,1.9-4.2,4.2C31.9,37.4,32.4,38.4,33.3,39.2z"/>
    <path style="fill:none;stroke:#293FC5;stroke-width:0.5;stroke-miterlimit:10;" d="M10.7,39.2l3.2,3.2H1.5V30.2v-0.3l3.4,3.4
        c0.8-0.8,1.8-1.3,3-1.3c2.3,0,4.2,1.9,4.2,4.2C12,37.4,11.5,38.4,10.7,39.2z"/>
</g>
</g>
</symbol>


      
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="A1">
            <title>A1</title>
       
        	<g  style="display:inline;">
		<path style="fill:#293FC5;" d="M40.1,4.2c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0l-4.3,4.4L34.3,7c0.2-0.3,0.3-0.6,0.3-1
			c0-0.9-0.7-1.7-1.7-1.7S31.2,5,31.2,6s0.7,1.7,1.7,1.7c0.4,0,0.7-0.1,1-0.3L35,8.6L33.6,10c-0.3-0.2-0.6-0.3-0.9-0.3
			c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7c0-0.4-0.1-0.7-0.3-1L35.4,9l1.3,1.4
			c-0.2,0.3-0.2,0.5-0.2,0.9c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.4,0-0.8,0.1-1.1,0.4l-1.3-1.4
			L40.1,4.2z"/>
		<path style="fill:#293FC5;" d="M4.3,4.2c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0L9,8.3l1.1-1.2c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.4,0-0.7-0.1-1-0.3L9.4,8.6l1.4,1.4c0.3-0.2,0.6-0.3,0.9-0.3
			c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7S10,12.3,10,11.4c0-0.4,0.1-0.7,0.3-1L9,9l-1.3,1.4C7.9,10.7,8,11,8,11.3
			C8,12.2,7.3,13,6.3,13s-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.4,0,0.8,0.1,1.1,0.4l1.3-1.4L4.3,4.2z"/>
		<path style="fill:#293FC5;" d="M4.1,40.1c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0l4.3-4.4l1.1,1.2c-0.2,0.3-0.3,0.6-0.3,1
			c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7s-0.7-1.7-1.7-1.7c-0.4,0-0.7,0.1-1,0.3l-1.1-1.2l1.4-1.4c0.3,0.2,0.6,0.3,0.9,0.3
			c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7S9.8,32,9.8,32.9c0,0.4,0.1,0.7,0.3,1l-1.3,1.4l-1.3-1.4c0.2-0.3,0.2-0.5,0.2-0.9
			c0-0.9-0.7-1.7-1.7-1.7S4.4,32.1,4.4,33s0.7,1.7,1.7,1.7c0.4,0,0.8-0.1,1.1-0.4l1.3,1.4L4.1,40.1z"/>
		<path style="fill:#293FC5;" d="M39.9,40.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.1-0.4,0l-4.3-4.4L34,37.3c0.2,0.3,0.3,0.6,0.3,1
			c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.4,0,0.7,0.1,1,0.3l1.1-1.2l-1.4-1.4c-0.3,0.2-0.6,0.3-0.9,0.3
			c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7c0,0.4-0.1,0.7-0.3,1l1.3,1.4l1.3-1.4c-0.2-0.3-0.2-0.5-0.2-0.9
			c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.4,0-0.8-0.1-1.1-0.4l-1.3,1.4L39.9,40.1z"/>
		<rect x="3.6" y="15.8" style="fill:#293FC5;" width="0.5" height="12.3"/>
		<rect x="39.9" y="15.8" style="fill:#293FC5;" width="0.5" height="12.3"/>
		<rect x="15.8" y="39.9" style="fill:#293FC5;" width="12.3" height="0.5"/>
		<rect x="15.8" y="3.6" style="fill:#293FC5;" width="12.3" height="0.5"/>
	</g>
    
        </symbol>
    </svg>`,

        'symbols': {
            'Z': 3,
            'A': 9,
            'B': 12,
            'C': 12
        },

    }

]

export default data_svgs